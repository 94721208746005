import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import {
    register
} from "redux/auth/action";
import List from "components/admin/list";

class Index extends Component {
    state = {
        is_admin: true,
        is_staff: true,
        number: '',
        password: '',
    };
    addAdmin = () => {
        this.props.register({
            "mobile_number": this.state.number,
            "password": this.state.password,
            "is_admin": this.state.is_admin,
            "is_staff": this.state.is_staff
        })
    }

    render() {
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>


                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">اضافه کردن ادمین جدید</p>
                    </div>
                            <div>
                                <div className="row text-right">
                                    <div className="col-12 col-md-6 mt-4">
                                        <label className="mb-2">شماره تلفن همراه :</label>
                                        <input type="text" className="input-style-public-border pr-2"
                                               onChange={(e) => {
                                                   this.setState({number: e.target.value})
                                               }}/>
                                    </div>
                                    <div className="col-12 col-md-6 mt-4">
                                        <label className="mb-2">رمز :</label>
                                        <input type="text" className="input-style-public-border pr-2"
                                               onChange={(e) => {
                                                   this.setState({password: e.target.value})
                                               }}/>
                                    </div>
                                    <div className="col-12 col-md-6 mt-4">
                                <span className="d-inline-block text-right mt-3 ml-5">
                                       <label className="labelContainer">
                                    <input type="checkbox" value="asd" onChange={e => {
                                        this.setState({is_admin: e.target.checked})
                                    }
                                    }
                                           checked={this.state.is_admin}/>
                                    <span className="checkmark"/>
                                    <span
                                        className="font-size-09 text-secondary ">ادمین</span>
                                </label>
                                </span>
                                        <span className="d-inline-block text-right mt-3">
                                       <label className="labelContainer">
                                    <input type="checkbox" value="asd"
                                           onChange={e => {
                                               this.setState({is_staff: e.target.checked})
                                           }
                                           }
                                           checked={this.state.is_staff}/>
                                    <span className="checkmark"/>
                                    <span
                                        className="font-size-09 text-secondary ">تولید محتوا</span>
                                </label>
                                </span>
                                    </div>
                                </div>
                                <div className="text-left">
                                    <button
                                        className={`submit-btn w-auto px-3 py-2 d-inline-block`}
                                        onClick={this.addAdmin}>ذخیره ادمین
                                    </button>
                                </div>
                            </div>
                        </div>
                        <List/>

                    </Spin>
                </Main>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        register: (...data) => dispatch(register(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
