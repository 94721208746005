import React from 'react';
import {file_fetcher} from "../../../redux/common/action";
import queryString from 'query-string';
import DownloadModal from "components/users/driver/downloadModal";

const Filter = (props) => {

    const deleteFilters = () => {
        window.location.reload();
    };

    return (
        <>

            {/*<div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*        <label htmlFor="">نوع سیستم:</label>*/}
            {/*        <div className="flex-grow-1">*/}
            {/*            <input type="text" className="input-style-public-border" onChange={e=>props.filterDropdown({*/}
            {/*                name: "system",*/}
            {/*                value: e.target.value*/}
            {/*            })}/>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}
            <div className="col-12 col-md-12 mt-2 col-lg-7 col-xl-5 mt-3">
                <div className="d-flex">
                    <button onClick={props.handleRequest} className="submit-btn py-1">اعمال فیلتر</button>
                    <button onClick={deleteFilters} className="submit-btn-border py-1 mr-1">حذف فیلترها</button>
                    <DownloadModal type="serviceprovider" params={props.state.params}/>
                </div>

            </div>

        </>
    );
};

export default Filter;
