import {MENU,SERVICE,CARGO} from 'redux/pages/type';

export const infoSavePage = (state = {}, action) => {
    switch (action.type) {
        case MENU:
            return {...state, 'menu': action.data};
        case SERVICE:
            return {...state, 'service': action.data};
        case CARGO:
            return {...state, 'cargo': action.data};
        default:
            return state;

    }
};
