import React, {Component} from 'react';
import all from "images/icon/service/supporters.svg";
import now from "images/icon/service/today request.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaSearch, FaTrashAlt} from "react-icons/fa";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {getServiceRequest,getTags} from 'redux/service/action';
import {pageSave} from 'redux/pages/action';
import {connect} from "react-redux";
import {Spin} from 'antd';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment-jalaali';
const status_choices = [
    {name:'looking_provider',label:'درخواست های جاری'},
    {name:'expired',label:'درخواست های رد شده'},
    {name:'done',label:'درخواست های قبول شده'},
    {name:'cancel',label:'درخواست های کنسل شده'},
];

class Request extends Component {
    state = {
        activeTab: this.props.infoSavePage ? this.props.infoSavePage : '1',
        dropdownOpen: false,
        looking_provider: [],
        expired: [],
        done: [],
        cancel: [],
        Tags:[]
    };
    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };

    componentDidMount() {
        status_choices.forEach((item, i) => this.props.getServiceRequest(item?.name)
            .then(r => {
                if (r?.result)
                    this.setState({[item?.name]: r?.result})
            }));
        this.props.getTags().then(r=>{
            if(r?.result){
                this.setState({
                    Tags:r?.result
                });
            }
        });

    }
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.props.pageSave(tab, 'service');
            this.setState({
                activeTab: tab
            })

        }
    }

    render() {
        return (
            <>

                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل درخواست ها"
                                  number={this.state.bannerCount}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد درخواست های ماه جاری"
                                  number="1">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد درخواست های ماه قبل"
                                  number="1">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد درخواست های امروز"
                                  number="1">
                                <img src={now} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">درخواست ها</p>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center ml-4">
                                        <span className="ml-2">دسته بندی براساس</span>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                            <DropdownToggle className="dropdown-btn bg border px-4">
                                                <span>همه مطالب</span>
                                                <span className="pr-2"><IoIosArrowDown/></span>
                                            </DropdownToggle>
                                            <DropdownMenu style={{right: 0}}>
                                                <button className="dropdown-btn-item">همه مطالب ماه جدید</button>
                                                <button className="dropdown-btn-item">همه مطالب امروز</button>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className="position-relative">
                                        <input type="text" className="input-style-public-border bg pr-2 border"
                                               placeholder="جستجو"/>
                                        <span className="iconLogin text-player"><FaSearch/></span>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-4">
                                <div>
                                    <Nav
                                        className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                        {
                                            status_choices.map((item, i) => <NavItem className="flex-grow-1">
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                                    onClick={() => {
                                                        this.toggle((i + 1).toString());
                                                    }}
                                                >
                                                    {item?.label}
                                                </NavLink>
                                            </NavItem>)
                                        }
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        {
                                            status_choices.map((item,i)=>  <TabPane tabId={(i+1).toString()}>
                                                <div className="table-main mt-4">
                                                    <table className="table">
                                                        <tbody>
                                                        {
                                                           this.state?.[item?.name].map((b, i) => <tr>
                                                                <td>{i + 1}</td>
                                                                <td>

                                                                </td>

                                                                <td>
                                                <span className="d-flex align-items-center">
                                                      <span className="profile-image ml-2"
                                                            style={{backgroundImage: `url(http://limux1.ir/wp-content/uploads/2019/07/Taylor-Swift1.jpg)`}}/>
                                                    {/*style={{backgroundImage: `url(${this.props.infoMedia?.profile?.exact_url})`}}/>*/}
                                                    <span>{`${b?.driver?.first_name} ${b?.driver?.last_name}`}</span>
                                                </span>
                                                                </td>
                                                                <td>
                                                                    <span>شهر : </span>
                                                                    <span>{b?.driver?.location?.city?.province?.name + '،' + b?.driver?.location?.city?.name}</span>
                                                                </td>
                                                                <td>
                                                                    <span>نوع نیاز : </span>
                                                                    <span>
                                                                        {
                                                                            this.state.Tags.filter((tag)=>b?.tags.some((element)=>element==tag.id))
                                                                                .map((r)=>
                                                                            r?.title)
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>تاریخ : </span>
                                                                    <span>{moment(b?.creation_date,'jYYYY-jMM-jDD').format(' ddd jD jMMMM jYYYY')}</span>
                                                                </td>
                                                            </tr>)
                                                        }


                                                        </tbody>

                                                    </table>
                                                </div>
                                            </TabPane>)
                                        }

                                    </TabContent>
                                </div>
                            </div>


                        </div>
                    </Spin>
                </Main>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceRequest: (...data) => dispatch(getServiceRequest(...data)),
        getTags: (...data) => dispatch(getTags(...data)),
        pageSave: (...data) => dispatch(pageSave(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoSavePage: store.infoSavePage?.service,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Request);
