import {NEW_TRANSPORT, RESET_NEW_TRANSPORT} from 'redux/account/type'
import {fetcher} from "../common/action";
import {message} from "antd";

export const setRequestTransport = (data) => {
    return {
        type: NEW_TRANSPORT,
        data
    }
};
export const resetRequestTransport = () => {
    return {
        type: RESET_NEW_TRANSPORT,
    }
};

export const sanitizeSendRequest = (newRequest) => {
    let point = {
        ...newRequest,
        "office_location": {
            "city_id": newRequest.location?.city?.id,
            "point": newRequest.location?.city?.point,
            "address": newRequest?.address_work
        },
        "location": {
            "city_id": newRequest.location?.city?.id,
            "point": newRequest.location?.city?.point,
            "address": newRequest?.address_live
        },
        /* requirements: [{
             "car_id": newRequest?.vehicles?.car?.car?.id,
             "car_options_id": newRequest?.vehicles?.options.map(t=>t?.id),
             "price_type": newRequest?.prices?.price_type  || 1,
             "price": newRequest?.prices?.price || 0,
             "origin": {"city_id": newRequest.origin?.id},
             "destination": {"city_id": newRequest.destination?.id},
             "impurity_type": 1,
             "extra_information": {
                 "phone_number": newRequest?.phone_number,
                 "owner_information": newRequest?.owner_information
             },
             type_packing: {"type": newRequest?.kind?.id, "packing": newRequest?.pack?.id},
         }]*/
    };
    if(!point?.location?.city_id){
        delete point?.location
    }
    if(!point?.office_location?.city_id){
        delete point?.office_location
    }

    return point;
};
export const addTransport = () => {
    return (dispatch, store) => {
        return fetcher('panel/user/fake_transportation/',
            {
                method: 'POST',
                body: JSON.stringify(sanitizeSendRequest(store().infoNewTransport)),
            })
            .then((response) => {
                message.success("ارسال اطلاعات موفقیت آمیز بود");
            }).catch(e => {
                message.error("لطفا تمامی فیلدها رو پر کنید")
                }
            )
    }
};
