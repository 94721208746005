import React, {Component} from 'react';
import LoadingIcon from "components/publicComponents/loadingIcon";
import bars from "images/icon/cargo/bars.svg";
import box from "images/icon/cargo/owners.svg";
import cargo from "images/icon/cargo/cargoes.svg";
import successBar from "images/icon/cargo/succcessfull bars.svg";
import unsuccessBar from "images/icon/cargo/Unsuccessful Bars.svg";
import {Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {getCargoes} from 'redux/trackCargo/action';
import {pageSave} from 'redux/pages/action';
import {connect} from "react-redux";
import {Pagination, Spin} from 'antd';
import classnames from 'classnames';
import Item from "components/trackCargo/item";
import NotFoundFile from "components/publicComponents/notFoundFile";
import * as QueryString from "query-string";
import {fetcher} from '../../redux/common/action';
import FilterPrivate from "components/trackCargo/filter";
import {getVehiclesList} from "redux/dashboard/action";

let Cargoes = [
    {name: 'looking', label: 'سفارش های جاری', id: 1, queryState: {state: 1},tag:null},
    {name: 'reserved', label: 'رزرو شده', id: 3, queryState: {request_status: "2,3"},tag:'تایید نشده'},
    {name: 'carrying', label: 'درحال حمل', id: 5, queryState: {request_status: "1,4,5",state: '1,3',is_tracked:true},tag:'تایید شده'},
    {name: 'done', label: 'سفارش‌های حمل شده', id: 2, queryState: {state: 2},tag:null},
    {name: 'cancel', label: 'سفارش‌های لغو شده', id: 0, queryState: {state: 0},tag:null},
]

class Index extends Component {
    state = {
        activeTab: this.props.infoSavePage ? this.props.infoSavePage : '1',
        dropdownOpen: false,
        looking: [],
        lookingCount: [],
        cancel: [],
        cancelCount: [],
        done: [],
        doneCount: [],
        reserved: [],
        reservedCount: [],
        carrying: [],
        carryingCount: [],
        page: 1,
        params: {page: 1, is_tracked: true},
        role: Roles[0],
        dashbordBox: {},
        vehiclesList: []
    };
    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.props.pageSave(tab, 'cargo');
            this.setState({
                activeTab: tab
            })
        }
    };

    componentDidMount() {

        this.props.getVehiclesList().then(r => {
            if (r?.result) {
                this.setState({
                    vehiclesList: r?.result
                })
            }
        });

        if (Object.keys(QueryString.parse(this.props.location.search))?.length) {
            this.setState({
                params: {
                    search: '',
                    city: '',
                    page: Number((QueryString.parse(this.props.location.search)?.page) || 1)
                },
            }, () => {
                this.getListCargoes();
                const {history} = this.props;
                history.push(`/track-cargo`);
                console.log(this.state.params)
            })
        } else {
            this.getListCargoes();
        }


        fetcher(`/panel/cargo/dashboard?dashboard_type=tracking`,
            {
                method: 'GET',
                // body: JSON.stringify(sanitizeSendRequest(store().infoNewTransport)),
            })
            .then((response) => {
                this.setState({
                    ...this.state,

                    dashbordBox: {
                        cargo_count: response.result.cargo_count,
                        canceled_cargo: response.result.canceled_cargo,
                        cargo_customer_count: response.result.cargo_customer_count,
                        cargo_transportation_count: response.result.cargo_transportation_count,
                        current_month_cargo: response.result.current_month_cargo,
                        last_month_cargo: response.result.last_month_cargo,
                        success_cargo: response.result.success_cargo,
                        today_cargo: response.result.today_cargo,
                    },
                }, () => {

                })
            }).catch(e => {
                // message.error("لطفا تمامی فیلدها رو پر کنید")
            }
        )

    }

    changePage = (item, p) => {
        let cargoParams = {}
        cargoParams = {
            ...item?.queryState,
            is_tracked: true,
            role: this.state.role?.value,
            ...this.state.params,
            page: p
        }
        this.setState({
            params: {
                ...this.state.params,
                page: p
            }
        });
        this.props.getCargoes(cargoParams).then(r => {
            if (r?.result) {
                this.setState({
                    [item?.name]: r?.result,
                    [item?.name + 'Count']: r?.count,
                })
            }
        });

    };

    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                o_city: c,
            }
        });

    };

    getProvinceDestination = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                d_city: c,
            }
        });

    };

    getNewCar = (e) => {
        console.log("--->", e)
        this.setState({
            params: {
                ...this.state.params,
                ...e,
            }
        });

    };


    // handleRequest = () => {
    //     let params={}
    //    params = QueryMaker(this.state.params);
    //     this.props.getCargoes(params).then(r => {
    //         if (r?.result) {
    //             this.setState({
    //                 looking: r?.result,
    //                 lookingCount: r?.count,
    //             })
    //         }
    //     })
    // }


    getListCargoes = () => {

        Cargoes.forEach((item, i) => {
            let cargoParams = {}
            cargoParams = {
                ...item?.queryState,
                is_tracked: true,
                role: this.state.role?.value,
                ...this.state.params,
            }

            this.props.getCargoes(cargoParams).then(r => {
                if (r?.result) {
                    this.setState({
                        [item?.name]: r?.result,
                        [item?.name + 'Count']: r?.count,
                    })

                    const {history} = this.props;
                    history.push(`/track-cargo`);
                }

            })
        })
    };

    render() {

        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل سفارشات بار"
                                  number={this.state?.dashbordBox?.cargo_count}>
                                <img src={bars} alt=""/>
                            </Card>
                            <Card name="سفارشات صاحبان بار"
                                  number={this.state?.dashbordBox?.cargo_customer_count}>
                                <img src={box} alt=""/>
                            </Card>
                            <Card name="سفارشات شرکت حمل‌ونقل  ها"
                                  number={this.state?.dashbordBox?.cargo_transportation_count}>
                                <img src={cargo} alt=""/>
                            </Card>
                            <Card name="تعداد سفارشات موفق"
                                  number={this.state?.dashbordBox?.success_cargo}>
                                <img src={successBar} alt=""/>
                            </Card>
                            <Card name="تعداد سفارشات ناموفق"
                                  number={this.state?.dashbordBox?.canceled_cargo}>
                                <img src={unsuccessBar} alt=""/>
                            </Card>
                            <Card name="تعداد سفارشات ماه جاری"
                                  number={this.state?.dashbordBox?.current_month_cargo}>
                                <img src={bars} alt=""/>
                            </Card>
                            <Card name="تعداد سفارشات ماه قبل"
                                  number={this.state?.dashbordBox?.last_month_cargo}>
                                <img src={bars} alt=""/>
                            </Card>
                            <Card name="تعدادسفارشات امروز"
                                  number={this.state?.dashbordBox?.today_cargo}>
                                <img src={bars} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">لیست بارهای رصد</p>

                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center ml-4">
                                        <span className="ml-2">دسته بندی براساس</span>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                            <DropdownToggle className="dropdown-btn bg border px-4">
                                                <span>{this.state.role?.label}</span>
                                                <span className="pr-2"><IoIosArrowDown/></span>
                                            </DropdownToggle>
                                            <DropdownMenu style={{right: 0}}>
                                                {
                                                    Roles.map(t =>
                                                        <button className="dropdown-btn-item"
                                                                onClick={_ => this.setState({
                                                                    role: t,
                                                                    dropdownOpen: false
                                                                }, () => this.getListCargoes())
                                                                }>{t?.label}</button>)
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>


                                    {/* <div className="position-relative">
                                        <input type="text" className="input-style-public-border bg pr-2 border"
                                               placeholder="جستجو"
                                               onChange={(e) => {

                                                this.setState({
                                                    params:{
                                                        ...this.state.params,
                                                        search:e.target.value
                                                    }
                                                },()=>this.getListCargoes())
                                            }}
                                               />
                                        <span className="iconLogin text-player"><FaSearch/></span>
                                    </div> */}
                                </div>

                            </div>

                            <FilterPrivate
                                state={this.state}
                                filterDropdown={(t) => this.setState({
                                    params: {...this.state.params, [t.name]: (t.value)}
                                })}
                                handleRequest={this.getListCargoes}
                                getProvince={this.getProvince}
                                getProvinceDestination={this.getProvinceDestination}
                                getNewCar={this.getNewCar}
                            />
                            <div className="mt-4">
                                <div>
                                    <Nav
                                        className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                        {
                                            Cargoes.map((item, i) => <NavItem className="flex-grow-1">
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                                    onClick={() => {
                                                        this.toggle((i + 1).toString());
                                                    }}
                                                >
                                                    {item?.label}
                                                </NavLink>
                                            </NavItem>)
                                        }

                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        {
                                            Cargoes.map((item, i) => <TabPane tabId={(i + 1).toString()}>
                                                <div className="table-main mt-4">
                                                    <table className="table">
                                                        <tbody>
                                                        {
                                                            this.state?.[item?.name].map((elem, i) => <Item
                                                                getListCargoes={this.getListCargoes}
                                                                tag={item.tag}
                                                                item={elem}
                                                                page={this.state.params.page || 1}
                                                            />)
                                                        }
                                                        </tbody>

                                                    </table>
                                                    <div dir="ltr" className="text-center">
                                                        <Pagination defaultCurrent={1}
                                                                    current={this.state.params?.page || 1}
                                                                    responsive={true}
                                                                    onChange={(e) => this.changePage(item, e)}
                                                                    showSizeChanger={false}
                                                                    hideOnSinglePage={true}
                                                                    total={this.state?.[item?.name + 'Count']}/>
                                                        {
                                                            this.state?.[item?.name + 'Count'] === 0 &&
                                                            <NotFoundFile/>
                                                        }
                                                    </div>
                                                </div>
                                            </TabPane>)
                                        }
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCargoes: (...data) => dispatch(getCargoes(...data)),
        pageSave: (...data) => dispatch(pageSave(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoSavePage: store.infoSavePage?.cargo,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
const Roles = [
    {label: 'همه کاربری ها', value: ''},
    {label: 'شرکت حمل‌ونقل  ', value: 'transportation'},
    {label: 'صاحب بار', value: 'customer'},
];
