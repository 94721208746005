import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import banner from "images/icon/magazine/Banners.svg";
import now from "images/icon/driver/new month drivers.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaPlusCircle} from "react-icons/fa";
import Visitor from "components/magazine/visitor";
import {connect} from "react-redux";
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";
import {uploadFile} from "redux/magazine/action";
import {addServiceCategory, resultServiceCategory} from "redux/service/action";
import {initialBanner} from "redux/magazine/type";
import {message} from "antd";
import {Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";

class NewServiceCategory extends Component {
    state = {
        dropdownOpen: false,
        ready: false,
        next: false,
        showFile: '',
        name: null,
        description: null,
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };

    componentDidMount() {
        if (this.props.infoServiceCategory?.id) {
            this.setState({
                name: this.props.infoServiceCategory.title,
                description: this.props.infoServiceCategory.description,
            })
        }
    }

    sendPic = (e) => {
        this.setState({
            file: e.target.files[0],
            showFile: URL.createObjectURL(e.target.files[0])
        });
        if (e.target.files[0]) {
            this.props.uploadFile(e.target.id, e.target.files[0], e.target.files[0].type).then(r => {
                if (r?.result) {
                    this.props.resultCreateBanner({
                        media: r?.result,
                    });
                    this.setState({
                        ready: r?.statusCode === 201
                    })
                }
            })
        }
    };
    sendCategory = (method, id = '') => {
        let error = null;
        !this.state.name && (error = 'نام بنر ');
        !this.state.description && (error = '  توضیحات ');

        if (!error) {
            this.props.addServiceCategory({
                "title": this.state.name,
                "description": this.state.description
            }, method, id).then((r) => {

                this.setState({
                    next: true
                })

            }).catch(r => {
                message.error('با خطا مواجه شدید')
            })
        } else {
            message.error(error + 'را وارد کنید');
        }
    };

    componentWillUnmount() {
        this.props.resultServiceCategory({})
    }

    render() {
        if (this.state.next) {
            return <Redirect to="/service-category"/>
        }
        const {infoServiceCategory} = this.props;
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">ایجاد دسته بندی جدید</p>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <p className="text-banner">عنوان دسته
                                    <span className="text-danger px-1">*</span>
                                </p>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoServiceCategory?.title}
                                       onChange={(e) => this.setState({name: e.target.value})}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <p className="text-banner">
                                    توضیحات
                                    <span className="text-danger px-1">*</span>
                                </p>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoServiceCategory?.description}
                                       onChange={(e) => this.setState({description: e.target.value})}/>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <p className="text-banner">زیردسته</p>
                                <input type="text" className="input-style-public-border pr-2"/>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-12 col-md-6 mt-4 mt-md-0">
                                    <div className="d-flex align-items-center ">
                                        <span className="text-banner">آپلود عکس </span>
                                        <div className="border-radius-10 ">
                                            <div className="d-flex align-items-center justify-content-center ">
                                    <span className="position-relative">
                                    <input type="file" className="pr-2 inputUpload py-1" title="یک فایل انتخاب کنید"
                                           onChange={this.sendPic}
                                           accept="image/png, image/jpeg"
                                           id="banner"
                                    />
                                        <span
                                            className="layerUpload d-flex align-items-center text-center justify-content-center">انتخاب فایل</span>
                                    </span>

                                            </div>
                                        </div>
                                        <img className="img-banner mr-2" src={this.state.showFile} alt=""/>
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <p className="text-banner">وضعیت</p>
                                <select name="status" onChange={this.handleChangeSelect}
                                        defaultValue={this.state.status}
                                        className="input-style-public-border select-input pr-2" id="cars">
                                    <option value="1">فعال</option>
                                    <option value="2">غیر فعال</option>
                                    <option value="3">آرشیو</option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <p className="text-banner">اولویت</p>
                                <select name="priority" onChange={this.handleChangeSelect}
                                        defaultValue={this.props.infoCreateCategory?.priority}
                                        className="input-style-public-border select-input pr-2" id="cars">
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </select>
                            </div>
                            <div className="text-left mt-4">
                                <button
                                    className={`submit-btn w-auto px-3 py-2 d-inline-block`}
                                    onClick={() => infoServiceCategory?.title ?
                                        this.sendCategory('PUT', this.props.infoServiceCategory?.id + '/') :
                                        this.sendCategory('POST')}> تایید نهایی
                                </button>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (...data) => dispatch(uploadFile(...data)),
        addServiceCategory: (...data) => dispatch(addServiceCategory(...data)),
        resultServiceCategory: (...data) => dispatch(resultServiceCategory(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoMedia: store.infoMedia,
        infoCreateBanner: store.infoCreateBanner,
        infoServiceCategory: store.infoServiceCategory,

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewServiceCategory);
