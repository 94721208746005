import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import {Redirect} from "react-router-dom";
import {editDetailCargo, getDetailCargo} from "redux/cargo/action";

class EditCargo extends Component {
    state = {
        back: false,
        item: {},
        description: ''
    };

    componentDidMount() {
        this.props.getDetailCargo(this.props.match.params?.slug)
            .then(r => {
                if (r?.result) {
                    this.setState({
                        item: r?.result,
                        description: r?.result?.description
                    })
                }
            })
    }
    render() {
        if (this.state.back) {
            return <Redirect to="/cargo"/>
        }
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row text-right">
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">توضیحات :</label>
                                <textarea className="input-style-public-border pr-2"
                                          defaultValue={this.state.description}
                                          onChange={(e) => {
                                              this.setState({
                                                  description: e.target.value
                                              })
                                          }}
                                />
                                <div className="text-left">
                                    <button className="submit-btn-green w-auto px-5 d-inline-block"
                                            onClick={() => {
                                                this.props.editDetailCargo(this.props.match.params?.slug,{description: this.state.description}).then(r => {
                                                    if (r?.statusCode && (r?.statusCode == 200)) {
                                                        this.setState({
                                                            back: true
                                                        })
                                                    }
                                                })
                                            }}>
                                        ذخیره تغییرات
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editDetailCargo: (...data) => dispatch(editDetailCargo(...data)),
        getDetailCargo: (...data) => dispatch(getDetailCargo(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCargo);
