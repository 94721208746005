import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";
import moment from "moment-jalaali";
import DatePicker from 'react-datepicker2';
import {MdCheckCircle} from "react-icons/md";
import {FaSearch} from "react-icons/fa";
import ProvinceSelector from "./ProvinceSelector";

const Filter = (props) => {
    const Filter = [
        {label: 'همه رانندگان', value: '', name: 'date_min'},
        {label: 'رانندگان امروز', value: moment().format('jYYYY-jMM-jDD'), name: 'date_min'},
        {label: 'رانندگان ماه جدید', value: moment().format('jYYYY-jMM-01'), name: 'date_min'},
        // {label: 'در انتظار تایید', value: 'true', name: 'is_in_approve_queue'},
    ]
    const [filter, setFilter] = useState(Filter[0],);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [date_min, seDate_min] = useState(null);
    const [date_max, seDate_max] = useState(null);
    const [approve, setApprove] = useState(false);
    const [is_approve, setIs_approve] = useState(false);
    return (
        <>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <div className="position-relative">
                    <input type="text" className="input-style-public-border bg pr-2 border"
                           placeholder="جستجو نام یا شماره تلفن همراه"
                           onChange={(e) => {
                               props.filterDropdown({value: (e.target.value || ''), name: 'search'})
                               // this.setState({
                               //     params: {
                               //         ...this.state.params,
                               //         search: (e.target.value || ''),
                               //         page: 1
                               //     }
                               // }, )
                           }}/>
                    <span className="iconLogin text-player"><FaSearch/></span>
                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <ProvinceSelector getNewProvince={props.getProvince}/>
            </div>
            {/*<div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4">*/}
            {/*    /!*<div className="d-flex align-items-center ">*!/*/}
            {/*    /!*    <span className="ml-2">دسته بندی براساس</span>*!/*/}
            {/*    /!*    /!*<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>*!/*!/*/}
            {/*    /!*    /!*    <DropdownToggle className="dropdown-btn bg border px-2">*!/*!/*/}
            {/*    /!*    /!*        <span>{filter?.label}</span>*!/*!/*/}
            {/*    /!*    /!*        <span className="pr-2"><IoIosArrowDown/></span>*!/*!/*/}
            {/*    /!*    /!*    </DropdownToggle>*!/*!/*/}
            {/*    /!*    /!*    <DropdownMenu style={{right: 0}}>*!/*!/*/}
            {/*    /!*    /!*        {*!/*!/*/}
            {/*    /!*    /!*            Filter.map(t => (t?.value !== filter?.value) &&*!/*!/*/}
            {/*    /!*    /!*                <button className="dropdown-btn-item"*!/*!/*/}
            {/*    /!*    /!*                        onClick={() => {*!/*!/*/}
            {/*    /!*    /!*                            // this.setState({filter: t,params:{...this.state.params,[t.name]:(t.value)}}, () => this.handleRequest())*!/*!/*/}
            {/*    /!*    /!*                            setFilter(t)*!/*!/*/}
            {/*    /!*    /!*                            props.filterDropdown(t)*!/*!/*/}
            {/*    /!*    /!*                            setDropdownOpen(false)*!/*!/*/}

            {/*    /!*    /!*                        }}>{t?.label}*!/*!/*/}
            {/*    /!*    /!*                </button>)*!/*!/*/}
            {/*    /!*    /!*        }*!/*!/*/}
            {/*    /!*    /!*    </DropdownMenu>*!/*!/*/}
            {/*    /!*    /!*</Dropdown>*!/*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">


                <div className="box-detail py-1 p-2 d-flex align-items-center justify-content-between">

                    <label className=""> تایید هویت :</label>

                    <input
                        type="checkbox"
                        checked={is_approve}
                        onChange={(e) => {
                            setIs_approve(e.target.checked)
                            props.filterDropdown({value: e.target.checked, name: 'is_approve'})
                        }}
                        className={` ${is_approve ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="box-detail py-1 p-2 d-flex align-items-center justify-content-between">

                    <label className=""> در انتظار احراز هویت :</label>

                    <label className="switch">
                        <input type="checkbox"
                               defaultChecked={approve}
                               onChange={(e) => {
                                   setApprove(e.target.checked)
                                   props.filterDropdown({value: e.target.checked, name: 'is_in_approve_queue'})
                               }}
                        />
                        <span className="slider"/>
                    </label>
                </div>
                </div>


            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className="">عضویت از:</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={date_min}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="تاریخ شروع عضویت"
                            onChange={(value) => {
                                seDate_min(value)
                                props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'date_min'})
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className=""> عضویت تا:</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2 "
                            value={date_max}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="تاریخ پایانی عضویت"
                            onChange={(value) => {

                                seDate_max(value)
                                props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'date_max'})
                            }}
                        />
                    </div>
                </div>

            </div>

        </>
    );
};

export default Filter;
