import React, {useEffect, useState} from 'react';
import Main from "../publicComponents/main";
import LoadingIcon from "../publicComponents/loadingIcon";
import {Spin} from "antd";
import {getProvince, getCities, getCargoCount} from "../../redux/report/action";
import {connect} from "react-redux";
import Chart from "./chart";
import {Button} from "reactstrap";


const ReportMap = (props) => {
    const [series, setSeries] = useState([])
    const [extra, setExtra] = useState(200)
    const [isCity, setIsCity] = useState(false)

    useEffect(() => {
        getProvinceData()
    }, [])
    const getProvinceData = () => {
        setSeries([])
        setIsCity(false)
        props.getProvince().then(r => {
            if (r?.statusCode === 200) {
                let list = []
                list = r?.result.map(t => ({...t, x: t?.province, y: t?.count + extra}))
                setExtra(200)
                setSeries(list)
            }
        }).catch(r => {
        })
    }
    const getCitiesData = (id) => {
        setSeries([])
        setIsCity(true)
        props.getCities(id).then(r => {
            if (r?.statusCode === 200) {
                let list = []
                list = r?.result.map(t => ({...t, x: t?.city, y: t?.count + extra}))

                setSeries(list)
            }
        }).catch(r => {
        })
    }
    return (
        <Main>
            <Spin size="large" spinning={props.infoLoading} indicator={<LoadingIcon/>}>
                <div className="main-page-inside mt-4 text-right">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <p className="main-title text-right"> تعداد اعلام بار بر روی نمودار</p>
                        {isCity ? <Button onClick={getProvinceData}>بازگشت به لیست استان ها</Button> : ''}
                    </div>
                    {series.length ? <Chart series={series} getProvinceData={getProvinceData} getCitiesData={getCitiesData} extra={extra}/> : ""}
                </div>
            </Spin>

        </Main>
    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        // getCargoes: (...data) => dispatch(getCargoes(...data)),
        getProvince: (...data) => dispatch(getProvince(...data)),
        getCities: (...data) => dispatch(getCities(...data)),
        getCargoCount: (...data) => dispatch(getCargoCount(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportMap);