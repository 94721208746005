import {NEW_TRANSPORT, RESET_NEW_TRANSPORT} from 'redux/account/type'
import {RESET} from "redux/common/type";

let initialNewTransport = {
  /*  "location": {
        "city_id": null,
        /!*  "point": {
              "longitude": "90.565798",
              "latitude": "50.670049"
          },*!/
        "address": ""
    },
    "first_name": "",
    "last_name": "",
    "national_code": "",
    "is_active": true,
    "is_approve": true,
    "birth_date": "",
    "company_name": "",*/
    "phone_number": "",
   /* "founder_name": "",
    "office_location": {
        "city_id": null,
        /!*   "point": {
               "longitude": "90.565798",
               "latitude": "50.670049"
           },*!/
        "address": ""
    },
    "area_expertise": "",*/
   /* address_live: '',
    address_work: ''*/
};
export const infoNewTransport = (state = initialNewTransport, action) => {
    switch (action.type) {
        case RESET:
            return initialNewTransport;
        case NEW_TRANSPORT:
            let out = {...initialNewTransport, ...state, ...action.data};
            return out;
        case RESET_NEW_TRANSPORT:
            return initialNewTransport;
            return out;
        default:
            return state;
    }
};
