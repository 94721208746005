import {fetcher} from "redux/common/action";
import {message} from 'antd'

export const getScore = (page = 1, target = '') => {
    return () => {
        return fetcher("panel/score/?page=" + page + '&targets=' + target, {
            method: "GET",
        }).then((response) => {
            return response;
        })
            .catch(r => {

            })
    };
};


export const scoreDetail = (id) => {
    return () => {
        return fetcher("panel/score/" + id + '/', {
            method: "GET",
        }).then((response) => {
            return response;
        }).catch(r => {

        })
    };
};
export const editScoreDetail = (id, data) => {
    return () => {
        return fetcher("panel/score/" + id + '/', {
            method: "PUT",
            body: JSON.stringify(data)
        }).then((response) => {
            return response;
        }).catch(r => {

        })
    };
};
export const giveScore = (data) => {
    return () => {
        return fetcher("panel/score/add_extra_score/", {
            method: "POST",
            body: JSON.stringify(data)
        }).then((response) => {
            return response;
        }).catch(r => {

        })
    };
};
export const giftScore = (data) => {
    return async () => {
        try {
            await fetcher("panel/score/", {
                method: "POST",
                body: JSON.stringify(data)
            });
            let res = await fetcher("panel/score/add_special_score/", {
                method: "POST",
                body: JSON.stringify({
                    "roles": data?.roles,
                    "score_type": data?.type
                })
            });
            return res;
        } catch (e) {
            message.error('با خطا مواجه شدید')
        }
    };
};
