import React, {Component} from 'react';
import {login} from "redux/auth/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";

class Index extends Component {
    state = {
        number: '',
        password: '',
    };
    login = () => {
        console.log('okkk')
        this.props.login({
            "mobile_number": this.state.number,
            "password": this.state.password,
        })
    }
    render() {
        return (
            <>
                {/*<Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>*/}
                <div className="login-box container ">
                    <div className="row h-100 w-100 justify-content-center">
                        <div className="col-6 h-100">
                            <div className="inside-login p-5">
                                <p className="title-login mb-5">ورود به پنل کاربری باریار</p>
                                <div className="d-flex align-items-center mb-4">
                                    <label className="text" htmlFor=""> شماره تلفن همراه : </label>
                                    <input type="text" className="input-style-public-border pr-2 border"
                                           onChange={(e) => {
                                               this.setState({number: e.target.value})
                                           }}/>
                                </div>
                                <div className="d-flex align-items-center">
                                    <label className="text" htmlFor="">رمز ورود : </label>
                                    <input type="text" className="input-style-public-border pr-2 border"
                                           onChange={(e) => {
                                               this.setState({password: e.target.value})
                                           }}/>
                                </div>
                                <div className="mt-5">
                                    <button className="submit-btn"
                                    onClick={this.login}>تایید  </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*</Spin>*/}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (...data) => dispatch(login(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
