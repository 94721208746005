import React, {Component} from 'react';
import {NavLink as NavLinkRouter} from "react-router-dom";
import { FiChevronDown} from "react-icons/fi";
import {Collapse} from 'reactstrap';
import {pageSave} from 'redux/pages/action';
import {connect} from "react-redux";

class ButtonCollapse extends Component {
    state = {
        isOpen: ( this.props.childsLink|| []).includes(this.props.infoSavePage?.menu)
    };
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };



    render() {

        return (
            <>
                <button className="menu-item " onClick={(this.toggle)}><span>{this.props.name}</span>
                    {
                        this.props.iconLeft ?


                            <span
                                className={`transition ${this.state.isOpen ? '' : 'rotate90'}`}><FiChevronDown/></span>
                            : ''
                    }</button>
                <Collapse isOpen={this.state.isOpen}>
                    {
                        this.props.children
                    }

                </Collapse>


            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        pageSave: (...data) => dispatch(pageSave(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoSavePage: store.infoSavePage,


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonCollapse);
