import React, {useEffect, useState} from 'react';
import {FaMapMarker} from "react-icons/fa";
import {Modal} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from "react-icons/io";
import {MapContainer, Marker, TileLayer, useMapEvents} from 'react-leaflet'
import L from "leaflet";

const MapSelector = (props) => {
    const [state, setState] = useState({
        isOpen: false,
        isOpenMap: false,
        zoom: 13,
        position: [35.711395371053676, 51.39279842376709],

    })
    const [selectedPosition, setSelectedPosition] = useState([35.711395371053676, 51.39279842376709])
    useEffect(() => {
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });

        navigator.geolocation.getCurrentPosition(function (location) {

        });
    }, [])
    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setSelectedPosition([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
                props.getPoint({latitude: e.latlng.lat, longitude: e.latlng.lng})
                console.log([
                    e.latlng.lat,
                    e.latlng.lng
                ])
            },
        })

        return (
            selectedPosition ?
                <Marker
                    key={selectedPosition[0]}
                    position={selectedPosition}
                    interactive={false}
                />
                : null
        )

    }

    return (
        <>
            <button value={""} onClick={() => setState(state => ({...state, isOpenMap: true}))}
                    className="box-detail py-3 p-2 d-block w-100 mt-2">
                <p className="d-flex align-items-center justify-content-between "
                   style={{lineHeight: '2.2rem'}}>
                                <span>
                                      <span className="text-MainColor"><FaMapMarker/></span>
                                    {selectedPosition?.length > 0 && <span className="mr-2">
                                    {selectedPosition[0] + ' ، ' + selectedPosition[1]}
                                </span>}
                                </span>
                    <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                </p>
            </button>
            <div className="row mt-3">
                <div className="col-12 col-lg-6">
                    <div className="d-flex align-items-center">

                        <input type="text" className="input-style-public-border text-left pl-2"
                               onChange={(e) => {
                                   let r = e.target.value;
                                   if (r) {
                                       let t = selectedPosition[1]
                                       setSelectedPosition(state => [r, state[1]])
                                       // setState(state => ({...state, position: }))
                                       props.getPoint({latitude: r, longitude: t})
                                   }
                               }}/>
                        <span className="pr-2">:latitude(y)</span>
                    </div>

                </div>
                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div className="d-flex align-items-center">

                        <input type="text" className="input-style-public-border text-left pl-2"
                               onChange={(e) => {
                                   let r = e.target.value;
                                   if (r) {
                                       let t = selectedPosition[0]
                                       setSelectedPosition(state => [state[0], r])
                                       // setState(state => ({...state, position: [t, e.target.value]}))
                                       props.getPoint({latitude: t, longitude: r})
                                   }
                               }}/>
                        <span className="pr-2">:longitude(x)</span>
                    </div>
                </div>
            </div>


            <Modal centered={true} size="lg" isOpen={state.isOpenMap}
                   toggle={() => setState(state => ({...state, isOpenMap: false}))}>
                {/*  <button onClick={()=>setState(state=>({...state,isOpenMap:false})}>X</button>*/}
                <div
                    className="py-3 headColor d-flex align-items-center justify-content-between px-2">
                    <p className="text-white text-center  pr-2">
                        انتخاب موقعیت مکانی
                    </p>
                    <button onClick={() => setState(state => ({...state, isOpenMap: false}))}
                            className="text-white font-size-15">
                        <IoIosClose/>
                    </button>
                </div>
                <MapContainer
                    center={selectedPosition}
                    zoom={state.zoom}
                    onzoomend={e => setState(state => ({...state, zoom: e.target._zoom}))}
                    style={{width: "100%", height: "500px", overflow: "hidden"}}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    <Markers/>

                </MapContainer>
            </Modal>
        </>
    );

}

export default MapSelector;
