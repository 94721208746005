import React, {Component} from 'react';
import { FaUserCircle,FaChevronDown} from "react-icons/fa";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {connect} from "react-redux";
import {logOut} from 'redux/auth/action';
class MenuTop extends Component {
    state={
        dropdownOpen:false
    };
    toggle=()=>{
        this.setState({
            dropdownOpen:!this.state.dropdownOpen
        })
    }
    render() {
        return (
            <>
                <div className="menu-top">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle className="bg-transparent border-0 text-color">
                            <button className="px-3">
                                <span className="icon pl-2"><FaUserCircle/></span>
                                <span className="">مدیر سیستم</span>
                                <span className="font-size-07 pr-1"><FaChevronDown/></span>
                            </button>
                        </DropdownToggle>
                        <DropdownMenu>
                          <button onClick={()=>this.props.logOut()}>خروج از سایت</button>
                        </DropdownMenu>
                    </Dropdown>

                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: (...data) => dispatch(logOut(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuTop);
