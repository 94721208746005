import React, {useEffect, useState} from 'react';
import Main from "components/publicComponents/main";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {connect} from "react-redux";
import {Pagination, Spin} from "antd";
import {fetcher, file_fetcher} from "redux/common/action";
import {FiDownload} from "react-icons/all";
import moment from "moment-jalaali";
import NotFoundFile from "components/publicComponents/notFoundFile";

const DownloadList = (props) => {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const getList = (p = page) => {
        fetcher((`panel/files/?page=${p}`),
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    setList(response.result)
                    setCount(response.count)
                }

            }).catch(e => {
        })
    }
    const changePage = (p) => {
        setPage(p)
        getList(p)

    };
    useEffect(() => {
        getList()
    }, [])

    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        // a.download = 'file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const handleReceivedFileExcel = (id,name) => {
        file_fetcher((`panel/files/${id}/download/`), {method: 'GET'})
            .then((res) => {
                res.blob().then(blob => download(blob,name))
            }).catch(e => {
            console.log(e);
        })
    }
    return (
        <>
            <Main>

                <Spin size="large" spinning={props.infoLoading} indicator={<LoadingIcon/>}>
                    <div className="table-main mt-4">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <p className="main-title text-right">دانلود گزارش ها</p>
                        </div>
                        <table className="table text-right">
                            <thead>
                            <tr>
                                <th className="text-right">شماره</th>
                                <th className="text-right">نام فایل</th>
                                <th className="text-right">وضعیت</th>
                                <th className="text-right">زمان ارسال</th>
                                <th className="text-right">عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                list.map((item, i) => <tr key={item.id}>
                                    <td className="text-right">{(page-1)*(10 ) + i + 1}</td>
                                    <td className="text-right">{item.filename}</td>
                                    <td className="text-right">{item.status}</td>
                                    <td className="text-right">{moment(item.creation_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td className="text-right">
                                        <button onClick={() => handleReceivedFileExcel(item.id,item.filename)}
                                                className="text-MainColor font-size-15">
                                            <FiDownload/>
                                        </button>
                                    </td>
                                </tr>)
                            }


                            </tbody>
                        </table>
                        <div dir="ltr" className="text-center">
                            <Pagination defaultCurrent={1} current={page || 1} responsive={true}
                                        onChange={(e) => changePage( e)}
                                        showSizeChanger={false}
                                        hideOnSinglePage={true}
                                        total={count}/>
                            {
                                count === 0 &&
                                <NotFoundFile/>
                            }
                        </div>
                    </div>
                </Spin>
            </Main>
        </>
    );
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};

export default connect(mapStateToProps, null)(DownloadList);
