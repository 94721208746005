import React, {useState} from 'react';
import CarFilter from "components/users/driver/carFilter";
import DatePicker from "react-datepicker2";
import {file_fetcher} from "../../../redux/common/action";
import queryString from 'query-string';
import {  notification } from 'antd';
import DownloadModal from "components/users/driver/downloadModal";

const Filter = (props) => {
    const [date_min, seDate_min] = useState(null);
    const [body, setBody] = useState(null);
    const deleteFilters = () => {
        window.location.reload();
    };





    return (
        <>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <CarFilter filterDropdown={props.filterDropdown}/>
            </div>
            {/*<div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*        <label htmlFor="">نوع سیستم:</label>*/}
            {/*        <div className="flex-grow-1">*/}
            {/*            <input type="text" className="input-style-public-border pr-2" onChange={e=>props.filterDropdown({*/}
            {/*                name: "system",*/}
            {/*                value: e.target.value*/}
            {/*            })}/>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*        <label htmlFor=""> نام برند:</label>*/}
            {/*        <div className="flex-grow-1">*/}
            {/*            <input type="text" className="input-style-public-border pr-2" onChange={e=>props.filterDropdown({*/}
            {/*                name: "brand",*/}
            {/*                value: e.target.value*/}
            {/*            })}/>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*        <label htmlFor=""> سال ساخت:</label>*/}
            {/*        <div className="flex-grow-1">*/}
            {/*            <input type="number" className="input-style-public-border pr-2" onChange={e=>props.filterDropdown({*/}
            {/*                name: "year",*/}
            {/*                value: e.target.value*/}
            {/*            })}/>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className=""> بیمه ثالث:</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={date_min}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="سررسید بیمه شخص ثالث"
                            onChange={(value) => {
                                seDate_min(value)
                                props.filterDropdown({
                                    value: value.format('jYYYY-jMM-jDD'),
                                    name: 'insurance_third_party_deadline'
                                })
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className=""> بیمه بدنه:</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={body}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="سررسید بیمه بدنه"
                            onChange={(value) => {
                                setBody(value)
                                props.filterDropdown({
                                    value: value.format('jYYYY-jMM-jDD'),
                                    name: 'insurance_body_deadline'
                                })
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-7 col-xl-5 mt-3">
                <div className="d-flex">
                    <button onClick={props.handleRequest} className="submit-btn py-1">اعمال فیلتر</button>
                    <button onClick={deleteFilters} className="submit-btn-border py-1 mr-1">حذف فیلترها</button>
                    <DownloadModal type="driver" params={props.state.params}/>
                </div>

            </div>
        </>
    );
};

export default Filter;
