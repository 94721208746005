import React, {useState} from 'react';
import queryString from "query-string";
import {file_fetcher} from "redux/common/action";
import {Modal, ModalBody, Spinner} from 'reactstrap';
import {Link} from "react-router-dom";


const DownloadModal = ({type = '', params}) => {
    const [toggle, setToggle] = useState(false);
    const [status, setStatus] = useState('none');

    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        // a.download = filename;
        a.download = 'file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const handleReceivedFileExcel = () => {
        setToggle(true)
        setStatus('none')
        const queries = queryString.stringify(params);

        file_fetcher((`panel/export/${type}/?${queries}`), {method: 'GET'})
            .then((res) => {


                res.blob().then(blob => {
                    if (blob.type === "application/vnd.ms-excel"){
                        download(blob)
                        setToggle(false)
                    }
                    else
                        setStatus('done')
                })
            }).catch(e => {
            setStatus('error')
        })
    }

    return (
        <>
            <button onClick={handleReceivedFileExcel} className="submit-btn py-1 mr-1"> دریافت  اکسل
            </button>
            <Modal centered={true} isOpen={toggle} toggle={() => setToggle(false)} className="modal-login">
                <ModalBody className="p-0 border-radius-5 p-4 text-right">

                    {
                        status === 'none' && <>
                            <p>
                                <span>  فایل اکسل در حال دانلود  </span>

                                {/*<Link to="/" className="px-1">دانلود فایل </Link>*/}
                                <span>   می باشد.</span>

                            </p>
                            <div className="text-center mt-3"><Spinner/></div>
                        </>
                    }
                    {
                        status === 'done' && <>
                            <p>
                                <span>  فایل اکسل در بخش </span>

                                <Link to="/report-download-list" className="px-1">دانلود فایل </Link>
                                <span>   بارگزاری شد.</span>

                            </p>
                        </>
                    }
                    {
                        status === 'error' && <>
                            <p>
                               دانلود فایل با خطا مواجه شد.

                            </p>
                        </>
                    }
                </ModalBody>
            </Modal>
        </>
    );
};

export default DownloadModal;
