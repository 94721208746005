import React, {useEffect, useState} from 'react';
import {file_fetcher} from "redux/common/action";
import queryString from 'query-string';
import DownloadModal from "components/users/driver/downloadModal";

const Filter = (props) => {
    const deleteFilters = () => {
        window.location.reload();
    };
    const [permit, setPermit] = useState(false);
    const [international, setInternational] = useState(false);


    return (
        <>

            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">


                <div className="box-detail py-1 p-2 d-flex align-items-center justify-content-between">

                    <label className=""> مجوز وانت بار :</label>

                    <input
                        type="checkbox"
                        checked={permit}
                        onChange={(e) => {
                            setPermit(e.target.checked)
                            props.filterDropdown({value: e.target.checked, name: 'cargo_pickup_license'})
                        }}
                        className={` ${permit ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">


                <div className="box-detail py-1 p-2 d-flex align-items-center justify-content-between">

                    <label className=""> مجوز بین الملل :</label>

                    <input
                        type="checkbox"
                        checked={international}
                        onChange={(e) => {
                            setInternational(e.target.checked)
                            props.filterDropdown({value: e.target.checked, name: 'international_license'})
                        }}
                        className={` ${permit ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                </div>
            </div>
            <div className="col-12 col-md-12 mt-2 col-lg-7 col-xl-5 mt-3">
                <div className="d-flex">
                    <button onClick={props.handleRequest} className="submit-btn py-1">اعمال فیلتر</button>
                    <button onClick={deleteFilters} className="submit-btn-border py-1 mr-1">حذف فیلترها</button>
                    <DownloadModal type="transportation" params={props.state.params}/>
                </div>

            </div>

        </>
    );
};

export default Filter;
