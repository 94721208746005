import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import Main from "../publicComponents/main";
import {getCargoesOnMap} from "redux/track/action";
import {useDispatch} from "react-redux";
import Locate from "components/track/locate";


const position = [35.72428729739558, 51.447000503540046]

const Index = () => {
    const dispatch = useDispatch();
    const [listCargoes, setListCargoes] = useState([]);
    useEffect(() => {
        dispatch(getCargoesOnMap({page_size: 100})).then(r => {
            if (r.statusCode === 200)
                setListCargoes(state => ([...state, ...r.result]))
        }).catch(e => {
        })
    }, [])

    return (<Main>
            <MapContainer center={position} zoom={13}
                          style={{width: "100%", height: "calc(100vh - 80px)", overflow: "hidden"}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {listCargoes.map((item, i) => {
                    if (item?.drivers.length)
                       return  item?.drivers.map((element, index) => ((element.location?.latitude && element.location?.longitude) && <Locate key={element.location?.latitude + element.location?.longitude + index} id={item.tracking_code} item={element}/>))
                })}
            </MapContainer></Main>
    );
};

export default Index;
