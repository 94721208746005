import React, {useEffect, useState} from 'react';
import CarFilter from "components/users/driver/carFilter";
import moment from "moment-jalaali";
import DatePicker from 'react-datepicker2';
// import {file_fetcher} from "../../../redux/common/action";
import {FaSearch} from "react-icons/fa";
import ProvinceSelector from "../users/ProvinceSelector";
import CarSelector from "components/cargo/CarSelector";
import {changeDetailDriver, editDetail, getDriver, getVehiclesList} from "../../redux/dashboard/action";
import {connect} from "react-redux";

const Filter = (props) => {
    const [date, seDate] = useState(null);
    const [vehiclesList, setVehiclesList] = useState([]);
    // const [body, setBody] = useState('');
    const deleteFilters = () => {
        window.location.reload();
    };

    // const handleReceivedFileExcel = () => {
    //     file_fetcher(`panel/export/driver/?search=${props.state.params.search},
    //         car=${props.state.params.car},
    //         city=${props.state.params.city},
    //         is_approve=${props.state.params.is_approve},
    //         is_in_approve_queue=${props.state.params.is_in_approve_queue},
    //         cargo_pickup_license=${props.state.params.cargo_pickup_license},
    //         insurance_body_deadline=${props.state.params.insurance_body_deadline},
    //         insurance_third_party_deadline=${props.state.params.insurance_third_party_deadline},
    //         option=${props.state.params.option},
    //         date_min=${props.state.params.date_min},
    //         date_max=${props.state.params.date_max}`,
    //         {
    //             method: 'GET',
    //         })
    //         .then((res) => {
    //             window.location.href = res.url;
    //         }).catch(e => {
    //             console.log(e);
    //             }
    //         )
    // }


    useEffect(() => {
        props.getVehiclesList().then(r => {
            if (r?.result) {
                setVehiclesList(r?.result)
            }
        });
    }, [])
    return (
        <>
            {/* <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <CarFilter filterDropdown={props.filterDropdown}/>
            </div> */}

            {/* <div className="position-relative mt-3">
                <label className="d-flex text-right mb-2">جستجو بر اساس اعلام بار کننده :</label>
                <input type="text" className="input-style-public-border bg pr-2 border"
                    placeholder="جستجو"
                    onChange={(e) => {
                        props.filterDropdown({value: (e.target.value || ''), name: 'search'})
                    }}/>
                <span className="iconLogin text-player"><FaSearch/></span>
            </div> */}

            <div className="row">
                <div className="col px-0">
                    <div className="row">
                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <ProvinceSelector getNewProvince={props.getProvince} titleListProvince="شهر مبدا"/>
                        </div>

                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <ProvinceSelector getNewProvince={props.getProvinceDestination}
                                              titleListProvince="شهر مقصد"/>
                        </div>
                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <CarSelector name="نوع وسیله نقلیه"
                                         getNewCar={props.getNewCar}
                                         vehiclesList={vehiclesList}/>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className="d-flex">
                <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                    <CarSelector name="نوع وسیله نقلیه"
                        vehiclesList={props.state.vehiclesList}
                    />
                </div>
            </div> */}


            {/* <div className="col-12 col-md-6 col-xl-4  mt-4"><span>نوع وسیله نقلیه : </span>
                <span>{infoDetailDriver?.vehicles_info[0]?.vehicle?.car?.title + ' ، ' + infoDetailDriver?.vehicles_info[0]?.vehicle?.option?.title}</span>

            </div> */}

            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className="ml-3">تاریخ بارگیری </label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={date}
                            timePicker={false}
                            isGregorian={false}
                            // placeholder="تاریخ بارگیری"
                            onChange={(value) => {
                                seDate(value)
                                props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'date'})
                            }}
                        />
                    </div>

                </div>
            </div>


            {/* <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className="">  تاریخ بارگیری :</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={date}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="تاریخ بارگیری"
                            onChange={(value) => {
                                seDate(value)
                                props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'date'})
                            }}
                        />
                    </div>

                </div>
            </div> */}

            {/* <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                <div className="d-flex align-items-center">
                    <label className="">  بیمه بدنه:</label>
                    <div className="flex-grow-1">
                        <DatePicker
                            className="input-style-public-border pr-2"
                            value={body}
                            timePicker={false}
                            isGregorian={false}
                            placeholder="سررسید بیمه بدنه"
                            onChange={(value) => {
                                setBody(value)
                                props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'insurance_bodyل_deadline'})
                            }}
                        />
                    </div>

                </div>
            </div> */}
            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <div className="d-flex">
                    <button onClick={props.handleRequest} className="submit-btn py-1">اعمال فیلتر</button>
                    <button onClick={deleteFilters} className="submit-btn-border py-1 mr-1">حذف فیلتر</button>
                    {/* <button onClick={handleReceivedFileExcel} className="submit-btn py-1 mr-1"> دریافت فایل اکسل</button> */}
                </div>

            </div>
        </>
    );
};

// export default Filter;
const mapDispatchToProps = (dispatch) => {
    return {
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
