import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import banner from "images/icon/magazine/Banners.svg";
import now from "images/icon/driver/new month drivers.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaPlusCircle} from "react-icons/fa";
import Visitor from "components/magazine/visitor";
import {initialCatergory} from "redux/magazine/type";
import {message} from "antd";
import {createCategory, resultCreateCategory, uploadFile, updateCategory} from "redux/magazine/action";
import {connect} from "react-redux";
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
class NewCategory extends Component {
    state = {
        dropdownOpen: false,
        status: 2,
        priority: 1,
        ready: false,
        next: false,
        showFile: '',
        update: false
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleChangeSelect = (e) => {
        let {name, value} = e.target;
        this.props.resultCreateCategory({[name]: value})
    }

    componentDidMount() {
        console.log(this.props.infoCreateCategory)
        if (this.props.match.params.slug === 'new') {
            this.props.resultCreateCategory(initialCatergory)
        } else {
            this.setState({
                ready: true,
                update: true
            })

        }

    }
    componentWillUnmount() {
        this.props.resultCreateCategory(initialCatergory)
    }

    sendPic = (e) => {

        this.setState({
            file: e.target.files[0],
            showFile: URL.createObjectURL(e.target.files[0])
        });
        if (e.target.files[0]) {
            this.props.uploadFile(e.target.id, e.target.files[0], e.target.files[0].type).then(r => {
                if (r?.result) {
                    this.props.resultCreateCategory({
                        media: r?.result,
                    })
                    this.setState({
                        ready: r?.statusCode === 201
                    })
                }
            })
        }
    };
    sendCategory = () => {

        let error = null;
        !this.props.infoCreateCategory.title && (error = 'نام دسته بندی');
        // (this.props.infoCreateCategory?.description.length > 50 ) && (error = 'توضیحات کمتر از 50 کاراکتر');

        if (!error) {
            this.props.createCategory().then((r) => {

                this.setState({
                    next: true
                })

            }).catch(r => {
                message.error('با خطا مواجه شدید')
            })
        } else {
            message.error(error + 'را وارد کنید');
        }
    };

    render() {

        if (this.state.next) {
            return <Redirect to="/category"/>
        }
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                    <div className="main-page-inside mt-4 text-right">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="main-title text-right">ایجاد دسته بندی جدید</p>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">عنوان دسته</p>
                            <input type="text" className="input-style-public-border pr-2"
                                   defaultValue={this.props.infoCreateCategory?.title}
                                   onChange={(e) => this.props.resultCreateCategory({title: e.target.value})}/>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">توضیحات</p>
                            <input type="text" className="input-style-public-border pr-2"
                                   defaultValue={this.props.infoCreateCategory?.description}
                                   onChange={(e) => this.props.resultCreateCategory({description: e.target.value})}/>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">وضعیت</p>
                            <select name="status" onChange={this.handleChangeSelect} defaultValue={this.state.status}
                                    className="input-style-public-border select-input pr-2" id="cars">
                                <option value="1">فعال</option>
                                <option value="2">غیر فعال</option>
                                <option value="3">آرشیو</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">اولویت</p>
                            <select name="priority" onChange={this.handleChangeSelect}
                                    defaultValue={this.props.infoCreateCategory?.priority}
                                    className="input-style-public-border select-input pr-2" id="cars">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                        </div>
                        <div className="row mt-4 align-items-center">

                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                                <div className="d-flex align-items-center ">
                                    <span className="text-banner">آپلود عکس </span>
                                    <div className="border-radius-10 ">
                                        <div className="d-flex align-items-center justify-content-center ">
                                    <span className="position-relative">
                                    <input type="file" className="pr-2 inputUpload py-1" title="یک فایل انتخاب کنید"
                                           onChange={this.sendPic}
                                           accept="image/png, image/jpeg"
                                           id="category"
                                    />
                                        <span
                                            className="layerUpload d-flex align-items-center text-center justify-content-center">انتخاب فایل</span>
                                    </span>

                                        </div>
                                    </div>
                                    <img className="img-banner mr-2"
                                         src={this.state.showFile ? this.state.showFile : this.props.infoCreateCategory?.media?.exact_url}
                                         alt=""/>
                                </div>
                            </div>

                        </div>
                        <Visitor/>
                        <div className="text-left">
                            <NavLinkRouter to="/category" className="submit-btn-border w-auto px-5 py-2 d-inline-block ml-2"
                                    >انصراف
                            </NavLinkRouter>
                            {
                                this.state.update
                                    ?
                                    <button
                                        className={`${this.state.ready ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}

                                        disabled={!this.state.ready}
                                        onClick={() => this.props.updateCategory(this.props.match.params.slug).then(r=> this.setState({
                                            next: true
                                        })).catch(r=>  message.error('با خطا مواجه شدید'))}>ذخیره تغییرات</button>
                                    :
                                    <button
                                        className={`${this.state.ready ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}

                                        disabled={!this.state.ready}
                                        onClick={this.sendCategory}>ذخیره دسته بندی</button>
                            }


                        </div>
                    </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (...data) => dispatch(uploadFile(...data)),
        resultCreateCategory: (...data) => dispatch(resultCreateCategory(...data)),
        createCategory: (...data) => dispatch(createCategory(...data)),
        updateCategory: (...data) => dispatch(updateCategory(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoMedia: store.infoMedia,
        infoCreateCategory: store.infoCreateCategory,

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCategory);
