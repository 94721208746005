import React, {Component} from "react";
import Button from "components/dashboard/button";
import ButtonCollapse from "components/dashboard/buttonCollapse";

class MenuRight extends Component {
    render() {

        return (
            <>
                <div className="menu-right">
                    <div className="title">
                        <div>پنل مدیریت باریار</div>
                    </div>
                    <div className="menu-contents">
                        <div className="menu-contents-inside">
                            <Button name="داشبورد"
                                    link="dashboard"
                                    to="/"
                                    iconLeft={true}/>
                            <Button name="ادمین"
                                    link="admin"
                                    to="/admin"
                                    iconLeft={true}/>
                            <ButtonCollapse name="کاربران"
                                            to="/user"
                                            childsLink={[
                                                "drivers",
                                                "customer",
                                                "transport",
                                                "service-provider",
                                            ]}
                                            iconLeft={true}>
                                <Button name="رانندگان"
                                        className="menu-item-child pr-5"
                                        to="/drivers"
                                        link="drivers"
                                        listLinks={['drivers','driver-detail','driver-edit']}
                                        iconLeft={true}/>
                                <Button name="صاحبان بار"
                                        className="menu-item-child pr-5"
                                        to="/customer"
                                        link="customer"
                                        listLinks={['customer','customer-detail','customer-edit']}
                                        iconLeft={true}/>
                                <Button name="شرکت حمل‌ونقل "
                                        className="menu-item-child pr-5"
                                        to="/transport"
                                        link="transport"
                                        listLinks={['transport','transporter-detail','newtransport','transporter-edit']}
                                        iconLeft={true}/>
                                <Button name="خدمت دهنده"
                                        className="menu-item-child pr-5"
                                        to="/service-provider"
                                        link="service-provider"
                                        listLinks={['service-provider','service-provider-detail']}
                                        iconLeft={true}/>

                            </ButtonCollapse>
                            <Button name="سفارش بار"
                                    to="/cargo"
                                    link="cargo"
                                    iconLeft={true}/>
                            <ButtonCollapse name="خدمات"
                                            to="/service"
                                            childsLink={[
                                                "service-category",
                                                "service-request",
                                            ]}
                                            iconLeft={true}>
                                <Button name="دسته بندی"
                                        className="menu-item-child pr-5"
                                        to="/service-category"
                                        link="service-category"
                                        listLinks={['service-category','new-service-category']}
                                        iconLeft={true}/>
                                <Button name=" درخواست ها"
                                        className="menu-item-child pr-5"
                                        to="/service-request"
                                        link="service-request"
                                        iconLeft={true}/>
                            </ButtonCollapse>
                            <ButtonCollapse name="مجلات"
                                            to="/magazine"
                                            childsLink={[
                                                "banner",
                                                "subjects",
                                                "category",
                                            ]}
                                            iconLeft={true}>
                                <Button name="بنر"
                                        className="menu-item-child pr-5"
                                        to="/banner"
                                        link="banner"
                                        listLinks={['banner','new-banner']}
                                        iconLeft={true}/>
                                <Button name=" مطالب"
                                        className="menu-item-child pr-5"
                                        to="/subjects"
                                        link="subjects"
                                        listLinks={['subjects','new-subject']}

                                        iconLeft={true}/>
                                <Button name="دسته بندی"
                                        className="menu-item-child pr-5"
                                        to="/category"
                                        link="category"
                                        listLinks={['category','new-category']}
                                        iconLeft={true}/>
                            </ButtonCollapse>
                            <Button name="اعلان ها"
                                    to="/notification"
                                    link="notification"
                                    iconLeft={true}/>
                            <Button name="امتیازات"
                                    to="/score"
                                    link="score"
                                    listLinks={['score','coins']}
                                    iconLeft={true}/>
                            <Button name="شهرها"
                                    to="/city"
                                    link="city"
                                    iconLeft={true}/>

                            <ButtonCollapse name="گزارشات"
                                            to="/report"
                                            childsLink={[
                                                "report",
                                                "report-map",
                                                "report-download-list",
                                                "report-number",
                                            ]}
                                            iconLeft={true}>
                                <Button name="اعلام بار"
                                        className="menu-item-child pr-5"
                                        to="/report"
                                        link="report"
                                        iconLeft={true}/>
                                <Button name=" اعلام بار روی نقشه"
                                        className="menu-item-child pr-5"
                                        to="/report-map"
                                        link="report-map"
                                        iconLeft={true}/>
                                <Button name="دانلود گزارش ها"
                                        className="menu-item-child pr-5"
                                        to="/report-download-list"
                                        link="report-download-list"
                                        iconLeft={true}/>
                                <Button name="شماره های همراه"
                                        className="menu-item-child pr-5"
                                        to="/report-number"
                                        link="report-number"
                                        iconLeft={true}/>
                            </ButtonCollapse>
                            <ButtonCollapse name="رصد"
                                            to="/track-map"
                                            childsLink={[
                                                "track-map",
                                                "track-cargo",

                                            ]}
                                            iconLeft={true}>
                                <Button name="نقشه"
                                        className="menu-item-child pr-5"
                                        to="/track-map"
                                        link="track-map"
                                        iconLeft={true}/>
                                <Button name="بارها"
                                        className="menu-item-child pr-5"
                                        to="/track-cargo"
                                        link="track-cargo"
                                        iconLeft={true}/>

                            </ButtonCollapse>
                            <Button name="نظرسنجی"
                                    link="survey"
                                    to="/survey"
                                    iconLeft={true}
                                    listLinks={['survey','survey-detail']}
                            />


                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MenuRight;
