import React, {useState} from 'react';
import {Spin} from "antd";
import Main from "components/publicComponents/main";
import {fetcher} from "redux/common/action";
import LoadingIcon from "components/publicComponents/loadingIcon";

const PhoneNumber = () => {
    const [params, setParams] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const getData = () => {
        if (params) {
            setLoading(true)
            fetcher(`panel/user_from_mobile/?mobile_number=${params}`, {
                method: 'GET',
            })
                .then((response) => {
                    if (response.statusCode === 200) {
                        setData(() => ([...response.result]))
                    }

                }).catch(e => {
            }).finally(() => setLoading(false))
        }
    };

    return (<Main>
        <Spin size="large" spinning={loading} indicator={<LoadingIcon/>}>
            <div className="main-page-inside mt-4 text-right">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="main-title text-right" >گزارش شماره  تلفن همراه کاربران</p>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <p className="text-banner" style={{minWidth:170}}>شماره تلفن همراه کاربر :</p>
                    <input type="text"
                           onChange={(e) => setParams(e.target.value)}
                           value={params}
                           name="title"
                           className="input-style-public-border pr-2"
                           placeholder="شماره تلفن همراه کاربر را وارد کنید"
                    />
                </div>
                <div className="text-left mt-3">
                    <button onClick={() => setParams("")}
                            className="submit-btn-border w-auto d-inline-block px-3 py-2 ml-1">حذف
                    </button>
                    <button
                        disabled={!params}
                        className="submit-btn w-auto px-3 py-2 d-inline-block"
                        onClick={getData}>
                        تایید
                    </button>
                </div>
                <div className="table-main mt-4">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="text-right">ردیف</th>
                            <th className="text-right">شماره تلفن همراه</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, i) => <tr key={item} className="mt-1">
                            <td className="text-right">{i + 1}</td>
                            <td className="text-right">{item}</td>

                        </tr>)}
                        </tbody>
                    </table>
                </div>

            </div>
        </Spin>
    </Main>);
};

export default PhoneNumber;