import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import Car from "components/report/Car";
import Destination from "components/report/Destination";
import Mobile from "components/report/Mobile";
import Telegram from "components/report/Telegram";

import {getCargoes} from "../../redux/report/action";
import {getVehiclesList} from "redux/dashboard/action";
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from "classnames";
import FilterPrivate from "./filter";


const Cargoes = [
    {name: 'car', component: Car, label: '  وسیله نقلیه ', id: 1},
    {name: 'origin_dest', component: Destination, label: '  شهر', id: 2},
    {name: 'mobile_number', component: Mobile, label: '  موبایل', id: 3},
    {name: 'telegram', component: Telegram, label: '  تلگرام', id: 4},
]

class Index extends Component {
    state = {
        activeTab: "1",
        vehiclesList: [],
        params: {},
        updateComponent:false
    };

    componentDidMount() {
        this.props.getVehiclesList().then(r => {
            if (r?.result) {
                this.setState({
                    vehiclesList: r?.result
                })
            }
        });
    }


    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    };

    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                o_city: c,
            }
        });

    };

    getProvinceDestination = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                d_city: c,
            }
        });

    };

    getNewCar = (e) => {
        console.log("--->", e)
        this.setState({
            params: {
                ...this.state.params,
                ...e,
            }
        });

    };


    render() {


        return (
            <>
                <Main>

                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <p className="main-title text-right"> تعداد اعلام بار</p>
                            </div>
                            <FilterPrivate
                                state={this.state}
                                filterDropdown={(t) => this.setState({
                                    params: {...this.state.params, [t.name]: (t.value)}
                                })}
                                handleRequest={()=>this.setState({updateComponent:!this.state.updateComponent})}
                                getProvince={this.getProvince}
                                getProvinceDestination={this.getProvinceDestination}
                                getNewCar={this.getNewCar}
                            />

                            <Nav
                                className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                {
                                    Cargoes.map((item, i) => <NavItem className="flex-grow-1">
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                            onClick={() => {
                                                this.toggle((i + 1).toString());
                                            }}
                                        >
                                            {item?.label}
                                        </NavLink>
                                    </NavItem>)
                                }

                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                {
                                    Cargoes.map((item, i) => {
                                        let MyComponent = item.component;

                                        return <TabPane tabId={(i + 1).toString()}>
                                            <MyComponent
                                                params={this.state.params}
                                                updateComponent={this.state.updateComponent}
                                                vehiclesList={this.state.vehiclesList}/>

                                        </TabPane>
                                    })
                                }
                            </TabContent>

                        </div>
                    </Spin>
                </Main>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getCargoes: (...data) => dispatch(getCargoes(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
