import React, {useEffect, useState} from 'react';
import {FaStar} from "react-icons/fa";
import {Pagination, Rate} from "antd";
import {Modal, ModalBody} from 'reactstrap';
import {fetcher} from "redux/common/action";
import moment from "moment-jalaali";

const Box = (props) => {
    const [list, setList] = useState([]);
    // const [optionsWeakness, setOptionsWeakness] = useState([]);
    const [optionsDriver, setOptionsDriver] = useState([]);
    const [optionsCustomer, setOptionsCustomer] = useState([]);
    const [data, setData] = useState({
        recieved_options: [],
        given_options: [],
        rating: 0,
        given_rate: 0,
    });
    const [listCount, setListCount] = useState(0);
    const [page, setPage] = useState(1);
    useEffect(() => {
        getData();
        getList();
        getOptions("driver");
        getOptions("customer");
    }, []);

    const getData = () => {
        fetcher(`panel/survey-report/${props.user}/${props.id}`,
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    setData(state => ({...state, ...response.result}))
                }

            }).catch(e => {
        })
    };
    const getList = (p = page) => {
        fetcher(`/panel/surveys/?owner=${props.owner}&role=${props.role}&page=` + p,
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    setList([...response.result])
                }

            }).catch(e => {
        });
    }
    const getOptions = (type) => {
        fetcher(`survey/options/?role=${type}`,
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    if (type === "driver")
                        setOptionsDriver(state => [...response.result])
                    else
                        setOptionsCustomer(state => [...response.result])
                }

            }).catch(e => {
        });
    }
    const changePage = () => {
        setPage(state => {
            getList(state + 1)
            return state + 1;
        })

    };

    return (
        <>
            <p className="main-title text-right d-flex align-items-center mt-4">
                <span className="pl-2">  جزییات نظرسنجی</span>
            </p>


            <div className="row text-right">
                <div className="col-12 col-lg-6   mt-4">
                    <p>
                        <b>میانگین امتیازات کسب شده</b>
                        <b className="mr-3">{data.rating}</b>
                        <span className="ml-2 mr-1"><FaStar color="gold"/></span>
                        {/*<sub>از</sub>*/}
                        {/*<sub className="mx-1">**</sub>*/}
                        {/*<sub>نظرسنجی</sub>*/}
                    </p>
                    <div className="row mt-4">
                        {
                            data.recieved_options.map((item, i) => <div className="col-12 col-md-6 mb-3" key={i}>
                                <div
                                    className="bgGray d-flex justify-content-between p-2 border-radius-5 border font-size-09">
                                    <span>{item.option}</span>
                                    <span className="text-MainColor">
                                    <span>{item.count}</span>
                                    <span>  رای</span>
                                </span>

                                </div>
                            </div>)
                        }

                    </div>
                </div>
                <div className="col-12 col-lg-6   mt-4">
                    <p>
                        <b>میانگین امتیازات داده شده</b>
                        <b className="mr-3">{data.given_rate}</b>
                        <span className="ml-2 mr-1"><FaStar color="gold"/></span>
                        {/*<sub>در</sub>*/}
                        {/*<sub className="mx-1">**</sub>*/}
                        {/*<sub>نظرسنجی</sub>*/}
                    </p>
                    <div className="row mt-4">
                        {
                            data.given_options.map((item, i) => <div className="col-12 col-md-6 mb-3" key={i}>
                                <div
                                    className="bgGray d-flex justify-content-between p-2 border-radius-5 border font-size-09">
                                    <span>{item.option}</span>
                                    <span className="text-MainColor">
                                      <span>{item.count}</span>
                                    <span>  رای</span>
                                </span>

                                </div>
                            </div>)
                        }

                    </div>
                </div>
            </div>

            <p className="main-title text-right d-flex align-items-center mt-4">
                <span className="pl-2">  بارها</span>
            </p>
            <div className="table-main mt-4">
                <table className="table">
                    <thead>
                    <tr>
                        <th>شماره</th>

                        <th>نام و نام خانوادگی</th>
                        <th>شماره تلفن همراه</th>
                        <th>
                            <div> امتیازات</div>
                            <sub>(کسب شده)</sub>
                        </th>
                        <th>
                            <div> امتیازات</div>
                            <sub>(داده شده)</sub>
                        </th>

                        <th>تاریخ</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item, i) => <tr>
                            <td>{i + 1}</td>
                            <td>{`${item.survey_target?.first_name ?? ''} ${item.survey_target?.last_name ?? ''}`}</td>
                            <td>{item.owner?.mobile_number}</td>
                            <td>{item.recieved_rate ?? 0}
                                <span className="ml-2 mr-1"><FaStar color="gold"/></span>
                            </td>
                            <td>
                                {item.rate ?? 0}
                                <span className="ml-2 mr-1">
                                <FaStar color="gold"/></span></td>
                            <td>{moment(item.creation_date).format("YYYY/MM/DD")}</td>
                            <td>
                                <SurveyModal data={item} optionsDriver={optionsDriver}
                                             user={props.user}
                                             optionsCustomer={optionsCustomer}/>
                            </td>

                        </tr>)
                    }


                    </tbody>

                </table>
                <div dir="ltr" className="text-center">
                    <Pagination defaultCurrent={1} current={page || 1} responsive={true}
                                onChange={changePage}
                                showSizeChanger={false}
                                total={listCount}/>
                </div>
            </div>
        </>
    );
};

const SurveyModal = (props) => {
    const [open, setOpen] = useState(false);
    const [optionDriver, setOptionDriver] = useState([]);
    const [optionCustomer, setOptionDriverCustomer] = useState([]);
    const {data, optionsDriver, optionsCustomer, user} = props;
    const toggle = () => {
        setOpen(state => !state)
    }
    useEffect(() => {
        let checkList = [];
        if (user === "drivers")
            checkList = data.options;
        else
            checkList = data.recieved_options;

        let list = [];
        if (checkList)
            list = checkList.map(t => ({...t, right: true}));
        let listFilter = optionsDriver.filter(t => !list.some(p => p.id === t.id))

        if (user === "drivers")
            setOptionDriver([...list, ...listFilter])
        else
            setOptionDriverCustomer([...list, ...listFilter])

    }, [optionsDriver])

    useEffect(() => {
        let checkList = [];
        if (user === "drivers")
            checkList = data.recieved_options;
        else
            checkList = data.options;
        let list = [];
        if (checkList)
            list = checkList.map(t => ({...t, right: true}));
        let listFilter = optionsCustomer.filter(t => !list.some(p => p.id === t.id))
        if (user === "drivers")
            setOptionDriverCustomer([...list, ...listFilter])
        else
        setOptionDriver([...list, ...listFilter])

    }, [optionsCustomer])

    return <>
        <button className="submit-btn py-1" onClick={toggle}>
            جزییات نظرسنجی
        </button>
        <Modal centered={true} isOpen={open} toggle={toggle} className="modal-login"
               size="lg">
            <ModalBody className="p-0 border-radius-5 pb-4">
                <div className="mx-3">
                    <p className="main-title text-right d-flex align-items-center mt-4">
                        <span className="pl-2">  جزییات نظرسنجی</span>
                    </p>
                </div>

                <div className="row text-center mt-4">
                    <div className="col-12 col-lg-6">

                        <div className="box-border m-3">
                            <div className="head p-2">{`ارزیابی ${user === "drivers" ? 'راننده' : ' صاحب بار'}`}</div>
                            <div className="m-3">

                                <div className="p-2">
                                    <div>
                                    <span className="profile-image ml-2"
                                          style={{backgroundImage: `url(${'profile'})`}}/>

                                    </div>
                                    <p>{`${data.survey_target?.first_name ?? ''} ${data.survey_target?.last_name ?? ''}`}</p>
                                    <div dir="ltr" className="mt-3">
                                        <Rate allowHalf defaultValue={data.rate}/>
                                    </div>

                                </div>
                            </div>
                            <p className="bg-light">نقاط قوت و ضعف</p>
                            <div className="m-3">
                                <div className="row">
                                    {
                                        optionDriver.map((item, i) => <div className="col-12 col-md-6 mb-3"
                                                                           key={i}>
                                            <div
                                                className="bgGray d-flex justify-content-center p-1 border-radius-100 border font-size-09">
                                                {/*<span className={`${item.type ==="STRENGTH" ? 'text-success' : 'text-danger'}`}>{item.title}</span>*/}
                                                <span
                                                    className={`text-gray ${item.right && (item.type === "STRENGTH" ? 'text-success' : 'text-danger')}`}>{item.title}</span>

                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-lg-6">

                        <div className="box-border m-3">
                            <div className="head p-2">{`ارزیابی ${user === "drivers" ? ' صاحب بار' : 'راننده'}`}</div>
                            <div className="m-3">

                                <div className="p-2">
                                    <div>
                                    <span className="profile-image ml-2"
                                          style={{backgroundImage: `url(${'profile'})`}}/>

                                    </div>
                                    <p>{`${data.survey_target?.first_name ?? ''} ${data.survey_target?.last_name ?? ''}`}</p>
                                    <div dir="ltr" className="mt-3">
                                        <Rate allowHalf defaultValue={data.recieved_rate}/>
                                    </div>

                                </div>
                            </div>
                            <p className="bg-light">نقاط قوت و ضعف</p>
                            <div className="m-3">
                                <div className="row">
                                    {
                                        optionCustomer.map((item, i) => <div className="col-12 col-md-6 mb-3"
                                                                             key={i}>
                                            <div
                                                className="bgGray d-flex justify-content-center p-1 border-radius-100 border font-size-09">
                                                {/*<span className={`${item.type ==="STRENGTH" ? 'text-success' : 'text-danger'}`}>{item.title}</span>*/}
                                                <span
                                                    className={`text-gray ${item.right && (item.type === "STRENGTH" ? 'text-success' : 'text-danger')}`}>{item.title}</span>

                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </ModalBody>
        </Modal>
    </>
}

export default Box;
