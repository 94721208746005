export const QueryMaker = (data) => {
    let params = '';
    Object.keys(data).map((t, i) => {
        if (params && data[t]) {
            params += '&' + t + '=' + data[t];
        } else if (data[t]) {
            params = '?' + t + '=' + data[t];
        }
    });
    return params;
}