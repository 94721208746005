import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";
import Visitor from "components/magazine/visitor";
import {initialSubject} from "redux/magazine/type";
import {message} from "antd";
import {createSubject, resultCreateSubjects, updateSubject, uploadFile, getCategory} from "redux/magazine/action";
import {connect} from "react-redux";
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";

class NewSubject extends Component {
    state = {
        dropdownOpen: false,
        status: 2,
        priority: 1,
        ready: false,
        next: false,
        showFile: '',
        update: false,
        categories: []
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };

    handleChangeSelect = (e) => {
        let {name, value} = e.target;
        this.props.resultCreateSubjects({category: value})
    };

    componentDidMount() {
        if (this.props.match.params.slug === 'new') {

            this.props.resultCreateSubjects(initialSubject);
            this.props.getCategory().then(r => {
                if (r?.result) {
                    this.setState({
                        categories: r?.result,
                        categoryCount: r?.count,
                    })
                    if (r?.result?.length > 0) {
                        console.log( r?.result[0].id)
                        this.props.resultCreateSubjects({
                            category: r?.result[0].id,
                        })
                    }

                }
            })
            this.forceUpdate()
        } else {
            this.setState({
                ready: true,
                update: true
            });
            this.props.getCategory().then(r => {
                if (r?.result) {
                    this.setState({
                        categories: r?.result,
                        categoryCount: r?.count,
                    })

                }
            })
        }



    }
    componentWillUnmount() {
        this.props.resultCreateSubjects(initialSubject);
    }

    sendPic = (e) => {

        this.setState({
            file: e.target.files[0],
            showFile: URL.createObjectURL(e.target.files[0])
        });
        if (e.target.files[0]) {
            this.props.uploadFile(e.target.id, e.target.files[0], e.target.files[0].type).then(r => {
                if (r?.result) {
                    this.props.resultCreateSubjects({
                        media: r?.result,
                    })
                    this.setState({
                        ready: r?.statusCode === 201
                    })
                }
            })
        }
    };
    sendCategory = () => {

        let error = null;
        !this.props.infoCreateSubject?.caption && (error = 'نام دسته بندی');
        (!this.props.infoCreateSubject?.description) && (error = 'توضیحات');
        // (this.props.infoCreateSubject?.description.length > 50 ) && (error = 'توضیحات کمتر از 50 کاراکتر');


        if (!error) {
            this.props.createSubject().then((r) => {

                this.setState({
                    next: true
                })

            }).catch(r => {
                message.error('با خطا مواجه شدید')
            })
        } else {
            message.error(error + 'را وارد کنید');
        }
    };



    render() {
        console.log(this.props.infoCreateSubject)
        if (this.state.next) {
            return <Redirect to="/subjects"/>
        }
        return (
            <>
                <Main>

                    <div className="main-page-inside mt-4 text-right">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="main-caption text-right">ایجاد مطلب جدید</p>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">تیتر</p>
                            <input type="text" className="input-style-public-border pr-2"
                                   defaultValue={this.props.infoCreateSubject?.caption}
                                   onChange={(e) => this.props.resultCreateSubjects({caption: e.target.value})}/>
                        </div>
                        <div className="d-flex  mt-4">
                            <p className="text-banner">توضیحات</p>
                            <textarea  className="input-style-public-border pr-2" rows={7}
                                      defaultValue={this.props.infoCreateSubject?.description}
                                      onChange={(e) => this.props.resultCreateSubjects({description: e.target.value})}/>
                        </div>

                        <div className="row mt-4 align-items-center">
                            <div className="col-12 col-md-6">
                                <div className="d-flex align-items-center ml-4">
                                    <span className="ml-2">دسته بندی براساس</span>
                                    <select name="category" onChange={this.handleChangeSelect}
                                            className="input-style-public-border select-input pr-2" >
                                        {this.state.categories.map((c, i) => <option selected={this.props.infoCreateSubject?.category == c.id} value={c.id}>{c.title}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                                <div className="d-flex align-items-center ">
                                    <span className="text-banner">آپلود عکس </span>
                                    <div className="border-radius-10 ">
                                        <div className="d-flex align-items-center justify-content-center ">
                                    <span className="position-relative">
                                    <input type="file" className="pr-2 inputUpload py-1" caption="یک فایل انتخاب کنید"
                                           onChange={this.sendPic}
                                           accept="image/png, image/jpeg"
                                           id="category"
                                    />
                                        <span
                                            className="layerUpload d-flex align-items-center text-center justify-content-center">انتخاب فایل</span>
                                    </span>

                                        </div>
                                    </div>
                                    <img className="img-banner mr-2"
                                         src={this.state.showFile ? this.state.showFile : this.props.infoCreateSubject?.media?.exact_url}
                                         alt=""/>
                                </div>
                            </div>

                        </div>
                        <Visitor/>
                        <div className="text-left">
                            <button className="submit-btn-blue-border w-auto px-3 py-2 d-inline-block ml-2"> آرشیو
                                مطلب
                            </button>
                            {
                                this.state.update
                                    ?
                                    <button
                                        className={`${this.state.ready ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}

                                        disabled={!this.state.ready}
                                        onClick={() => this.props.updateSubject(this.props.match.params.slug).then(r => this.setState({
                                            next: true
                                        })).catch(r => message.error('با خطا مواجه شدید'))}>ذخیره تغییرات</button>
                                    :
                                    <button
                                        className={`${this.state.ready ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}

                                        disabled={!this.state.ready}
                                        onClick={this.sendCategory}>ذخیره دسته بندی</button>
                            }
                        </div>
                    </div>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (...data) => dispatch(uploadFile(...data)),
        resultCreateSubjects: (...data) => dispatch(resultCreateSubjects(...data)),
        createSubject: (...data) => dispatch(createSubject(...data)),
        updateSubject: (...data) => dispatch(updateSubject(...data)),
        getCategory: (...data) => dispatch(getCategory(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoMedia: store.infoMedia,
        infoCreateSubject: store.infoCreateSubject,

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewSubject);
