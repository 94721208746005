import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import today from "images/icon/driver/Nerw today drives.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaTrashAlt,MdModeEdit} from "react-icons/all";
import {getDriver, changeDetailDriver, authDetail, numberUsers, deleteUser} from "redux/dashboard/action";
import {connect} from "react-redux";
import {Spin, Pagination} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {NavLink as NavLinkRouter, Redirect , Link} from "react-router-dom";
import {QueryMaker} from "components/users/Query";
import FilterUser from "components/users/filter";
import FilterPrivate from "components/users/driver/filter";
import moment from "moment-jalaali";
import * as QueryString from "query-string";

class Drivers extends Component {


    state = {
        dropdownOpen: false,
        drivers: [],
        driversCount: 0,
        page: 1 ,
        dashboard: {},
        params: {search: '', city: '', page:  1},
        search: '',
        city: ""
    }


    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {

        if( Object.keys(QueryString.parse(this.props.location.search))?.length ){
            this.setState({
                params: {search: '', city: '', page: Number((QueryString.parse(this.props.location.search)?.page) || 1)},
            },()=>{
                this.handleRequest();
                const { history } = this.props;
                history.push(`/drivers`);
            })
        }else{
            this.handleRequest();
        }
        this.props.numberUsers("driver").then(
            r => {
                this.setState({
                    dashboard: r?.result
                })
            }
        )
    }

    handleRequest = () => {
        let params={}
       params = QueryMaker(this.state.params);
        this.props.getDriver(params).then(r => {
            if (r?.result) {
                this.setState({
                    drivers: r?.result,
                    driversCount: r?.count,
                })
            }
        })
    }

    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            },
        }, () => this.handleRequest());
        console.log(p);
    };

    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                city: c
            }
        });
    };



    render() {
        const {drivers} = this.state;

        return (
            <>
                <Main>

                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کاربران"
                                  number={this.state.dashboard?.total_count}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="رانندگان جدید این ماه"
                                  number={this.state.dashboard?.current_month_count}>
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="رانندگان جدید ماه قبل"
                                  number={this.state.dashboard?.last_month_count}>
                                <img src={last} alt=""/>
                            </Card>
                            <Card name="رانندگان جدید امروز"
                                  number={this.state.dashboard?.today_count}>
                                <img src={today} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <p className="main-title text-right">رانندگان</p>

                            <div className="">
                                <div className="row">

                                    <FilterUser
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}
                                        getProvince={this.getProvince}
                                    />

                                    <FilterPrivate
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}
                                        handleRequest={() => {

                                            this.setState({
                                                params: {
                                                    ...this.state.params,
                                                    page: 1
                                                }
                                            }, () => this.handleRequest())
                                        }}
                                        getProvince={this.getProvince}
                                    />

                                </div>
                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th className="text-right">نام و نام خانوادگی</th>
                                        <th>شماره همراه</th>
                                        <th className="text-right">شهر</th>
                                        <th className="text-right">وسیله نقلیه</th>
                                        <th className="text-right"> کدملی</th>
                                        <th>تاریخ عضویت</th>
                                        {/*<th>جنسیت</th>
                                    <th>سال تولد</th>*/}
                                        <th>احراز هویت</th>
                                        <th>ویرایش</th>
                                        {/*<th>حذف</th>*/}
                                        <th>فعال/مسدود</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        drivers.map((d, i) => <tr>
                                            <td>{((Number(this.state.params?.page) > 1) ? ((Number(this.state.params?.page) - 1) * 10) : 0) + (i + 1)}</td>
                                            <td className="text-right">
                                                <NavLinkRouter to={`/driver-detail/?page=${this.state.params?.page}`}
                                                               onClick={() => this.props.changeDetailDriver(d)}>
                                                    {(d?.first_name || '') + ' ' + (d?.last_name || '') + ((!((d?.first_name) && (d?.last_name))) ? 'بیان نشده' : '')}</NavLinkRouter>
                                            </td>
                                            <td>{d?.owner?.mobile_number}</td>
                                            <td className="text-right">{(d?.location?.city?.province?.name || '') + '،'+(d?.location?.city?.name || '')}</td>
                                            <td className="text-right">{(d?.vehicles_info[0]?.vehicle?.car?.title || '') + '،'+(d?.vehicles_info[0]?.vehicle?.option?.title || '')}</td>
                                            <td className="text-right">{(d?.national_code || '')}</td>
                                            <td>{moment(d?.creation_date,'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') }</td>
                                            {/*<td>آقا</td>
                                        <td>1344</td>*/}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={d?.is_approve}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_approve: e.target.checked
                                                        }, d?.owner?.id, 'driver')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${d.is_approve ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                                {d?.is_in_approve_queue && <span className="text-danger">*</span>}
                                            </td>
                                            <td>
                                                <NavLinkRouter to="/driver-edit"
                                                               onClick={() => this.props.changeDetailDriver(d)}
                                                               className="check-td-green"
                                                ><MdModeEdit/></NavLinkRouter>
                                            </td>
                                            {/*<td>*/}
                                            {/*    <button className="trash-td-pink"*/}
                                            {/*            onClick={() => {*/}
                                            {/*                if (d?.owner?.id) {*/}
                                            {/*                    this.props.deleteUser(d?.owner?.id, 'driver')*/}
                                            {/*                        .then(r => {*/}
                                            {/*                            this.handleRequest();*/}
                                            {/*                        })*/}
                                            {/*                        .catch(e => {*/}
                                            {/*                        })*/}
                                            {/*                }*/}
                                            {/*            }}*/}
                                            {/*    ><FaTrashAlt/></button>*/}
                                            {/*</td>*/}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={d?.is_active}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_active: e.target.checked
                                                        }, d?.owner?.id, 'driver')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${d.is_active ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                            </td>
                                        </tr>)
                                    }


                                    </tbody>

                                </table>
                                <div dir="ltr" className="text-center">
                                    <Pagination defaultCurrent={1} current={this.state.params?.page || 1} responsive={true}
                                                onChange={this.changePage}
                                                showSizeChanger={false}
                                                total={this.state.driversCount}/>
                                </div>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDriver: (...data) => dispatch(getDriver(...data)),
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
        numberUsers: (...data) => dispatch(numberUsers(...data)),
        deleteUser: (...data) => dispatch(deleteUser(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
