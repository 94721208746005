import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {changeDetailServiceProvider, getVehiclesList, getCargoesDriver, authDetail} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import Record from "components/users/record";
import moment from 'moment-jalaali';
import {Educate} from 'components/users/EducareSelectorFilter';
import * as QueryString from "query-string";

class DetailDriver extends Component {
    state = {
        pageNo : QueryString.parse(this.props.location.search),
        customer: [],
        customerCount: 0,
        profile: '',
        national: '',
        smartcode: '',
        modal: false,
        input: null,
        data: '',
        selectedOptionApprove: false,
        vehiclesList: [],
        cargoesNow:[],
        cargoesPast:[],
    }


    componentDidMount() {
        this.props.getVehiclesList().then(r => {
            if (r?.result) {
                this.setState({
                    vehiclesList: r?.result
                })
            }
        });
        this.props.getCargoesDriver(1,0).then(r=>{
            if(r?.result){
                this.setState({
                    cargoesNow:r?.result
                })
            }
        })
        this.props.getCargoesDriver(1,1).then(r=>{
            if(r?.result){
                this.setState({
                    cargoesPast:r?.result
                })
            }
        });
        if (this.props.infoDetailServiceProvider?.media) {
            let profile = this.props.infoDetailServiceProvider?.media.filter(t => t?.type === "serviceproviderprofile");
            let national = this.props.infoDetailServiceProvider?.media.filter(t => t?.type === "national");
            let smartcode = this.props.infoDetailServiceProvider?.media.filter(t => t?.type === "smartcode");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url,
                    selectedOptionApprove: this.props.infoDetailServiceProvider?.is_approve
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }
            if (smartcode.length !== 0) {
                this.setState({
                    smartcode: smartcode[0]?.exact_url
                })
            }

        }
    }

    handleReturn = () => {
        window.location.href  =  `#/service-provider/?page=${this.state.pageNo.page}`
    }

    render() {
        const {infoDetailServiceProvider} = this.props;
        return (
            <>
                <Main>
                <button onClick={this.handleReturn}  className="btn-return-page">بازگشت</button>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کل سفرها"
                                  number="5435">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های موفق"
                                  number="5435">
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های ناموفق"
                                  number="5435">
                                <img src={last} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <p className="main-title text-right d-flex align-items-center">
                                    <span className="profile-image ml-2" style={{
                                        backgroundImage: `url(${this.state.profile})`
                                    }}/>
                                <span>{infoDetailServiceProvider?.first_name}</span>
                            </p>
                            <div className="row text-right">
                                <div className="col-12 col-md-6 col-xl-4  mt-4"><span>شماره همراه : </span>
                                    <span>{infoDetailServiceProvider?.owner?.mobile_number}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4  mt-4"><span>محل زندگی : </span>
                                    <span>{infoDetailServiceProvider?.location?.city?.province?.name + ' ، ' + infoDetailServiceProvider?.location?.city?.name}</span>


                                </div>
                                <div className="col-12 col-md-6 col-xl-4  mt-4"><span>تاریخ تولد : </span>
                                    <span>{
                                        moment(infoDetailServiceProvider?.birth_date, 'jYYYY-jMM-jDD').isValid() ?
                                        moment(infoDetailServiceProvider?.birth_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4  mt-4"><span>تحصیلات : </span>
                                    <span>{Educate.filter((t) => t.value === infoDetailServiceProvider?.education_degree)[0]?.label}</span>

                                </div>


                            </div>

                        </div>

                        <div className="main-page-inside mt-4 text-right">
                            <p className="main-title text-right d-flex align-items-center">
                                <span className="pl-2"> احراز هویت</span>
                            </p>
                                <p className="text-right d-flex align-items-center">
                                <span className="pl-2"> فعال/مسدود</span>
                                <label className="switch" title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                    <input type="checkbox"
                                           defaultChecked={infoDetailServiceProvider?.is_active}
                                           onChange={(e)=> {
                                               this.props.changeDetailServiceProvider({is_active: e.target.checked})
                                               this.props.authDetail({
                                                   is_active: e.target.checked
                                               }, infoDetailServiceProvider?.owner?.id, 'driver')
                                                   .then(r => {
                                                       if ((r?.statusCode == 200) && r?.result?.is_active)
                                                           this.props.changeDetailServiceProvider({is_active: r?.result?.is_active})
                                                   })
                                           }}
                                    />
                                    <span className="slider"/>
                                </label>
                                <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailServiceProvider?.is_active ?
                                                            <span className="text-success">(کاربر فعال شده)</span>
                                                            :
                                                            <span className="text-pink">(کاربر مسدود شد)</span>
                                                    }
                                                </span>

                            </p>
                            <div className="row text-right">
                                <div className="col-12 col-md-6   mt-4"><span>کد ملی : </span>
                                    <span>{infoDetailServiceProvider?.national_code}</span>
                                </div>
                                <div className="col-12 col-md-6   mt-4">
                                    <div className="d-flex align-items-center">
                                        <span>عکس کارت ملی : </span>
                                        <a href={this.state.national} target="_blank" dir="ltr"
                                           className="text-wrap-width">{this.state.national}</a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6   mt-4"><span>شماره هوشمند راننده : </span>
                                    <span>{infoDetailServiceProvider?.smart_driver_code}</span>
                                </div>
                                <div className="col-12 col-md-6   mt-4">
                                    <div className="d-flex align-items-center ">
                                        <span>عکس کارت هوشمند راننده : </span>
                                        <a href={this.state.smartcode} target="_blank" dir="ltr"
                                           className="text-wrap-width">{this.state.smartcode}</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Record
                            list={[{name:'سفرهای جاری' , cargo:this.state.cargoesNow,tag:null}, {name:'سفرهای موفق' , cargo:this.state.cargoesPast,tag:null} ]}/>

                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeDetailServiceProvider: (...data) => dispatch(changeDetailServiceProvider(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        getCargoesDriver: (...data) => dispatch(getCargoesDriver(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailServiceProvider: store.infoDetailServiceProvider,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailDriver);
