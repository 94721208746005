import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import today from "images/icon/driver/Nerw today drives.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaSearch, FaTrashAlt} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";
import {MdCheckCircle, MdModeEdit} from "react-icons/md";
import {Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {QueryMaker} from "components/users/Query";
import FilterUser from "components/users/filter";
import FilterPrivate from "components/users/serviceProvider/filter";
import {
    getServiceProvider,
    changeDetailServiceProvider,
    authDetail,
    numberUsers,
    deleteUser
} from "redux/dashboard/action";
import {connect} from "react-redux";
import {Pagination, Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";
import moment from "moment-jalaali";
import ProvinceSelector from "../ProvinceSelector";
import * as QueryString from "query-string";

// const Filter = [
//     {label: 'همه خدمت دهندگان', value: ''},
//     {label: 'خدمت دهندگان امروز', value: '&date_min=' + moment().format('jYYYY-jMM-jDD')},
//     {label: 'خدمت دهندگان ماه جدید', value: '&date_min=' + moment().format('jYYYY-jMM-01')},
//     {label: 'در انتظار تایید', value: '&is_in_approve_queue=true'},
// ]
class ServiceProvider extends Component {

    state = {
        dropdownOpen: false,
        drivers: [],
        driversCount: 0,
        page: 1,
        params: {search: '', city: '', page: 1},
        search:'',
        city:""
    }
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {

        if( Object.keys(QueryString.parse(this.props.location.search))?.length ){
            this.setState({
                params: {search: '', city: '', page: Number((QueryString.parse(this.props.location.search)?.page) || 1)},
            },()=>{
                this.handleRequest(); 
                const { history } = this.props;
                history.push(`/service-provider`);
            })
        }else{
            this.handleRequest();
        }
        this.props.numberUsers("serviceprovider").then(
            r => {
                this.setState({
                    dashboard: r?.result
                })
            }
        )




        // this.handleRequest();
        // this.props.numberUsers("serviceprovider").then(
        //     r=>{
        //         this.setState({
        //             dashboard:r?.result
        //         })
        //     }
        // )
    }

    handleRequest = () => {
        let params = QueryMaker(this.state.params);
        this.props.getServiceProvider(params).then(r => {
            if (r?.result) {
                this.setState({
                    drivers: r?.result,
                    driversCount: r?.count,
                })
            }
        })
    }
    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            }
        }, () => this.handleRequest());

    };
    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                city: c
            }
        });

    };
    render() {
        const {drivers} = this.state;

        console.log("Service_state",this.state);
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row ">
                            <Card name="تعداد کاربران"
                                  number={this.state.dashboard?.total_count}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="خدمت دهندگان جدید این ماه"
                                  number={this.state.dashboard?.current_month_count}>
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="خدمت دهندگان جدید ماه قبل"
                                  number={this.state.dashboard?.last_month_count}>
                                <img src={last} alt=""/>
                            </Card>
                            <Card name="خدمت دهندگان جدید امروز"
                                  number={this.state.dashboard?.today_count}>
                                <img src={today} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">

                                <p className="main-title text-right">خدمت دهندگان</p>
                            <div className="mt-1">
                                <div className="row">
                                    <FilterUser
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            filter: t,
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}

                                        getProvince={this.getProvince}
                                    />
                                    <FilterPrivate
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            filter: t,
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}
                                        handleRequest={() => {

                                            this.setState({
                                                params: {
                                                    ...this.state.params,
                                                    page: 1
                                                }
                                            }, () => this.handleRequest())
                                        }}/>
                                {/*    <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4">*/}
                                {/*    <ProvinceSelector getNewProvince={this.getProvince}/>*/}
                                {/*</div>*/}

                                {/*    <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4">*/}
                                {/*<div className="d-flex align-items-center">*/}
                                {/*    <div className="d-flex align-items-center ">*/}
                                {/*        <span className="ml-2">دسته بندی براساس</span>*/}
                                {/*        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>*/}
                                {/*            <DropdownToggle className="dropdown-btn bg border px-2">*/}
                                {/*                <span>{this.state.filter?.label}</span>*/}
                                {/*                <span className="pr-2"><IoIosArrowDown/></span>*/}
                                {/*            </DropdownToggle>*/}
                                {/*            <DropdownMenu style={{right: 0}}>*/}
                                {/*                {*/}
                                {/*                    Filter.map(t => (t?.value !== this.state.filter?.value) && <button className="dropdown-btn-item"*/}
                                {/*                                                                                       onClick={() => {*/}
                                {/*                                                                                           this.setState({filter:t})*/}
                                {/*                                                                                           this.toggle()*/}
                                {/*                                                                                           this.handleRequest(this.state.search, t?.value)*/}
                                {/*                                                                                       }}>{t?.label}*/}
                                {/*                    </button>)*/}
                                {/*                }*/}
                                {/*            </DropdownMenu>*/}
                                {/*        </Dropdown>*/}
                                {/*    </div>*/}

                                {/*</div> </div>*/}
                                {/*        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4">*/}
                                {/*        <div className="position-relative">*/}
                                {/*            <input type="text" className="input-style-public-border bg pr-2 border"*/}
                                {/*                   placeholder="جستجو"*/}
                                {/*                   onChange={(e) => {*/}
                                {/*                       this.setState({search:e.target.value,page:1})*/}
                                {/*                       e.target.value ?*/}
                                {/*                           this.handleRequest(e.target.value)*/}
                                {/*                           :*/}
                                {/*                           this.handleRequest('')*/}
                                {/*                   }}/>*/}
                                {/*            <span className="iconLogin text-player"><FaSearch/></span>*/}
                                {/*        </div>*/}
                                {/*        </div>*/}


                                    </div>
                                </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th className="text-right">نام و نام خانوادگی</th>
                                        <th>شماره همراه</th>
                                        <th>تاریخ عضویت</th>
                                        {/*<th>جنسیت</th>
                                    <th>سال تولد</th>*/}
                                        <th>احراز هویت</th>
                                        <th>ویرایش</th>
                                        {/*<th>حذف</th>*/}
                                        <th>فعال/مسدود</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        drivers.map((d, i) => <tr>
                                            <td>{((Number(this.state.params?.page)>1) ? ((Number(this.state.params?.page)-1) *10) : 0)+ (i + 1)}</td>
                                            <td className="text-right">
                                                <NavLinkRouter to={`/service-provider-detail/?page=${this.state.params?.page}`}
                                                               onClick={() => this.props.changeDetailServiceProvider(d)}>
                                                    {(d?.first_name || '') + ' ' + (d?.last_name || '')+(!(d?.first_name && d?.last_name) ? 'بیان نشده' :'')}</NavLinkRouter>
                                            </td>
                                            <td>{d?.owner?.mobile_number}</td>
                                            <td>{moment(d?.creation_date,'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') }</td>
                                            {/*<td>آقا</td>
                                        <td>1344</td>*/}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={d?.is_approve}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_approve: e.target.checked
                                                        }, d?.owner?.id, 'serviceprovider')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${d.is_approve ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                                {d?.is_in_approve_queue && <span className="text-danger">*</span>}
                                            </td>
                                            <td>
                                                <NavLinkRouter to="/driver-edit"
                                                               onClick={() => this.props.changeDetailServiceProvider(d)}
                                                               className="check-td-green"
                                                ><MdModeEdit/></NavLinkRouter>
                                            </td>
                                            {/*<td>*/}
                                            {/*    <button className="trash-td-pink"*/}
                                            {/*            onClick={() => {*/}
                                            {/*                if(d?.owner?.id){*/}
                                            {/*                    this.props.deleteUser(d?.owner?.id, 'serviceprovider')*/}
                                            {/*                        .then(r => {*/}
                                            {/*                            this.handleRequest();*/}
                                            {/*                        })*/}
                                            {/*                        .catch(e => {*/}
                                            {/*                        })*/}
                                            {/*                }}}><FaTrashAlt/></button>*/}
                                            {/*</td>*/}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={d?.is_active}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_active: e.target.checked
                                                        }, d?.owner?.id, 'serviceprovider')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${d.is_active ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                            </td>
                                        </tr>)
                                    }


                                    </tbody>

                                </table>
                                <div dir="ltr" className="text-center">
                                    <Pagination defaultCurrent={1} current={this.state.params?.page || 1} responsive={true}
                                                onChange={this.changePage}
                                                showSizeChanger={false}
                                                total={this.state.driversCount}/>
                                </div>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceProvider: (...data) => dispatch(getServiceProvider(...data)),
        changeDetailServiceProvider: (...data) => dispatch(changeDetailServiceProvider(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
        numberUsers: (...data) => dispatch(numberUsers(...data)),
        deleteUser: (...data) => dispatch(deleteUser(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider);
