import React, {useState} from 'react';
import {MdModeEdit} from "react-icons/md";
import {Modal, ModalBody} from "reactstrap";
import {fetcher} from "../../../redux/common/action";
import {UrlQuery} from "../../../utils/utils";

const ModalShop = ({item,getData}) => {
    const [toggle, setToggle] = useState(false);
    const [value, setValue] = useState("0");
    const sendData = () => {
        if(item.id)
        fetcher(UrlQuery(`panel/service-pockets/${item.id}/`,),
            {
                method: 'PUT',
                body: JSON.stringify({debt_limit:value})
            },)
            .then((response) => {
                if(response.statusCode === 200){
                    getData()
                    setToggle(false)
                }
            }).catch((err) => {

        })
    }
    return (
        <div>
            <div>
                <span>{item.debt_limit}</span>
                <button className="text-MainColor ml-3" onClick={() => setToggle(true)}><MdModeEdit/></button>

            </div>
            <Modal centered={true} isOpen={toggle} toggle={() => setToggle(false)} className="modal-login">
                <ModalBody className="border-radius-5 ">
                    <p className="main-title text-right d-flex align-items-center">
                        تعیین سقف بدهی
                    </p>

                    <div className="text-right mt-4">
                        <label htmlFor="debt" className="mb-1">سقف بدهی</label>
                        <input type="text" id="debt" className="input-style-public-border pr-2"  onChange={(e)=>{
                            setValue(e.target.value)
                        }}/>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <button onClick={() => setToggle(false)}
                                className="submit-btn-border py-1 w-auto px-4 ml-2"> انصراف
                        </button>
                        <button onClick={sendData} className="submit-btn py-1 w-auto px-4 "> تایید و
                            ذخیره
                        </button>

                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ModalShop;
