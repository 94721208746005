import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {authDetail, changeDetailCustomer, getCargoesCustomer, getCustomer} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import Record from "components/users/record";
import moment from 'moment-jalaali';
import * as QueryString from "query-string";
import classnames from "classnames";
import Score from "./score";
import Payments from "./payments";
import Shop from "./shop";

const listTabs = [
    {name: 'looking', label: 'اطلاعات کاربری', id: 1},
    {name: 'done', label: 'بارها', id: 2},
    {name: 'cancel', label: 'تراکنش ها', id: 3},
    {name: 'cancel', label: 'تاریخچه اعتبار', id: 4},
    {name: 'cancel', label: 'فروشگاه', id: 5},
]

class CustomerDetail extends Component {
    state = {
        pageNo: QueryString.parse(this.props.location.search),
        customer: [],
        customerCount: 0,
        profile: '',
        national: '',
        smartcode: '',
        cargoesNow: [],
        cargoesPast: [],
        cargoesCancel: [],
        reserved: [],
        carrying: [],
        data: moment(),
        activeTab: '1',
    }

    componentDidMount() {
        this.getCargoes();
        if (this.props.infoDetailCustomer?.media) {
            let profile = this.props.infoDetailCustomer?.media.filter(t => t?.type === "profile");
            let national = this.props.infoDetailCustomer?.media.filter(t => t?.type === "national");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }

        }
    }

    getCargoes = () => {
        this.props.getCargoesCustomer(1, '', this.props.infoDetailCustomer?.owner?.id,'1').then(r => {
            if (r?.result) {
                this.setState({
                    cargoesNow: r?.result
                })
            }
        })
        this.props.getCargoesCustomer(1, '2,3', this.props.infoDetailCustomer?.owner?.id).then(r => {
            if (r?.result) {
                // console.log("cancel",r.result)
                this.setState({
                    reserved: r?.result,
                })
            }
        })
        this.props.getCargoesCustomer(1, '1,4,5', this.props.infoDetailCustomer?.owner?.id,'1,3&is_tracked=true').then(r => {
            if (r?.result) {
                // console.log("cancel",r.result)
                this.setState({
                    carrying: r?.result,
                })
            }
        })
        this.props.getCargoesCustomer(1, '', this.props.infoDetailCustomer?.owner?.id,'0').then(r => {
            if (r?.result) {
                // console.log("cancel",r.result)
                this.setState({
                    cargoesCancel: r?.result,
                })
            }
        })

        this.props.getCargoesCustomer(1, '', this.props.infoDetailCustomer?.owner?.id,'2').then(r => {
            if (r?.result) {
                // console.log(r.result)
                this.setState({
                    cargoesPast: r?.result,
                })
            }
        })
    }


    handleReturn = () => {
        window.location.href = `#/customer/?page=${this.state.pageNo.page}`
    }


    render() {
        const {infoDetailCustomer} = this.props;
        return (
            <>
                <Main>
                    <button onClick={this.handleReturn}
                            style={{position: 'absolute', top: '70px', left: '10px', zIndex: '99999'}}
                            className="btn-return-page">بازگشت
                    </button>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کل سفرها"
                                  number="5435">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های موفق"
                                  number="5435">
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های ناموفق"
                                  number="5435">
                                <img src={last} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <Nav
                                className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                {
                                    listTabs.map((item, i) => <NavItem className="flex-grow-1">
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                            onClick={() => {
                                                if (this.state.activeTab !== (i + 1).toString()) {
                                                    this.setState({
                                                        activeTab: (i + 1).toString()
                                                    })
                                                }
                                            }}
                                        >
                                            {item?.label}
                                        </NavLink>
                                    </NavItem>)
                                }

                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="mt-5">
                                        <p className="main-title text-right d-flex align-items-center">
                                    <span className="profile-image ml-2"
                                          style={{backgroundImage: `url(${this.state.profile})`}}/>
                                            <span>{(infoDetailCustomer?.first_name || '') + ' ' + (infoDetailCustomer?.last_name || '')}</span>

                                        </p>
                                        <div className="row text-right">
                                            <div className="col-12 col-md-6 col-xl-4  mt-4"><span>شماره همراه : </span>
                                                <span>{infoDetailCustomer?.owner?.mobile_number}</span></div>
                                            <div className="col-12 col-md-6 col-xl-4  mt-4">
                                                <span>محل زندگی : </span>
                                                <span>{(infoDetailCustomer?.location?.city?.province?.name || '') + ' ، ' + (infoDetailCustomer?.location?.city?.name || '')}</span>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-4  mt-4">
                                                <span>تاریخ تولد : </span>
                                                <span>{infoDetailCustomer?.birth_date ? moment(infoDetailCustomer?.birth_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-4  mt-4">
                                                <span>محل کار : </span>
                                                <span>{(infoDetailCustomer?.location?.city?.province?.name || '') + ' ، ' + (infoDetailCustomer?.location?.city?.name || '')}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-5 text-right">
                                        <p className="main-title text-right d-flex align-items-center">
                                            <span className="pl-2"> احراز هویت</span>
                                        </p>

                                        <div className="row text-right">
                                            <div className="col-12 col-md-6  mt-4">
                                                <p className="text-right d-flex align-items-center">
                                                    <span className="pl-2"> احراز هویت</span>
                                                    <label className="switch"
                                                           title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                                        <input type="checkbox"
                                                               defaultChecked={infoDetailCustomer?.is_approve}
                                                               onChange={(e) => {
                                                                   this.props.changeDetailCustomer({is_approve: e.target.checked})
                                                                   this.props.authDetail({
                                                                       is_approve: e.target.checked
                                                                   }, infoDetailCustomer?.owner?.id, 'customer')
                                                                       .then(r => {
                                                                           if ((r?.statusCode == 200) && r?.result?.is_approve)
                                                                               this.props.changeDetailCustomer({is_approve: r?.result?.is_approve})
                                                                       })
                                                               }}
                                                        />
                                                        <span className="slider"/>
                                                    </label>
                                                    <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailCustomer?.is_approve ?
                                                            <span className="text-success">(کاربر تایید شد)</span>
                                                            :
                                                            <span className="text-pink">(کاربر تایید نشده)</span>
                                                    }
                                                </span>

                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6  mt-4">
                                                <p className="text-right d-flex align-items-center">
                                                    <span className="pl-2"> فعال/مسدود</span>
                                                    <label className="switch"
                                                           title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                                        <input type="checkbox"
                                                               defaultChecked={infoDetailCustomer?.is_active}
                                                               onChange={(e) => {
                                                                   this.props.changeDetailCustomer({is_active: e.target.checked})
                                                                   this.props.authDetail({
                                                                       is_active: e.target.checked
                                                                   }, infoDetailCustomer?.owner?.id, 'customer')
                                                                       .then(r => {
                                                                           if ((r?.statusCode == 200) && r?.result?.is_active)
                                                                               this.props.changeDetailCustomer({is_active: r?.result?.is_active})
                                                                       })
                                                               }}
                                                        />
                                                        <span className="slider"/>
                                                    </label>
                                                    <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailCustomer?.is_active ?
                                                            <span className="text-success">(کاربر فعال شده)</span>
                                                            :
                                                            <span className="text-pink">(کاربر مسدود شد)</span>
                                                    }
                                                </span>

                                                </p>
                                            </div>
                                        </div>
                                        <div className="row text-right">
                                            <div className="col-12 col-md-6  mt-4">
                                                <span>کد ملی : </span>
                                                <span>{infoDetailCustomer?.national_code}</span>
                                            </div>
                                            <div className="col-12 col-md-6  mt-4">
                                                <div className="d-flex align-items-center">
                                                    <span>عکس کارت ملی : </span>
                                                    <a href={this.state.national} target="_blank" dir="ltr"
                                                       className="text-wrap-width">{this.state.national}</a>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 ">
                                        <p className="main-title text-right d-flex align-items-center">
                                            توضیحات</p>
                                        {/*{console.log(infoDetailCustomer)}*/}
                                        <textarea name="" id="" cols="30" rows="4"
                                                  className="input-style-public-border p-3 mt-3"
                                                  defaultValue={infoDetailCustomer?.admin_description ? infoDetailCustomer?.admin_description : ''}
                                                  onChange={e => this.props.changeDetailCustomer({admin_description: e.target.value})}/>
                                        <button className="btn-success"
                                                disabled={!infoDetailCustomer?.admin_description}
                                                onClick={() => {
                                                    this.props.authDetail({
                                                        admin_description: infoDetailCustomer?.admin_description
                                                    }, infoDetailCustomer?.owner?.id, 'customer')
                                                }}>افزودن توضیح
                                        </button>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Record
                                        mainClass={true}
                                        getListCargoes={this.getCargoes}
                                        list={[
                                            {name: 'سفارش‌های جاری', cargo: this.state.cargoesNow,tag:null},
                                            {name: ' رزروشده', cargo: this.state.reserved,tag: 'تایید نشده'},
                                            {name: ' درحال حمل', cargo: this.state.carrying,tag: 'تایید شده'},
                                            {name: 'حمل شده', cargo: this.state.cargoesPast,tag: null}
                                            , {
                                                name: 'لغو شده',
                                                cargo: this.state.cargoesCancel,
                                                tag:null
                                            }]}/>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Payments infoDetailCustomer={infoDetailCustomer}/>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Score infoDetailCustomer={infoDetailCustomer}/>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Shop infoDetailCustomer={infoDetailCustomer}/>
                                </TabPane>
                            </TabContent>

                        </div>


                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (...data) => dispatch(getCustomer(...data)),
        changeDetailCustomer: (...data) => dispatch(changeDetailCustomer(...data)),
        getCargoesCustomer: (...data) => dispatch(getCargoesCustomer(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),


    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailCustomer: store.infoDetailCustomer,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);


