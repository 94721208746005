import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import today from "images/icon/driver/Nerw today drives.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaSearch, FaTrashAlt} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";
import {MdCheckCircle, MdModeEdit} from "react-icons/md";
import {Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {authDetail, changeDetailCustomer, deleteUser, getCustomer, numberUsers} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Pagination, Spin} from "antd";
import {NavLink as NavLinkRouter} from "react-router-dom";
import moment from "moment-jalaali";
import ProvinceSelector from "components/users/ProvinceSelector";
import {QueryMaker} from "components/users/Query";
import FilterUser from "components/users/filter";
import FilterPrivate from "components/users/customer/filter";
import * as QueryString from "query-string";
// const Filter = [
//     {label: 'همه صاحبان بار', value: ''},
//     {label: 'صاحبان بار امروز', value: '&date_min=' + moment().format('jYYYY-jMM-jDD')},
//     {label: 'صاحبان بار ماه جدید', value: '&date_min=' + moment().format('jYYYY-jMM-01')},
//     {label: 'در انتظار تایید', value: '&is_in_approve_queue=true'},
// ]
class Customer extends Component {

    state = {
        dropdownOpen: false,
        customer: [],
        customerCount: 0,
        page: 1,
        params: {search: '', city: '', page: 1},
        dashboard: {},
        search: '',
        city: ""
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };



    componentDidMount() {
        if( Object.keys(QueryString.parse(this.props.location.search))?.length ){
            this.setState({
                params: {search: '', city: '', page: Number((QueryString.parse(this.props.location.search)?.page) || 1)},
            },()=>{
                this.handleRequest();
                const { history } = this.props;
                history.push(`/customer`);
            })
        }else{
            this.handleRequest();
        }
        this.props.numberUsers("customer").then(
            r => {
                this.setState({
                    dashboard: r?.result
                })
            }
        )
    }

    handleRequest = () => {
        let params = QueryMaker(this.state.params);
        this.props.getCustomer(params).then(r => {
            if (r?.result) {
                this.setState({
                    customer: r?.result,
                    customerCount: r?.count,
                })
            }
        })
    };

    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            }
        }, () => this.handleRequest());

    };

    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                city: c
            }
        });

    };


    render() {

        // console.log("Customer_state",this.state);
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کل صاحبان بار"
                                  number={this.state.dashboard?.total_count}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="صاحبان بار جدید ماه جاری"
                                  number={this.state.dashboard?.current_month_count}>
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="صاحبان بار جدید ماه قبل"
                                  number={this.state.dashboard?.last_month_count}>
                                <img src={last} alt=""/>
                            </Card>
                            <Card name="صاحبان بار جدید امروز"
                                  number={this.state.dashboard?.today_count}>
                                <img src={today} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">

                            <p className="main-title text-right">صاحبان بار</p>
                            <div className="mt-1">
                                <div className="row">
                                    <FilterUser
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            filter: t,
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}

                                        getProvince={this.getProvince}
                                    />
                                    <FilterPrivate
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                        filter: t,
                                        params: {...this.state.params, [t.name]: (t.value)}
                                    })}
                                                   handleRequest={() => {

                                                       this.setState({
                                                           params: {
                                                               ...this.state.params,
                                                               page: 1
                                                           }
                                                       }, () => this.handleRequest())
                                                   }}

                                    />


                                </div>

                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th className="text-right">نام و نام خانوادگی</th>
                                        <th>شماره همراه</th>
                                        <th>محل</th>
                                        <th className="text-center"> کدملی</th>
                                        <th className="text-center"> تاریخ عضویت</th>
                                        {/*<th>سال تولد</th>*/}
                                        <th>احراز هویت</th>
                                        <th>ویرایش</th>
                                        {/*<th>حذف</th>*/}
                                        <th>فعال/مسدود</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.customer.map((c, i) => <tr>
                                            <td>{((Number(this.state.params?.page) > 1) ? ((Number(this.state.params?.page) - 1) * 10) : 0) + (i + 1)}</td>
                                            <td className="text-right">
                                                <NavLinkRouter to={`/customer-detail/?page=${this.state.params?.page}`}
                                                               onClick={() => this.props.changeDetailCustomer(c)}>
                                                    {(c?.first_name || '') + " " + (c?.last_name || '') + (!(c?.first_name && c?.last_name) ? 'بیان نشده' : '')}</NavLinkRouter>
                                            </td>
                                            <td>{c?.owner?.mobile_number}</td>
                                            <td>{(c?.location?.city?.province?.name || '') + (c?.location?.city?.province?.name ? '،' : '') + (c?.location?.city?.name || '')
                                            }</td>
                                            <td className="text-center">{(c?.national_code || '')}</td>
                                            {/*<td>1344</td>*/}
                                            <td>{moment(c?.creation_date,'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') }</td>

                                            <td>

                                                <input
                                                    type="checkbox"
                                                    checked={c?.is_approve}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_approve: e.target.checked
                                                        }, c?.owner?.id, 'customer')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${c.is_approve ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                                {c?.is_in_approve_queue && <span className="text-danger">*</span>}
                                            </td>
                                            <td><NavLinkRouter to="/customer-edit"
                                                               onClick={() => this.props.changeDetailCustomer(c)}
                                                               className="check-td-green"><MdModeEdit/></NavLinkRouter>
                                            </td>
                                            {/*<td>*/}
                                            {/*    <button className="trash-td-pink"*/}
                                            {/*            onClick={() => {*/}
                                            {/*                if (c?.owner?.id) {*/}
                                            {/*                    this.props.deleteUser(c?.owner?.id, 'customer')*/}
                                            {/*                        .then(r => {*/}
                                            {/*                            this.handleRequest();*/}
                                            {/*                        })*/}
                                            {/*                        .catch(e => {*/}
                                            {/*                        })*/}
                                            {/*                }*/}
                                            {/*            }}><FaTrashAlt/></button>*/}
                                            {/*</td>*/}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={c?.is_active}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_active: e.target.checked
                                                        }, c?.owner?.id, 'customer')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${c.is_active ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                            </td>
                                        </tr>)
                                    }


                                    </tbody>

                                </table>
                                <div dir="ltr" className="text-center">
                                    <Pagination defaultCurrent={1} current={this.state.params?.page || 1} responsive={true}
                                        onChange={this.changePage}
                                        showSizeChanger={false}
                                        total={this.state.customerCount}/>
                                </div>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (...data) => dispatch(getCustomer(...data)),
        changeDetailCustomer: (...data) => dispatch(changeDetailCustomer(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
        numberUsers: (...data) => dispatch(numberUsers(...data)),
        deleteUser: (...data) => dispatch(deleteUser(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Customer);
