import React from 'react';
import Main from "components/publicComponents/main";
import Box from "components/survey/box";
import {useParams} from "react-router-dom";

const Detail = () => {
    const paramsUrl = useParams();
    return (
        <div>
            <Main>
                <div className="main-page-inside mt-4">
                <Box user={paramsUrl.user} id={paramsUrl.slug} role={paramsUrl.role} owner={paramsUrl.owner}/>
                </div>
            </Main>
        </div>
    );
};

export default Detail;
