import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import today from "images/icon/driver/Nerw today drives.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaSearch, FaTrashAlt} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";
import {MdCheckCircle, MdModeEdit} from "react-icons/md";
import {Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {
    getCustomer,
    getCargoesTransporterSend,
    getCargoesTransporterRecived,
    changeDetailTransporter, authDetail
} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import Record from "components/users/record";
import moment from 'moment-jalaali';
import * as QueryString from "query-string";


class DetailTransporter extends Component {
    state = {
        pageNo : QueryString.parse(this.props.location.search),
        dropdownOpen: false,
        customer: [],
        customerCount: 0,
        profile: '',
        national: '',
        activity: '',
        now: true,
        cargoesSendNow: [],
        cargoesRecivedPast: [],
        cargoesRecivedNow: [],
        cargoesSendPast: [],
    }
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        this.infoNow();
        this.infoPast();
        if (this.props.infoDetailTransporter?.media) {
            let profile = this.props.infoDetailTransporter?.media.filter(t => t?.type === "transporterprofile");
            let national = this.props.infoDetailTransporter?.media.filter(t => t?.type === "national");
            let activity = this.props.infoDetailTransporter?.media.filter(t => t?.type === "activity");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }
            if (activity.length !== 0) {
                this.setState({
                    activity: activity[0]?.exact_url
                })
            }

        }
    }

    infoNow = () => {
        this.props.getCargoesTransporterSend('0,2', 1,this.props.infoDetailTransporter?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesSendNow: r?.result
                })
            }
        });

        this.props.getCargoesTransporterRecived(0, 1,this.props.infoDetailTransporter?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesRecivedNow: r?.result
                })
            }
        });
    }
    getCargoes = () => {
        this.infoNow();
        this.infoPast();
    }
    infoPast = () => {
        this.props.getCargoesTransporterRecived(1, 1,this.props.infoDetailTransporter?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({

                    cargoesRecivedPast: r?.result
                })
            }
        });
        this.props.getCargoesTransporterSend(1, 1,this.props.infoDetailTransporter?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesSendPast: r?.result
                })
            }
        });
    }

    handleReturn = () => {
        window.location.href  =  `#/transport/?page=${this.state.pageNo.page}`
    }

    render() {
        const {infoDetailTransporter} = this.props;
        return (
            <>
                <Main>
                <button onClick={this.handleReturn}  className="btn-return-page">بازگشت</button>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کل سفرها"
                                  number="5435">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های موفق"
                                  number="5435">
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های ناموفق"
                                  number="5435">
                                <img src={last} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <p className="main-title text-right d-flex align-items-center">
                                    <span className="profile-image ml-2"
                                          style={{backgroundImage: `url(${this.state.profile})`}}/>
                                <span>{infoDetailTransporter?.company_name}</span>

                            </p>
                            <div className="row text-right">
                                <div className="col-12 col-md-6 col-xl-4  mt-4">
                                    <span>مدیرعامل : </span>
                                    <span>{infoDetailTransporter?.founder_name || ''}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4  mt-4">
                                    <span>مدیرعامل : </span>
                                    <span>{(infoDetailTransporter?.first_name || '') + ' ' + (infoDetailTransporter?.last_name || '')}</span>
                                </div>

                                <div className="col-12 col-md-6 col-xl-4 mt-4">
                                    <span>محل شرکت حمل‌ونقل  : </span>
                                    <span>{(infoDetailTransporter?.location?.city?.province?.name || '') + ' ، ' + (infoDetailTransporter?.location?.city?.name || '')}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4 mt-4">
                                    <span>شماره همراه : </span>
                                    <span>{infoDetailTransporter?.owner?.mobile_number}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4 mt-4">
                                    <span>تلفن ثابت : </span>
                                    <span>{infoDetailTransporter?.phone_number}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4 mt-4">
                                    <span>حوزه کاری : </span>
                                    <span>{infoDetailTransporter?.area_expertise}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4 mt-4">
                                    <span>سال تاسیس : </span>
                                    <span>{
                                        moment(infoDetailTransporter?.established_date,'jYYYY-jMM-jDD').isValid() ?
                                        moment(infoDetailTransporter?.established_date,'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD')
                                             :""
                                    }</span>
                                </div>
                                <div className="col-12 col-md-12 col-xl-6 mt-4">
                                    <span>آدرس دقیق : </span>
                                    <span>{infoDetailTransporter?.location?.address}</span>
                                </div>
                                <div className="col-12 col-md-12 col-xl-6 mt-4">
                                    <span>توضیحات : </span>
                                    <span></span>
                                </div>
                                <div className="col-12 mt-4">
                                <span>
                                        <span>شبکه های اجتماعی : </span>
                                    {/*<a href="#" className="ml-4">Instagram: barayer_co</a>
                                        <a href="#" className="ml-4">Telegram: @barayer_co</a>*/}
                                    {infoDetailTransporter?.social_network &&
                                    Object.keys(infoDetailTransporter?.social_network).map(t =>
                                        <div className="pt-2"><a href={infoDetailTransporter?.social_network?.[t]}
                                                                 className="ml-4">{t.toLowerCase()}:
                                            {infoDetailTransporter?.social_network?.[t]}</a></div>)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="main-page-inside mt-4 text-right">
                            <p className="main-title text-right d-flex align-items-center">
                                <span className="pl-2"> احراز هویت</span>
                            </p>

                            <div className="row text-right">
                                <div className="col-12 col-md-6   mt-4">
                                    <p className="text-right d-flex align-items-center">
                                        <span className="pl-2"> احراز هویت</span>

                                        <label className="switch" title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                            <input type="checkbox"
                                                   defaultChecked={infoDetailTransporter?.is_approve}
                                                   onChange={(e)=> {
                                                       this.props.changeDetailTransporter({is_approve: e.target.checked})
                                                       this.props.authDetail({
                                                           is_approve: e.target.checked
                                                       }, infoDetailTransporter?.owner?.id, 'transportation')
                                                           .then(r => {
                                                               if ((r?.statusCode == 200) && r?.result?.is_approve)
                                                                   this.props.changeDetailTransporter({is_approve: r?.result?.is_approve})
                                                           })
                                                   }}
                                            />
                                            <span className="slider"/>
                                        </label>
                                        <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailTransporter?.is_approve ?
                                                            <span className="text-success">(کاربر تایید شد)</span>
                                                            :
                                                            <span className="text-pink">(کاربر تایید نشده)</span>
                                                    }
                                                </span>

                                    </p>
                                </div>
                                <div className="col-12 col-md-6   mt-4">
                                    <p className="text-right d-flex align-items-center">
                                        <span className="pl-2">فعال/مسدود</span>

                                        <label className="switch" title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                            <input type="checkbox"
                                                   defaultChecked={infoDetailTransporter?.is_active}
                                                   onChange={(e)=> {
                                                       this.props.changeDetailTransporter({is_active: e.target.checked})
                                                       this.props.authDetail({
                                                           is_active: e.target.checked
                                                       }, infoDetailTransporter?.owner?.id, 'transportation')
                                                           .then(r => {
                                                               if ((r?.statusCode == 200) && r?.result?.is_active)
                                                                   this.props.changeDetailTransporter({is_active: r?.result?.is_active})
                                                           })
                                                   }}
                                            />
                                            <span className="slider"/>
                                        </label>
                                        <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailTransporter?.is_active ?
                                                            <span className="text-success">(کاربر فعال شده)</span>
                                                            :
                                                            <span className="text-pink">(کاربر مسدود شد)</span>
                                                    }
                                                </span>

                                    </p>
                                </div>
                                </div>
                            <div className="row text-right">
                                <div className="col-12 col-md-6   mt-4">
                                    <span>کد ملی ادمین : </span>
                                    <span>{infoDetailTransporter?.national_code}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">
                                    <div className="d-flex align-items-center">
                                        <span>تصویر کارت ملی ادمین : </span>
                                        <a href={this.state.national} target="_blank" dir="ltr"
                                           className="text-wrap-width">{this.state.national}</a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6  mt-4">
                                    <span>شماره پروانه فعالیت : </span>
                                    <span>{this.state?.activity_license_number}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">
                                    <div className="d-flex align-items-center">
                                        <span>تصویر پروانه فعالیت شرکت حمل‌ونقل  : </span>
                                        <a href={this.state.activity} target="_blank" dir="ltr"
                                           className="text-wrap-width">{this.state.activity}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-page-inside mt-4 ">
                            <p className="main-title text-right d-flex align-items-center">
                                امتیازات</p>
                            <div className="row text-right">
                                {(infoDetailTransporter?.scores?.length > 0) &&
                                infoDetailTransporter?.scores.map((t,i)=>
                                    <div className="col-12 col-md-6  mt-4"><span>{t?.title} : </span>
                                        <span>{t?.score * t?.count}</span>
                                    </div>)
                                }


                            </div>
                        </div>
                        <div className="main-page-inside mt-4 ">
                            <p className="main-title text-right d-flex align-items-center">
                                توضیحات</p>
                            {console.log(infoDetailTransporter)}
                            <textarea name="" id="" cols="30" rows="4" className="input-style-public-border p-3 mt-3"
                                      defaultValue={infoDetailTransporter?.admin_description ? infoDetailTransporter?.admin_description : '' }
                                      onChange={e => this.props.changeDetailTransporter({admin_description: e.target.value})}/>
                            <button className="btn-success"
                                    disabled={!infoDetailTransporter?.admin_description}
                                    onClick={() => {

                                        this.props.authDetail({
                                            admin_description: infoDetailTransporter?.admin_description
                                        }, infoDetailTransporter?.owner?.id, 'transportation')
                                    }}>افزودن توضیح
                            </button>
                        </div>
                        <Record
                            getListCargoes={this.getCargoes}
                            roll="transportation"
                            handleNow={() => {
                                this.setState({
                                    now: true
                                })
                                this.infoNow()
                            }}
                            handlePast={() => {
                                this.setState({
                                    now: false
                                })
                                this.infoPast()
                            }}
                            now={this.state.now}
                            list={
                                this.state.now ?
                                    [{name: 'سفرهای ارسالی', cargo: this.state.cargoesSendNow,tag:null}, {
                                        name: 'سفرهای دریافتی',
                                        cargo: this.state.cargoesRecivedNow,
                                        tag:null
                                    }]
                                    :
                                    [{
                                        name: 'سفرهای ارسالی',
                                        cargo: this.state.cargoesSendPast,
                                        tag:null
                                    }, {name: 'سفرهای دریافتی', cargo: this.state.cargoesRecivedPast,tag:null}]
                            }/>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (...data) => dispatch(getCustomer(...data)),
        getCargoesTransporterSend: (...data) => dispatch(getCargoesTransporterSend(...data)),
        getCargoesTransporterRecived: (...data) => dispatch(getCargoesTransporterRecived(...data)),
        changeDetailTransporter: (...data) => dispatch(changeDetailTransporter(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailTransporter: store.infoDetailTransporter,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailTransporter);
