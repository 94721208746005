import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts'


const Chart = (props) => {
    const {series,getCitiesData,getProvinceData,extra=50}=props;
    const [options, setOptions] = useState({
        dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            formatter: function (text, op) {
                let val = op.w.config.series[0].data[op.dataPointIndex]?.y - extra;
                return `${text}: ${val}`;
            },

            style: {
                fontSize: '13px',
                fontFamily: 'IRANSans_light ,Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ["#fff"],
                display: "flex",
                flexDirection: "column"
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },

        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return '<div class="arrow_box p-2">' +
                    '<span>' + (w.config.series[0].data[dataPointIndex]?.x) + '</span>' +
                    '<span class="px-1">:</span>' +
                    '<span>' + (w.config.series[0].data[dataPointIndex]?.y - extra) + '</span>' +
                    '</div>'
            },
            style: {
                fontSize: '12px',
                fontFamily: 'IranSans ,Helvetica, Arial, sans-serif'
            },
        },
        legend: {
            show: false
        },

        title: {
            text: '',
            align: 'center'
        },
        // colors: Colors,

        chart: {
            foreColor: '#c61457',
            height: 700,
            type: 'treemap',
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    if (config.w.config.series[0].data[config.dataPointIndex]?.id < 32) {
                        getCitiesData(config.w.config.series[0].data[config.dataPointIndex]?.id)
                    }
                },
                dataPointMouseEnter: function (event, chartContext, config) {

                }
            }
        },
        plotOptions: {
            treemap: {
                distributed: true,
                // enableShades: false
                enableShades: true,
                shadeIntensity: -0.1,
                reverseNegativeShade: true,
                colorScale: {
                    // ranges:range
                    //     [
                    //     {
                    //         from: -8,
                    //         to: 28, // + 8
                    //         color: '#52B12C'
                    //     },
                    //     {
                    //         from: 29,
                    //         to: 48,
                    //         color: '#ffd64e'
                    //     },
                    //     {
                    //         from: 49,
                    //         to: 68,
                    //         color: '#CD363A'
                    //     },
                    //     {
                    //         from: 69,
                    //         to: 1000,
                    //         color: '#302A2A'
                    //     }
                    // ]
                }

            }
        }
    })


    return (<>

                    <div id="chart">
                        <ReactApexChart options={options} series={[{data: series}]} type="treemap" height={700}/>
                    </div>
        </>
    );
};



export default Chart;