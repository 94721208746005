import {fetcher} from "redux/common/action";
import {
    DETAIL_USER,
    CHANGE_DETAIL_DRIVER,
    OLD_AREA_SEARCH,
    CHANGE_DETAIL_CUSTOMER,
    CHANGE_DETAIL_TRANSPORTER,
    CHANGE_DETAIL_SERVICE_PROVIDER,
    DELETE_OLD_AREA_SEARCH
} from 'redux/dashboard/type';
import {message} from "antd";

export const getDetailUser = (data) => {
    return {
        type: DETAIL_USER,
        data
    }
}

export const changeDetailDriver = (data) => {
    return {
        type: CHANGE_DETAIL_DRIVER,
        data
    }
}
export const changeDetailCustomer = (data) => {
    return {
        type: CHANGE_DETAIL_CUSTOMER,
        data
    }
}
export const changeDetailTransporter = (data) => {
    return {
        type: CHANGE_DETAIL_TRANSPORTER,
        data
    }
}
export const changeDetailServiceProvider = (data) => {
    return {
        type: CHANGE_DETAIL_SERVICE_PROVIDER,
        data
    }
};
export const resultOldArea = (data) => {
    return {
        type: OLD_AREA_SEARCH,
        data
    }
};
export const deleteOldArea = (data) => {
    return {
        type: DELETE_OLD_AREA_SEARCH,
        data
    }
};
export const areaSearch = (data = "", page = 1) => {
    return (dispatch) => {
        return fetcher('core/area/search/?q=' + data + '&page=' + page,
            {
                method: 'GET',
            })
            .then(r => r)
    }
};

export const getDashboard = () => {
    return () => {
        return fetcher('panel/dashboard',
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getDriver = (data = '') => {
    return () => {
        return fetcher('panel/user/driver' + '/' + data,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getCustomer = (data = '') => {
    return () => {
        return fetcher('panel/user/customer' + '/' + data,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getTransport = (data = '') => {
    return () => {
        return fetcher('panel/user/transportation' + '/' + data,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getServiceProvider = (data = '') => {
    return () => {
        return fetcher('panel/user/serviceprovider' + '/' + data,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const sanitizeChangeProfile = (newRequest, roll) => {

    switch (roll) {
        case 'driver':
            let point = {
                ...newRequest,
                "location": {
                    ...newRequest.location,
                    city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                },
                "vehicles_info": [{
                    ...newRequest.vehicles_info[0],
                    "vehicle": {
                        "car_id": newRequest.vehicles_info[0]?.vehicle?.car?.id ? newRequest.vehicles_info[0]?.vehicle?.car?.id : newRequest.vehicles_info[0]?.vehicle?.car_id,
                        "option_id": newRequest.vehicles_info[0]?.vehicle?.option?.id ? newRequest.vehicles_info[0]?.vehicle?.option?.id : newRequest.vehicles_info[0]?.vehicle?.option_id
                    },

                }]

            };
            if (!newRequest?.location?.point) {
                point = {
                    ...point,
                    "location": {
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            }
            delete point.scores;
            delete point.referral_code;
            console.log('newRequest', point)
            return point;
        case 'customer':
            let point2 = {
                ...newRequest,
            };
            if (!newRequest.location.point) {
                point2 = {
                    ...newRequest,
                    "location": {
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            } else {
                point2 = {
                    ...newRequest,
                    "location": {
                        ...newRequest.location,
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            }
            return point2;
        case 'transportation':
            let point3 = {
                ...newRequest,
            };
            if (!newRequest.location.point) {
                point3 = {
                    ...newRequest,
                    "location": {
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            } else {
                point3 = {
                    ...newRequest,
                    "location": {
                        ...newRequest.location,
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            }
            return point3;
        case 'fake_transportation':
            let point4 = {
                ...newRequest,
            };
            if (!newRequest.location.point) {
                point4 = {
                    ...newRequest,
                    "location": {
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            } else {
                point4 = {
                    ...newRequest,
                    "location": {
                        ...newRequest.location,
                        city_id: newRequest.location?.city_id ? newRequest.location?.city_id : newRequest.location?.city?.id,
                    },
                }
            }
            return point4;
        default:
            return;

    }


};
export const editDetail = (data, id, roll) => {
    return (dispatch, store) => {
        return fetcher('panel/user/edit/' + id + '/' + roll + '/',
            {
                method: 'PUT',
                body: JSON.stringify(sanitizeChangeProfile(store()?.[data], roll)),
            })
            .then((response) => {
                message.success('تغییرات با موفقیت اعمال شد')
                return response;
            }).catch(r => {
                    message.error(r?.message)
                    return;
                }
            )
    }
};
export const editDetailFake = (data, id, roll) => {

    return (dispatch, store) => {
        console.log(store()?.[data])
        return fetcher('panel/user/' + roll + '/' + id + '/',
            {
                method: 'PUT',
                body: JSON.stringify(sanitizeChangeProfile(store()?.[data], roll)),
            })
            .then((response) => {
                message.success('تغییرات با موفقیت اعمال شد');
                return response;
            }).catch(r => {
                    message.error(r?.message);
                    return;
                }
            )
    }
};
export const deleteUser = (id, roll) => {
    return () => {
        return fetcher('panel/user/edit/' + id + '/' + roll + '/',
            {
                method: 'DELETE',
            })
            .then((response) => {
                // message.success('حذف شرکت حمل‌ونقل با موفقیت اعمال شد')
                return response;
            }).catch(r => {
                    // message.error(r?.message)
                    throw new Error('error')
                }
            )
    }
};

export const deleteUserFake = (id, roll) => {
    return () => {
        return fetcher('panel/user/' + roll + '/' + id + '/',
            {
                method: 'DELETE',
            })
            .then((response) => {
                // message.success('حذف شرکت حمل‌ونقل با موفقیت اعمال شد')
                return response;
            }).catch(r => {
                    // message.error(r?.message)
                    throw new Error('error')
                }
            )
    }
};
export const authDetail = (data, id, roll) => {
    return (dispatch, store) => {
        return fetcher('panel/user/edit/' + id + '/' + roll + '/',
            {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            .then((response) => {
                return response;
            }).catch(r => {
                    message.error(r?.message)
                    return;
                }
            )
    }
};
export const getVehiclesList = () => {
    return (dispatch) => {
        return fetcher('/driver/vehicle/',
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
}
export const getCargoesDriver = (page = '', type = '', owner = "", state = '') => {
    return (dispatch) => {
        return fetcher('/panel/cargo/?request_role=driver&' + 'page=' + page + '&request_status=' + type + "&request_owner=" + owner + '&state=' + state,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;

            })
    }
};
export const getCargoesCustomer = (page = 1, type = '', owner = "", state = '') => {
    return (dispatch) => {
        return fetcher('/panel/cargo/?role=customer&' + 'page=' + page + '&request_status=' + type + "&owner=" + owner+ '&state=' + state,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;

            })
    }
};
export const getCargoesTransporterSend = (state = '2,0'/* 1 => now*/, page = 1, owner = "") => {
    return (dispatch) => {
        return fetcher('/panel/cargo/?role=transportation&state=' + state + '&page=' + page + "&owner=" + owner)
            .then((response) => {
                return response
            }).catch((response) => {
                if (response) {
                    /* message.error(response)*/
                }

            })
    }
};
export const getCargoesTransporterRecived = (status = '0'/* 1=>prev*/, page = 1, owner = "") => {
    return (dispatch) => {
        return fetcher('/panel/cargo/?request_role=transportation&request_status=' + status + '&page=' + page + "&request_owner=" + owner)
            .then((response) => {
                return response

            }).catch((response) => {
                /*   message.error(response)*/
            })
    }
};
export const nameSearch = (page, data) => {
    return () => {
        return fetcher('transportation/company_names/?page=' + page + '&search=' + data,
            {
                method: 'GET',
            })
            .then(r => r)
    }
};

export const numberUsers = (roll) => {
    return () => {
        return fetcher('panel/user/dashboard/?role=' + roll,
            {
                method: 'GET',
            })
            .then(r => r)
            .catch(r => {
            })
    }
};