import React, {Component} from 'react';

class Index extends Component {
    render() {
        return (


            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <p className="number-404">404</p>
                <p className="caption-404">صفحه موردنظر پیدا نشد</p>

            </div>
        );
    }
}

export default Index;
