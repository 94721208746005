import {fetcher} from "redux/common/action";
import {UrlQuery} from "../../utils/utils";
import {} from 'redux/dashboard/type';
import {message} from "antd";

export const getCargoes = (data) => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo/report/',data),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getCargoCount = () => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo/dashboard'),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getProvince = () => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo/province/dashboard'),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getCities = (id) => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo/'+id+'/city/dashboard'),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};