import React, {Component, useEffect, useState} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from 'react-icons/io';
import {FaBookReader, FaMapMarkerAlt} from 'react-icons/fa';
import {connect} from "react-redux";
import {areaSearch} from "redux/dashboard/action";
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import {FiSearch} from "react-icons/fi";
import {RiCloseLine} from "react-icons/ri";


const ProvinceSelector = (props) => {
    const [toggle, setToggle] = useState(false);
    const [selectedOption, setSelectedOption] = useState({
        city: {id: "", name: ""},
        id: "",
        name: "",
        province: {id: "", name: ""},

    });
    const [province, setProvince] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState('');
    
    const handleOptionChange = (changeEvent) => {
        setSelectedOption(JSON.parse(changeEvent.target.value))
        props.getNewProvince(JSON.parse(changeEvent.target.value)?.id)
        setToggle(false);
    }
    useEffect(() => {
        searchCity()
    }, []);
    const searchCity = (p = 1, d = data) => {
        props.areaSearch(d, p)
            .then(r => {
                if (r?.result) {
                    setProvince((p == 1) ? r?.result : province.concat(r?.result))
                    setLoading(false);
                    setHasMore(r?.result?.length > 29)
                    setCount(r?.count)
                }
            })
    };
    const handleInfiniteOnLoad = () => {
        setLoading(true)
        if (!hasMore) {
            setLoading(false)
            return;
        } else {

            searchCity(((Math.floor(province?.length / 30)) + 1), data)
        }

    };

    return (
        <>
            <button onClick={() => setToggle(true)} className="box-detail  d-block w-100  p-0">
                {/*{console.log(province)}*/}
                <p className="d-flex align-items-center justify-content-between py-1 px-2"
                   style={{lineHeight: '2.2rem'}}>
                                <span>
                                <span className="text-MainColor"><FaBookReader/></span>
                                <span className="mr-2">
                                    {(props.titleListProvince || 'شهر') + ' : ' + (selectedOption?.province?.name || '') + (selectedOption?.name ? '،' : '') + (selectedOption?.name || '')}</span>
                                </span>
                    <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                </p>
            </button>

            <Modal centered={true} isOpen={toggle} toggle={() => setToggle(false)} className="modal-login">
                <ModalBody className="p-0 border-radius-5 pb-4">
                    <div
                        className="d-flex align-items-center justify-content-between headColorMain modal-head-style ">

                        <span className="text-white pr-2 font-size-12"><FiSearch/></span>
                        <input
                            className="input-modal-head pr-2 py-3"
                            placeholder="شهر مورد نظر خود را جستجو کنید"
                            style={{backgroundColor: 'transparent'}}
                            onChange={e => {
                                setData(e.target.value)
                                searchCity(1, e.target.value)
                            }}/>
                        <button onClick={() => setToggle(false)} className="text-white font-size-12">
                            <RiCloseLine className="m-1"/>
                        </button>

                    </div>
                    <Spin size="large" spinning={props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="box-height-modal text-right mt-3 px-3 mb-2" style={{height: '80vh'}}>

                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                hasMore={!loading && hasMore}
                                useWindow={false}
                            >
                                <div className="form-check-inline-style">
                                    {
                                        province.map((i, index) => <div
                                            key={index}
                                            className="pr-3 mb-2">
                                            <button
                                                className="text-color"
                                                onClick={() => {
                                                    setSelectedOption(i?.normal_location)
                                                    props.getNewProvince(i?.normal_location?.id)
                                                    setToggle(false);
                                                    // this.props.resultOldArea(i)
                                                }}>
                                                <span className="ml-2 text-gray"><FaMapMarkerAlt/></span>
                                                {`${i?.normal_location?.province?.name} ، ${i?.normal_location?.name}`}
                                            </button>
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>
                        </div>
                    </Spin>


                </ModalBody>
            </Modal>
        </>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        areaSearch: (...data) => dispatch(areaSearch(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoDetailDriver: store.infoDetailDriver,
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ProvinceSelector);
