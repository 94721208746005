import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {MdCheckCircle} from "react-icons/md";
import {changeDetailDriver, getVehiclesList, editDetail, getDriver} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import PlaceSelector from "components/users/PlaceSelector";
import EducateSelectorFilter from "components/users/EducareSelectorFilter";
import DatePicker from 'react-datepicker2';
import moment from 'moment-jalaali';
import CarSelector from "components/users/CarSelector";
import {NavLink as NavLinkRouter,Redirect} from "react-router-dom";
import {message} from "antd";

class EditDriver extends Component {
    state = {
        profile: '',
        national: '',
        smartcode: '',
        modal: false,
        input: null,
        data: moment(),
        insurance_third_party_deadline: '',
        selectedOptionApprove: false,
        vehiclesList: [],
        back:false
    };


    componentDidMount() {
        this.props.getVehiclesList().then(r => {
            if (r?.result) {
                this.setState({
                    vehiclesList: r?.result,
                })
            }
        });
        this.setState({
            date: moment(this.props.infoDetailDriver?.birth_date, 'jYYYY-jMM-jDD').isValid() ? moment(this.props.infoDetailDriver?.birth_date, 'jYYYY-jMM-jDD')
                : moment(),
            insurance_third_party_deadline: moment(this.props.infoDetailDriver?.vehicles_info[0]?.insurance_third_party_deadline, 'jYYYY-jMM-jDD').isValid() ?
              moment(this.props.infoDetailDriver?.vehicles_info[0]?.insurance_third_party_deadline, 'jYYYY-jMM-jDD') : moment() ,
            insurance_body_deadline: moment(this.props.infoDetailDriver?.vehicles_info[0]?.insurance_body_deadline, 'jYYYY-jMM-jDD').isValid() ?
                moment(this.props.infoDetailDriver?.vehicles_info[0]?.insurance_body_deadline, 'jYYYY-jMM-jDD') : moment()
            ,
        });
        if (this.props.infoDetailDriver?.media) {
            let profile = this.props.infoDetailDriver?.media.filter(t => t?.type === "profile");
            let national = this.props.infoDetailDriver?.media.filter(t => t?.type === "national");
            let smartcode = this.props.infoDetailDriver?.media.filter(t => t?.type === "smartcode");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url,
                    selectedOptionApprove: this.props.infoDetailDriver?.is_approve
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }
            if (smartcode.length !== 0) {
                this.setState({
                    smartcode: smartcode[0]?.exact_url
                })
            }

        }
    }

    setDataCar = (e, n) => {
        this.props.changeDetailDriver(
            {
                vehicles_info: [{
                    ...this.props.infoDetailDriver?.vehicles_info[0],
                    [n]: e
                }]
            })
    }

    render() {
        if(this.state.back){
            return <Redirect to="/drivers" />
        }
        const {infoDetailDriver} = this.props;
        return (
            <>
                <Main>

                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row text-right">
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره تلفن همراه :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.owner?.mobile_number}
                                       disabled={true}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">تاریخ تولد :</label>
                                <DatePicker
                                    className="input-style-public-border pr-2 mt-2"

                                    value={this.state.date}
                                    timePicker={false}
                                    isGregorian={false}
                                    onChange={(value) => {
                                        if (value)
                                            this.props.changeDetailDriver({
                                                "birth_date": value.format('YYYY-MM-DD')
                                            })
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.first_name}
                                       onChange={(e) => {
                                           this.props.changeDetailDriver({first_name: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام خانوادگی :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.last_name}
                                       onChange={(e) => {
                                           this.props.changeDetailDriver({last_name: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6  mt-4">
                                <label className="mb-2"> تایید احراز هویت :</label>
                                <div className="box-detail  d-block w-100 p-0">
                                    <p className="d-flex align-items-center justify-content-between py-3 p-2"
                                       style={{lineHeight: '2.2rem'}}>
                                <span>
                                                  <span className="mr-3">
                                                    {

                                                        this.props.infoDetailDriver?.is_approve ?
                                                            <span>کاربر تایید شد</span>
                                                            :
                                                            <span>کاربر تایید نشده</span>
                                                    }
                                                </span>
                                    <span
                                        className={` ${infoDetailDriver?.is_approve ? 'text-green' : ' text-gray'} border-0`}><MdCheckCircle/></span>

                                       </span>

                                        <label className="switch">
                                            <input type="checkbox"
                                                   defaultChecked={infoDetailDriver?.is_approve}
                                                   onChange={(e) => {
                                                       this.props.changeDetailDriver({is_approve: e.target.checked})
                                                   }}
                                            />
                                            <span className="slider"/>
                                        </label>

                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شهر محل زندگی :</label>
                                <PlaceSelector title={'محل زندگی'} prefix={"شهر:"}
                                               property='changeDetailDriver'
                                               result='infoDetailDriver'
                                               type={"location"}/>
                            </div>

                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">مدرک تحصیلی :</label>
                                <EducateSelectorFilter title={'مدرک تحصیلی '}
                                                       label='&option='
                                                       subject=" تحصیلات"
                                                       multiple={false}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نوع وسیله نقلیه :</label>
                                <CarSelector name="نوع وسیله نقلیه"
                                             vehiclesList={this.state.vehiclesList}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره هوشمند ناوگان :</label>
                                <input type="number" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.vehicles_info[0]?.smart_car_code}
                                       onChange={(e) => {
                                           this.setDataCar(e.target.value, 'smart_car_code');
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره پلاک ناوگان :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.vehicles_info[0]?.plate_car}
                                       onChange={(e) => {
                                           this.setDataCar(e.target.value, 'plate_car');
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام برند :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.vehicles_info[0]?.brand}
                                       onChange={(e) => {
                                           this.setDataCar(e.target.value, 'brand');
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نوع سیستم ناوگان :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.vehicles_info[0]?.type}
                                       onChange={(e) => {
                                           this.setDataCar(e.target.value, 'type');
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">سال ساخت ناوگان :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.vehicles_info[0]?.create_year}
                                       onChange={(e) => {
                                           this.setDataCar(e.target.value, 'create_year');
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">سررسید بیمه ثالث :</label>
                                <DatePicker
                                    className="input-style-public-border pr-2 mt-2"
                                    value={this.state.insurance_third_party_deadline}
                                    timePicker={false}
                                    isGregorian={false}
                                    onChange={(value) => {
                                        this.setDataCar(value.format('jYYYY-jMM-jDD'), 'insurance_third_party_deadline')
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">سررسید بیمه بدنه :</label>
                                <DatePicker
                                    className="input-style-public-border pr-2 mt-2"
                                    value={this.state.insurance_body_deadline}
                                    timePicker={false}
                                    isGregorian={false}
                                    onChange={(value) => {
                                        this.setDataCar(value.format('jYYYY-jMM-jDD'), 'insurance_body_deadline')
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">کدملی :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.national_code}
                                       onChange={(e) => {
                                           this.props.changeDetailDriver({national_code: e.target.value})
                                       }}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره هوشمند راننده :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailDriver?.smart_driver_code}
                                       onChange={(e) => {
                                           this.props.changeDetailDriver({smart_driver_code: e.target.value})
                                       }}/>
                            </div>


                        </div>
                        <div className="row justify-content-center">
                            <div className="col-4 text-center my-4">
                                <button className="submit-btn-green "
                                        disabled={this.props.infoLoading}
                                        onClick={() => {
                                            this.props.editDetail('infoDetailDriver', this.props.infoDetailDriver?.owner?.id, 'driver')
                                                .then(r => {
                                                    /*this.props.cancel();*/
                                                    this.props.getDriver().then(r => {
                                                        if (r?.result) {
                                                            let item = r?.result.filter(t => t?.owner?.id == this.props.id);
                                                            if (item?.length !== 0) {
                                                                this.props.changeDetailDriver(item[0]);
                                                            }
                                                            this.setState({back:true})
                                                        }
                                                    })


                                                })
                                        }}>
                                    {this.props.infoLoading ? "درحال اتصال" : 'ذخیره تغییرات'}
                                </button>
                            </div>
                        </div>
                    </Spin>

                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDriver: (...data) => dispatch(getDriver(...data)),
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        editDetail: (...data) => dispatch(editDetail(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailDriver: store.infoDetailDriver,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDriver);
