import React, {Component} from 'react'
import {NavLink as NavLinkRouter, Redirect} from 'react-router-dom';
import Main from 'components/publicComponents/main'
import {scoreDetail, editScoreDetail} from "redux/score/action";
import {connect} from "react-redux";
import {Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";
import Visitor from "components/magazine/visitor";
import {resultٰVisitors} from "../../redux/magazine/action";


class EditScore extends React.Component {
    state = {
        item: {},
        back: false
    };

    componentDidMount() {
        this.props.scoreDetail(this.props.match.params?.slug)
            .then(r => {
                if (r?.result) {
                    this.setState({
                        item: r?.result
                    })
                    this.props.resultٰVisitors(r?.result?.targets)
                }
            })
    };

    changeItem = (e) => {
        this.setState({
            item: {
                ...this.state.item,
                [e.target.name]: e.target.value
            }
        })
    };
    send = () => {
        this.props.editScoreDetail(this.props.match.params?.slug, {
            ...this.state.item,
            "targets": this.props.infoVisitor
        })
            .then(r => {
                if (r?.result) {
                    this.setState({
                        back: true
                    })
                }
            })
    };

    render() {
        if (this.state.back) {
            return <Redirect to="/score"/>
        }
        const {item} = this.state;
        return (
            <div>
                <Main>
                    <Spin
                        size="large"
                        spinning={this.props.infoLoading}
                        indicator={<LoadingIcon/>}
                    >
                        <div className="main-page-inside mt-4 ">
                            <p className="main-title text-right">ویرایش امتیازات</p>
                            <div className="row mt-5 text-right">
                                <div className="col-12 col-lg-4">
                                    <p className="mb-1">شاخه اصلی</p>
                                    <input type="text"
                                           className="input-style-public-border pr-2"
                                           onChange={this.changeItem}
                                           name="title"
                                           defaultValue={item?.title}/>
                                </div>
                                <div className="col-12 col-lg-4">

                                    <p className="mb-1">تعداد سکه</p>
                                    <input type="text"
                                           name="score"
                                           onChange={this.changeItem}
                                           className="input-style-public-border pr-2"
                                           defaultValue={item?.score}/>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <p className="mb-1">سقف ماهیانه</p>
                                    <input type="text"
                                           name="ceil"
                                           onChange={this.changeItem}
                                           className="input-style-public-border pr-2"
                                           defaultValue={item?.ceil}/>
                                </div>
                            </div>
                            <div className="row mt-4 mr-0">
                                <p className="mb-1">توضیحات</p>
                                <textarea className="input-style-public-border pr-2"
                                          rows="3"
                                          name="description"
                                          onChange={this.changeItem}
                                          defaultValue={item?.description}/>
                            </div>
                            <Visitor text="ارائه برای : "/>
                            <div className="row mt-4 justify-content-end">
                                <div className="col-6 col-md-4 col-lg-3">
                                    <NavLinkRouter to="/score"
                                                   className="submit-btn-border px-4 py-2">
                                        انصراف
                                    </NavLinkRouter></div>
                                <div className="col-6 col-md-4 col-lg-3">
                                    <button
                                        onClick={this.send}
                                        className="submit-btn p-2 mr-3 ml-2"
                                    >ثبت ویرایش
                                    </button>
                                </div>

                            </div>
                        </div>
                    </Spin>
                </Main>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        scoreDetail: (...data) => dispatch(scoreDetail(...data)),
        editScoreDetail: (...data) => dispatch(editScoreDetail(...data)),
        resultٰVisitors: (...data) => dispatch(resultٰVisitors(...data)),
    };
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoVisitor: store.infoVisitor
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditScore);
