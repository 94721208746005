import React, {Component} from 'react';
import {AiFillCaretLeft} from "react-icons/ai";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {Modal} from 'reactstrap';
import {Tabs} from 'antd';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import {connect} from 'react-redux'
import {changeDetailDriver, resultOldArea, changeDetailCustomer, changeDetailTransporter,deleteOldArea} from 'redux/dashboard/action';
import {areaSearch} from 'redux/dashboard/action';
import {FaChevronLeft, FaMapMarkerAlt} from "react-icons/fa";
import {RiCloseLine} from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {FaMapMarker} from 'react-icons/fa';
import {IoIosArrowDropleft,IoIosCloseCircle} from 'react-icons/io';
import classnames from 'classnames';
import {setRequestTransport} from "redux/account/action";

class PlaceSelector extends Component {

    state = {
        toggle: false,
        selectedObject: {},
        areas: [],
        input: '',
        activeTab: '1',
        firstOpen: false
    };


    componentDidMount() {
        if (this.props.infoOldArea?.length < 1) {
            this.searchCity('');
        }
    }

    searchCity(data = '') {
        this.props.areaSearch(data)
            .then(r => {
                if (r?.result) {
                    this.setState({
                        areas: r?.result,
                        firstOpen: true
                    })
                }
            })
    };

    toggle = () => {
        this.setState({toggle: !this.state.toggle, firstOpen: false})
    };

    render() {
        return (
            <>
                <div className="">
                    <button onClick={this.toggle} className="border border-radius-5 box-detail py-3 p-2 d-block w-100 ">

                        <p className="d-flex align-items-center justify-content-between "
                           style={{lineHeight: '2.2rem'}}>
                                <span>
                                      <span className="text-MainColor"><FaMapMarker/></span>
                                <span className="mr-2">
                                    {(this.props?.[this.props.result]?.title) ? this.props.prefix : ""}
                                    {(this.props?.[this.props.result]?.[this.props.type]?.city?.name) ?
                                        this.props.prefix + " " + this.props?.[this.props.result]?.[this.props.type]?.city?.name
                                        : this.props.title}
                                </span>
                                </span>
                            <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                        </p>
                    </button>
                    <Modal centered={true} isOpen={this.state.toggle} toggle={this.toggle}>
                        <div
                            className="d-flex align-items-center justify-content-between headColorMain modal-head-style ">

                            <span className="text-white pr-2 font-size-12"><FiSearch/></span>
                            <input
                                className="input-modal-head pr-2 py-3"
                                ref={r => this.input = r}
                                placeholder="شهر مورد نظر خود را جستجو کنید"
                                style={{backgroundColor: 'transparent'}}
                                onChange={e => this.searchCity(e.target.value)}/>
                            <button onClick={this.toggle} className="text-white font-size-12">
                                <RiCloseLine className="m-1"/>
                            </button>
                        </div>
                        <div className="text-right mt-3 px-3 mb-2">
                            {(this.state.firstOpen || (this.props.infoOldArea?.length < 1)) ?


                                            this.state.areas.map((i, index) => <div
                                                key={index}
                                                className="pr-3 mb-2">
                                                <button
                                                    className="text-color"
                                                    onClick={() => {
                                                        this.setState({toggle: false});
                                                        this.props[this.props.property]({
                                                            [this.props.type]: {
                                                                ...i?.normal_location,
                                                                city: {...i?.normal_location}
                                                            }
                                                        });
                                                        this.props.resultOldArea(i)
                                                    }}>
                                                    <span className="ml-2 text-gray"><FaMapMarkerAlt/></span>
                                                    {`${i?.normal_location?.province?.name} ، ${i?.normal_location?.name}`}
                                                </button>
                                            </div>)
                                        :
                                        this.props.infoOldArea.map((i, index) => <div
                                            key={index}
                                            className="d-flex align-items-center justify-content-between mb-2">
                                            <button
                                                className="text-color"
                                                onClick={() => {
                                                    this.setState({toggle: false});
                                                    this.props[this.props.property]({
                                                        [this.props.type]: {
                                                            ...i?.normal_location,
                                                            city: {...i?.normal_location}
                                                        }
                                                    });


                                                    this.props[this.props.property]({
                                                        [this.props.type]: {
                                                            ...i?.normal_location,
                                                            city: {...i?.normal_location}
                                                        }
                                                    })
                                                }}>
                                                <span className="ml-2 text-gray"><FaMapMarkerAlt/></span>
                                                {`${i?.normal_location?.province?.name} ، ${i?.normal_location?.name}`}
                                            </button>
                                            <button className="text-MainColor font-size-13"
                                                    onClick={() => {
                                                        this.props.deleteOldArea(i?.normal_location?.id);
                                                        if (this.props.infoOldArea?.length <= 1) {
                                                            this.searchCity('');
                                                        }
                                                    }}><IoIosCloseCircle/></button>
                                        </div>)}
                        </div>
                    </Modal>
                </div>
            </>
        );
    }
}


const mapStateToProps = (store) => {
    return {
        infoDetailDriver: store.infoDetailDriver,
        infoDetailCustomer: store.infoDetailCustomer,
        infoOldArea: store.infoOldArea,
        infoDetailTransporter: store.infoDetailTransporter,
        infoNewTransport: store.infoNewTransport,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        areaSearch: (data) => dispatch(areaSearch(data)),
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
        changeDetailCustomer: (...data) => dispatch(changeDetailCustomer(...data)),
        changeDetailTransporter: (...data) => dispatch(changeDetailTransporter(...data)),
        resultOldArea: (...data) => dispatch(resultOldArea(...data)),
        setRequestTransport: (...data) => dispatch(setRequestTransport(...data)),
        deleteOldArea: (...data) => dispatch(deleteOldArea(...data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlaceSelector);
