import React, {Component} from 'react';
import {changeDetailDriver, getVehiclesList} from "redux/dashboard/action";
import {connect} from "react-redux";
import {Modal, ModalBody} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from 'react-icons/io';
import {FaTruckMoving} from 'react-icons/fa';

class CarSelector extends Component {
    state = {
        toggle: false,
        toggle2: false,
        options: [],
        selected: null,
        selectedOption: null
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    };

    render() {

        return (
            <>

                <button onClick={this.toggle} className="box-detail  d-block w-100 p-0">
                    <p className="d-flex align-items-center justify-content-between py-1 px-2"
                       style={{lineHeight: '2.2rem'}}>
                                <span>
                                <span className="text-MainColor"><FaTruckMoving/></span>
                                    {/*<span*/}
                                    {/*    className="mr-2">{this.props.infoDetailDriver?.vehicles_info[0]?.vehicle?.car?.title + ' : ' +*/}
                                    {/*this.props.infoDetailDriver?.vehicles_info[0]?.vehicle?.option?.title*/}
                                    {/*}</span>*/}
                                    <span
                                        className="mr-2">
                                          {this.state.selected ? this.state.selected?.car?.title : "وسیله نقلیه"}
                                          {this.state.selectedOption ? (" ،"+this.state.selectedOption?.title) : ""}
                                      </span>

                                </span>
                        <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                    </p>
                </button>

                <Modal centered={true} isOpen={this.state.toggle} toggle={this.toggle} className="modal-login"
                       size="lg">
                    <ModalBody className="p-0 border-radius-5 pb-4">
                        <div
                            className="py-3 headColor d-flex align-items-center justify-content-between mb-3 px-2">
                            <p className="text-white text-center  pr-2">
                                انتخاب نوع ناوگان
                            </p>
                            <button onClick={this.toggle} className="text-white font-size-15">
                                <IoIosClose/>
                            </button>
                        </div>
                        <div className="p-3">
                            <div className="form-check-inline-style">
                                <div className="row justify-content-center">
                                    {this.props.vehiclesList.map((v, i) => <div className="col-6 mt-2">
                                        <button className="button-car w-100 py-4" key={i}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: v,
                                                        options: this.props.vehiclesList.filter((t, i) => t?.car?.id == v?.car?.id)[0]?.option
                                                    });
                                                    this.props.getNewCar({car:v?.car?.id})
                                                    this.toggle();
                                                }}
                                                value={v?.car?.id}>
                                            {v?.car?.title}
                                        </button>
                                    </div>)}
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailDriver: store.infoDetailDriver,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CarSelector);

