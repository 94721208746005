import React, {Component} from 'react';
import {NavLink as NavLinkRouter} from "react-router-dom";
import {FiChevronLeft} from "react-icons/fi";

import {pageSave} from 'redux/pages/action';
import {connect} from "react-redux";


class Button extends Component {


    render() {


        return (
            <>

                {this.props?.listLinks ?
                    <NavLinkRouter className={`menu-item ${this.props.className}`}
                                   to={this.props.to} exact
                                   onClick={() => {
                                       this.props.pageSave(this.props.link, 'menu')
                                   }}
                        // isActive={(a, b) => this.props?.listLinks.includes(b.pathname.replace(/\/+|\\+/g, ''))}
                                   isActive={(a, b) => {
                                       let check = false;
                                       this.props?.listLinks.map(t => b.pathname.includes(t) && (check =true) )
                                      return check;
                                   }}
                                   activeClassName="active-item">
                        <span>{this.props.name}</span>
                        {
                            this.props.iconLeft ?
                                <span><FiChevronLeft/></span>
                                : ''
                        }

                    </NavLinkRouter>
                    :
                    <NavLinkRouter className={`menu-item ${this.props.className}`}
                                   to={this.props.to} exact
                                   onClick={() => {
                                       this.props.pageSave(this.props.link, 'menu')
                                   }}
                                   activeClassName="active-item">
                        <span>{this.props.name}</span>
                        {
                            this.props.iconLeft ?
                                <span><FiChevronLeft/></span>
                                : ''
                        }

                    </NavLinkRouter>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pageSave: (...data) => dispatch(pageSave(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoSavePage: store.infoSavePage,


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Button);

