import React, {Component} from 'react';
import MenuTop from "components/publicComponents/menuTop";
import MenuRight from "components/publicComponents/menuRight";

class Main extends Component {
    render() {

        return (
            <>
                <div className="main-page">
                    <div className="container-fluid h-100">
                        <div className="row h-100">
                            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-2 h-100 p-0">
                              <MenuRight/>
                            </div>
                            <div className="col-7 col-sm-8 col-md-8 col-lg-9 col-xl-10 h-100 p-0">
                              <MenuTop/>
                                <div className="contents">
                                    <div className="contents-inside px-3 py-2">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
