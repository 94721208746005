import React, {useRef, useState} from 'react';
import {MdLocationOn} from "react-icons/md";
import {Marker, Popup, Tooltip} from "react-leaflet";
import PopupTrack from "./popupTrack";
import iconCar from "./icon";
import L from "leaflet";

const Red_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(iconCar("red"))}`;
const redIcon = L.icon({
    iconUrl: Red_MARKER,
    iconSize: [50, 50],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
});
const Orange_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(iconCar("orange"))}`;
const orangeIcon = L.icon({
    iconUrl: Orange_MARKER,
    iconSize: [50, 50],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
});

const Locate = ({item,id}) => {
    const [value, setValue] = useState({});
    const [currentlyMousedOverMarker, setCurrentlyMousedOverMarker] = useState(false);
    const [mousedOverMarker, setMousedOverMarker] = useState(false);
    const tool = useRef(false)
    const getData = () => {
        if ( !mousedOverMarker){
            setMousedOverMarker(true)
            fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${item.location.latitude}&lon=${item.location.longitude}`).then(r => r.json()).then(r => {
                setValue(r)

            }).catch(e => {

            })
        }

    }
    return (
        <>
            <Marker
                position={[item.location.latitude, item.location.longitude]}
                eventHandlers={{
                    // click: (e) => {
                    //     console.log(e.target.options.data);  // will print 'FooBar' in console
                    // },
                    mouseover: () => {
                        setCurrentlyMousedOverMarker(true)
                        getData()
                    },
                    mouseout: () => {
                        setCurrentlyMousedOverMarker(false)
                    }
                }}
                icon={item?.eta_passed ? redIcon : orangeIcon}>
                <Popup>
                    <PopupTrack item={item} id={id}/>
                </Popup>
                {currentlyMousedOverMarker && <Tooltip direction="right"  permanent={currentlyMousedOverMarker}>
                    <span className="ml-2 font-size-15 text-MainColor"><MdLocationOn/></span>
                    {(mousedOverMarker && value?.address) ? `${value?.address?.city} , ${value?.address?.district}, ${value?.address?.neighbourhood ? value?.address?.neighbourhood : (value?.address?.road || '') }` : 'درحال دریافت ...'}
                </Tooltip>}
            </Marker>

        </>
    );
};

export default Locate;
