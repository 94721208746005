import {RESULT_SERVICE_CATEGORY} from 'redux/service/type';
import {RESET} from "redux/common/type";
export const infoServiceCategory = (state = {}, action) => {
    switch (action.type) {
        case RESET:
            return {};
        case RESULT_SERVICE_CATEGORY :
            return action.data;
        default:
            return state;

    }
};
