import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    getCargoes
} from "redux/report/action";
import {FaCheckCircle} from "react-icons/fa";
import {Pagination} from "antd";
import NotFoundFile from "../publicComponents/notFoundFile";

class Car extends Component {

    state = {
        list:[],
        listCount:0,
        page:1
    };
    componentDidMount() {
        this.getListCargoes()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateComponent !== this.props.updateComponent) {
            this.getListCargoes()
        }
    }
    getListCargoes = (p=1) => {
        let cargoParams={}
        cargoParams={
            report_type:"car",
            ...this.props.params,
            page:p
        }
        this.props.getCargoes(cargoParams).then(r => {
            if (r?.result) {
                this.setState({
                    list: r?.result,
                    listCount: r?.count,
                })
            }

        })
    };
    render() {
        const {vehiclesList=[]} = this.props;
        const {list,listCount} = this.state;
        return (
            <>



                    <div className="table-main">
                        <table className="table mt-4 ">
                            <thead>
                            <tr>
                                <th>شماره</th>
                                <th>نوع وسیله نقلیه</th>
                                <th>تعداد اعلام بار</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vehiclesList.length ?
                                list.map((item , index) => <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{vehiclesList.filter(t=>t?.car?.id === item?.car__id)[0]?.car?.title}</td>
                                    <td>{item?.cargo_count}</td>
                                </tr>):""
                            }


                            </tbody>
                        </table>


                    </div>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCargoes: (...data) => dispatch(getCargoes(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Car);
