import React, {Component} from 'react';
import {AiFillCaretLeft} from "react-icons/ai";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {Modal} from 'reactstrap';
import {Tabs} from 'antd';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import {connect} from 'react-redux'
import {changeDetailTransporter, nameSearch} from 'redux/dashboard/action';
import {FaChevronLeft, FaMapMarkerAlt} from "react-icons/fa";
import {RiCloseLine} from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {FaWarehouse} from 'react-icons/fa';
import {IoIosArrowDropleft, IoIosCloseCircle} from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
class NameSelector extends Component {

    state = {
        toggle: false,
        selectedObject: {},
        names: [],
        input: '',
        activeTab: '1',
        firstOpen: false,
        loading: false,
        hasMore: true,
        data:''
    };

    componentDidMount() {
            this.nameSearch(1, '');
    }

    nameSearch(page = 1, data) {
        this.setState({data});
        this.props.nameSearch(page, data)
            .then(r => {
                if (r?.result) {

                    this.setState({
                        firstOpen: true,
                        names: (page == 1) ? r?.result : this.state.names.concat(r?.result),
                        loading: false,
                        hasMore: r?.result?.length > 29
                    })
                }
            })
    };

    toggle = () => {
        this.setState({toggle: !this.state.toggle, firstOpen: false})
    };
    handleInfiniteOnLoad = () => {
        let {names} = this.state;
        this.setState({
            loading: true,
        });
        this.nameSearch(Math.floor(names?.length / 30) + 1, this.state.data)
    };


    render() {
        return (
            <>
                <div className=" mb-3">
                    <button onClick={this.toggle} className="box-detail py-3 p-2 d-block w-100 mt-2">

                        <p className="d-flex align-items-center justify-content-between "
                           style={{lineHeight: '2.2rem'}}>
                                <span>
                                     <span className="font-size-1 text-MainColor pl-2"><FaWarehouse/></span>
                                <span className="mr-2">
                                    {(this.props.infoDetailTransporter?.title) ? this.props.prefix : ""}
                                    {(this.props.infoDetailTransporter?.company_name) ? (this.props.infoDetailTransporter?.company_name) : ''}
                                </span>
                                </span>

                            <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>

                        </p>
                    </button>
                    <Modal centered={true} isOpen={this.state.toggle} toggle={this.toggle}>
                        <div className="d-flex align-items-center headColorMain modal-head-style ">

                            <span className="text-white pr-2 font-size-12"><FiSearch/></span>
                            <input
                                className="input-modal-head pr-2 py-3"
                                ref={r => this.input = r}
                                placeholder="نام شرکت حمل‌ونقل را جستجو کنید"
                                style={{backgroundColor: 'transparent'}}
                                onChange={e => this.nameSearch(1,e.target.value)}/>
                            <button onClick={this.toggle} className="text-white font-size-12">
                                <RiCloseLine className="m-4"/>
                            </button>

                        </div>
                        <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="box-height-modal text-right mt-3 px-3 mb-2">
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={this.handleInfiniteOnLoad}
                                hasMore={!this.state.loading && this.state.hasMore}
                                useWindow={false}
                            >
                            {
                                this.state.names.map((c, i) => <div className="py-2">
                                    <button
                                        onClick={() => {
                                            this.props.changeDetailTransporter({"company_name": c?.title});
                                            this.toggle();
                                        }}>
                                        <span className="font-size-1 text-MainColor pl-2"><FaWarehouse/></span>
                                        {

                                            c?.title ?(   c?.title) : 'نامعلوم'
                                        }
                                    </button>
                                </div>)
                            }
                            </InfiniteScroll>
                        </div>
                        </Spin>
                    </Modal>
                </div>
            </>
        );
    }
}


const mapStateToProps = (store) => {
    return {
        infoOldArea: store.infoOldArea,
        infoLoading: store.infoLoading,
        infoDetailTransporter: store.infoDetailTransporter,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        nameSearch: (...data) => dispatch(nameSearch(...data)),
        changeDetailTransporter: (...data) => dispatch(changeDetailTransporter(...data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NameSelector);
