import React, {Component} from 'react';
import active from "images/icon/magazine/active Groups.svg";
import group from "images/icon/magazine/Groups.svg";

import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaPlusCircle, FaTrashAlt} from "react-icons/fa";
import {MdModeEdit} from "react-icons/md";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {getCategory, removeCategory,resultCreateCategory} from "redux/magazine/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";

class Category extends Component {
    state = {
        dropdownOpen: false,
        categories: [],
        categoryCount: 0,
        loading: true
    }
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        this.getCategory();
    }

    getCategory = () => {
        this.props.getCategory().then(r => {
            if (r?.result) {
                this.setState({
                    categories: r?.result,
                    categoryCount: r?.count,
                })
            }
        })
    };

    render() {
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل دسته ها"
                                  number={this.state.categoryCount}>
                                <img src={group} alt=""/>
                            </Card>
                            <Card name="تعداد دسته های فعال"
                                  number={this.state.categoryCount}>
                                <img src={active} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">دسته بندی مجلات</p>
                                <NavLinkRouter to="/new-category/new" className="submit-btn w-auto px-4 py-2">
                                    <span className="pl-2"><FaPlusCircle/></span>
                                    ایجاد دسته جدید
                                </NavLinkRouter>

                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th>عنوان دسته</th>
                                        <th>وضعیت</th>

                                        <th>ویرایش</th>
                                        <th>حذف</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.categories.map((category, i) => <tr>
                                            <td>{i+1}</td>
                                            <td>{category.title}</td>
                                            <td>فعال</td>
                                            <td><NavLinkRouter to={'new-category/' + category.id}
                                                               onClick={()=> this.props.resultCreateCategory(category)}
                                                               className="check-td-green"><MdModeEdit/></NavLinkRouter>
                                            </td>
                                            <td>
                                                <button className="trash-td-pink"
                                                        onClick={() => {
                                                            this.props.removeCategory(category.id).then(r => this.getCategory())
                                                        }}><FaTrashAlt/></button>
                                            </td>
                                        </tr>
                                    )}

                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCategory: (...data) => dispatch(getCategory(...data)),
        removeCategory: (...data) => dispatch(removeCategory(...data)),
        resultCreateCategory: (...data) => dispatch(resultCreateCategory(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Category);
