import React, {Component} from 'react';
import banner from "images/icon/magazine/Banners.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {getBanner, removeBanner} from 'redux/magazine/action';
import {connect} from "react-redux";
import {Spin} from 'antd';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {fetcher} from "redux/common/action";
import Car from "components/report/Car";
import Destination from "components/report/Destination";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from "classnames";
import CustomerSurvey from "components/survey/customer";

const Cargoes = [
    {name: 'car', component: Car, label: '  صاحبان بار', id: 1},
    {name: 'origin_dest', component: Destination, label: '  رانندگان', id: 2},
]

class Banner extends Component {
    state = {
        activeTab: "1",
        list: [{}],
        listCount: 0,
        loading: true,
        dashboardBox: {
            current_month_surveys: 8,
            today_surveys: 0,
            total_surveys: 8,
        }
    }


    componentDidMount() {
        this.getBanner();
        console.log(this);

        fetcher(`/panel/surveys/dashboard/`,
            {
                method: 'GET',
                // body: JSON.stringify(sanitizeSendRequest(store().infoNewTransport)),
            })
            .then((response) => {
                this.setState(state => ({
                    dashboardBox: {
                        ...state.dashboardBox,
                        ...response.result
                    },
                }), () => {

                })
            }).catch(e => {
                // message.error("لطفا تمامی فیلدها رو پر کنید")
            }
        )
    }

    getBanner = () => {
        // fetcher('panel/area/province/?page=' + this.state.params.page + '&search=' + this.state.params.data,
        //     {
        //         method: 'GET',
        //     })
        //     .then((response) => {
        //         if (response.statusCode === 200) {
        //             console.log(response)
        //             this.setState({
        //                 // list: response.result
        //             })
        //         }
        //
        //     }).catch(e => {
        //
        //     }
        // )
    };

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    };

    render() {
        return (
            <>

                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل نظرسنجی ها"
                                  number={this.state.dashboardBox.today_surveys}>
                                <img src={banner} alt=""/>
                            </Card>
                            <Card name="تعداد نظرسنجی ماه جاری"
                                  number={this.state.dashboardBox.current_month_surveys}>
                                <img src={banner} alt=""/>
                            </Card>
                            <Card name=" نظرسنجی های امروز"
                                  number={this.state.dashboardBox.total_surveys}>
                                <img src={banner} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="">
                                <p className="main-title text-right">نظرسنجی کاربران</p>
                                {/*<NavLinkRouter to="/new-banner" className="submit-btn w-auto px-4 py-2">*/}
                                {/*    <span className="pl-2"><FaPlusCircle/></span>*/}
                                {/*    ایجاد بنر جدید*/}
                                {/*</NavLinkRouter>*/}

                            </div>
                            <Nav
                                className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                {
                                    Cargoes.map((item, i) => <NavItem className="flex-grow-1" key={item.id}>
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                            onClick={() => {
                                                this.toggle((i + 1).toString());
                                            }}
                                        >
                                            {item?.label}
                                        </NavLink>
                                    </NavItem>)
                                }

                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <CustomerSurvey user="customers" role="customer"/>
                                </TabPane>
                                <TabPane tabId="2">
                                    <CustomerSurvey user="drivers" role="driver"/>
                                </TabPane>
                            </TabContent>

                        </div>
                    </Spin>
                </Main>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBanner: (...data) => dispatch(getBanner(...data)),
        removeBanner: (...data) => dispatch(removeBanner(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
