import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import Visitor from "components/magazine/visitor";
import {sendTopicPush} from "redux/notification/action";
import {connect} from "react-redux";
import {message,Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";
const Show = [
    {title: 'راننده', id: 'driver'},
    {title: 'صاحب بار', id: 'customer'},
    {title: 'شرکت حمل‌ونقل ', id: 'transportation'},
    {title: 'سرویس دهنده', id: 'serviceprovider'}
];
class Index extends Component {
    state = {
        ready: false,
        next: false,
    };
    getData=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    };
    send=()=>{
        if(this.state.title && this.state.body ){
            this.props.sendTopicPush({
                "title":this.state.title,
                "body":this.state.body,
                "topic":this.props.infoVisitor
            }).then(r=>{
                if(r?.statusCode==200){
                    this.setState({
                        title:"",
                        body:''
                    })
                }
            })
        }else {
            message.error('اطلاعات را کامل وارد کنید')
        }

    };

    render() {
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                    <div className="main-page-inside mt-4 text-right">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="main-title text-right">ایجاد اعلان جدید</p>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">عنوان اعلان</p>
                            <input type="text"
                                   onChange={this.getData}
                                   value={this.state.title}
                                   name="title"
                                   className="input-style-public-border pr-2"
                            />
                        </div>
                     {/*   <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">لینک اعلان</p>
                            <input type="url" className="input-style-public-border pr-2"/>
                        </div>*/}

                        <div className="row mt-4 align-items-center">
                            <div className="col-12">
                                <div className="d-flex align-items-center ">
                                    <p className="text-banner">متن اعلان</p>
                                    <textarea type="text"
                                              name="body"
                                              value={this.state.body}
                                              onChange={this.getData}
                                              rows={5}
                                              className="input-style-public-border pr-2"/>
                                </div>
                            </div>


                        </div>
                        <Visitor/>
                        <div className="text-left mt-4">
                            <button
                                className={`${(this.state.title && this.state.body) ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}
                                disabled={!(this.state.title && this.state.body)}
                                onClick={this.send}> تایید نهایی
                            </button>
                        </div>
                    </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendTopicPush: (...data) => dispatch(sendTopicPush(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoVisitor: store.infoVisitor
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
