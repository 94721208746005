import React, {Component} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {getBanner, removeBanner} from 'redux/magazine/action';
import {pageSave} from 'redux/pages/action';
import {connect} from "react-redux";
import {FaSearch} from "react-icons/fa";
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import Item from "components/cargo/item";
import {IoIosArrowDown} from "react-icons/io";
class Record extends Component {
    state = {
        activeTab: (this.props.infoSavePage && (this.props.list?.length >= Number(this.props.infoSavePage))) ? this.props.infoSavePage : '1',
        dropdownOpen: false,
    };
    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.props.pageSave(tab, 'cargo');
            this.setState({
                activeTab: tab
            })

        }
    }

    render() {
        return (
            <>


                <div className={`${this.props.mainClass ? "": "main-page-inside"} mt-4`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="main-title text-right">سابقه</p>
                        <div className="d-flex align-items-center">
                            {
                                this.props.roll === 'transportation' ?
                                    <div className="border border-radius-5 ml-2 p-1">
                                        <button
                                            className={`${this.props.now ? 'btn-check-now' : 'btn-check-now-gray'} ml-1`}
                                            onClick={this.props.handleNow}>جاری
                                        </button>
                                        <button
                                            className={`${!this.props.now ? 'btn-check-now' : 'btn-check-now-gray'} `}
                                            onClick={this.props.handlePast}>حمل شده
                                        </button>
                                    </div>
                                    : ''
                            }

                            <div className="d-flex align-items-center ml-4">
                                <span className="ml-2">دسته بندی براساس</span>
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                    <DropdownToggle className="dropdown-btn bg border px-4">
                                        <span>همه مطالب</span>
                                        <span className="pr-2"><IoIosArrowDown/></span>
                                    </DropdownToggle>
                                    <DropdownMenu style={{right: 0}}>
                                        <button className="dropdown-btn-item">همه مطالب ماه جدید</button>
                                        <button className="dropdown-btn-item">همه مطالب امروز</button>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="position-relative">
                                <input type="text" className="input-style-public-border bg pr-2 border"
                                       placeholder="جستجو"/>
                                <span className="iconLogin text-player"><FaSearch/></span>
                            </div>
                        </div>

                    </div>
                    <div className="mt-4">
                        <div>
                            <Nav
                                className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                {
                                    this.props.list.map((item, i) => <NavItem className="flex-grow-1">
                                        <NavLink
                                            className={classnames({active: this.state.activeTab == (i + 1)})}
                                            onClick={() => {
                                                this.toggle((i + 1).toString());
                                            }}
                                        >
                                            {item?.name}
                                        </NavLink>
                                    </NavItem>)
                                }
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                {
                                    this.props.list.map((item, i) => <TabPane tabId={(i + 1).toString()}>
                                        <div className="table-main mt-4">
                                            <table className="table">
                                                <tbody>
                                                {
                                                    item?.cargo.map((elem, ind) => <Item getListCargoes={this.props.getListCargoes} item={elem} tag={item.tag}/>)
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPane>)
                                }
                            </TabContent>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBanner: (...data) => dispatch(getBanner(...data)),
        removeBanner: (...data) => dispatch(removeBanner(...data)),
        pageSave: (...data) => dispatch(pageSave(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoSavePage: store.infoSavePage?.cargo,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Record);
