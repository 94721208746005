import {
    DETAIL_USER,
    CHANGE_DETAIL_DRIVER,
    initialChangeDriver,
    OLD_AREA_SEARCH,
    CHANGE_DETAIL_CUSTOMER,
    initialChangeCustomer,
    CHANGE_DETAIL_TRANSPORTER,
    initialChangeTransporter,
    CHANGE_DETAIL_SERVICE_PROVIDER,
    DELETE_OLD_AREA_SEARCH
} from 'redux/dashboard/type';
import {RESET} from "redux/common/type";



export const infoDetailDriver = (state = initialChangeDriver, action) => {
    switch (action.type) {
        case RESET:
            return initialChangeDriver;
        case CHANGE_DETAIL_DRIVER:
            let out = {...initialChangeDriver, ...state, ...action.data}
            return out
        default:
            return state;
    }
}
export const infoDetailCustomer = (state = initialChangeCustomer, action) => {
    switch (action.type) {
        case RESET:
            return initialChangeCustomer;
        case CHANGE_DETAIL_CUSTOMER:
            let out = {...initialChangeCustomer, ...state, ...action.data}
            return out
        default:
            return state;
    }
};
export const infoDetailTransporter = (state = initialChangeTransporter, action) => {
    switch (action.type) {
        case RESET:
            return initialChangeTransporter;
        case CHANGE_DETAIL_TRANSPORTER:
            let out = {...initialChangeTransporter, ...state, ...action.data}
            return out
        default:
            return state;
    }
};
export const infoDetailServiceProvider = (state = initialChangeTransporter, action) => {
    switch (action.type) {
        case RESET:
            return initialChangeTransporter;
        case CHANGE_DETAIL_SERVICE_PROVIDER:
            let out = {...initialChangeTransporter, ...state, ...action.data}
            return out;
        default:
            return state;
    }
};
export const infoOldArea = (state = [], action) => {
    switch (action.type) {
        case RESET:
            return [];
        case OLD_AREA_SEARCH :
            if (!state.some((i) => action.data?.normal_location?.id === i?.normal_location?.id)) {
                return [...state, action.data];
            }
            return state;

        case DELETE_OLD_AREA_SEARCH :
            let list = state.filter(i => action.data !== i?.normal_location?.id)
            return list;
        default:
            return state;

    }
};
