import React, {Component} from 'react';

class LoadingIcon extends Component {
    render() {
        return (
            <>
                <div className="loadingio-spinner-ripple-ndkueme4em">
                    <div className="ldio-tft879wxl8">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>
        );
    }
}

export default LoadingIcon;
