import {
    GET_CATEGORY_PARENTS,
    GET_BANNER,
    GET_MAGAZINE,
    GET_MAGAZINE_DETAIL,
    CREATE_BANNER,
    initialBanner,
    CREATE_CATEGORY,
    CREATE_SUBJECTS,
    VISITORS
} from 'redux/magazine/type';
import {fetcher} from "redux/common/action";
import {message} from "antd";

export const getUrlAsync = (data) => {
    return (dispatch) => {
        return fetcher('account/upload/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            })
    }
};
export const uploadFileAsync = (url, data, type) => {
    return (dispatch) => {
        return fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Content-Type": type
                },
                body: data,
            })
    }
};
export const sendServerAsync = (data) => {
    return (dispatch) => {
        return fetcher('account/media/photos/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            })
    }
};
export const uploadFile = (data, file, type) => {
    return async dispatch => {
        try {
            let getUrl = await getUrlAsync({"content_type": data})(dispatch);

            await uploadFileAsync(getUrl?.result?.upload_url, file, type)(dispatch);

            let response = await sendServerAsync({
                "url": getUrl?.result?.upload_url,
                "type": data,
                "bucket": data,
                "file_key": getUrl?.result?.file_key
            })(dispatch);
            /*await getProfile()(dispatch);*/
            return response;
            message.success(' با موفقیت آپلود شد')
        } catch (e) {
            message.error(' باخطا مواجه شدید')
        }
        return 'done';
    }
};
export const resultٰVisitors = (data) => {
    return {
        type: VISITORS,
        data
    }
}
export const resultCreateBanner = (data) => {
    return {
        type: CREATE_BANNER,
        data
    }
}
export const resultCreateCategory = (data) => {
    return {
        type: CREATE_CATEGORY,
        data
    }
}
export const resultCreateSubjects = (data) => {
    return {
        type: CREATE_SUBJECTS,
        data
    }
}
export const getBanner = () => {
    return (dispatch) => {
        return fetcher('banner/manage_banner/',
            {
                method: 'GET',
            })
            .then((response) => {
                /*  dispatch(resultGetBanner(response));*/
                return response;
            })
    }
};

export const resultGetCategoryParents = (data) => {
    return {
        type: GET_CATEGORY_PARENTS,
        data
    }
};
export const getCategoryParents = () => {
    return (dispatch) => {
        return fetcher('magazine/category/parents?ordering=-priority',
            {
                method: 'GET',
            })
            .then((response) => {
                dispatch(resultGetCategoryParents(response));
            })

    }
};
export const resultGetMagazine = (data) => {
    return {
        type: GET_MAGAZINE,
        data
    }
};
export const resultGetMagazineDetail = (data) => {
    return {
        type: GET_MAGAZINE_DETAIL,
        data
    }
};
export const getMagazine = (number, search = '') => {
    return (dispatch) => {
        return fetcher('magazine/timeline/?category=' + number + search,
            {
                method: 'GET',
            })
            .then((response) => {
                dispatch(resultGetMagazine(response));
            })
    }
};
export const getMagazineDetail = (number) => {
    return (dispatch) => {
        return fetcher('magazine/timeline/' + number,
            {
                method: 'GET',
            })
            .then((response) => {
                dispatch(resultGetMagazineDetail(response));
            })
    }
};
export const sanitizeCreateBanner = (infoCreateBanner) => {
    return {
        ...infoCreateBanner,

    }
};

export function createBanner() {
    return (dispatch, store) => {
        return fetcher('/banner/manage_banner/',
            {
                method: 'POST',
                body: JSON.stringify(sanitizeCreateBanner(store().infoCreateBanner)),
            })
    };
}
export function removeBanner(id) {
    return () => {
        return fetcher('/banner/manage_banner/' + id,
            {
                method: 'DELETE',
            })
    };
}
export const sanitizeCreateCategory = (infoCreateCategory,target) => {
    return {
        ...infoCreateCategory,
        targets:target


    }
};
export function createCategory() {
    return (dispatch, store) => {
        return fetcher('/magazine/category/',
            {
                method: 'POST',
                body: JSON.stringify(sanitizeCreateCategory(store().infoCreateCategory,store().infoVisitor)),
            })
    };
}
export const getCategory = () => {
    return (dispatch) => {
        return fetcher('magazine/category/parents?ordering=-priority',
            {
                method: 'GET',
            })
            .then((response) => {
                /*  dispatch(resultGetBanner(response));*/
                return response;
            })
    }
};
export function removeCategory(id) {
    return () => {
        return fetcher('magazine/category/' + id + '/',
            {
                method: 'DELETE',
            })
    };
}
export function updateCategory(id) {
    return (dispatch, store) => {
        return fetcher('magazine/category/' + id + '/',
            {
                method: 'PUT',
                body: JSON.stringify(sanitizeCreateCategory(store().infoCreateCategory,store().infoVisitor)),
            })
    };
}
export const sanitizeCreateSubject = (infoCreateSubject,target) => {
    console.log(infoCreateSubject)
    return {
        ...infoCreateSubject,
        targets:target
    }
};
export const getSubject = () => {
    return (dispatch) => {
        return fetcher('magazine/',
            {
                method: 'GET',
            })
            .then((response) => {
                /*  dispatch(resultGetBanner(response));*/
                return response;
            })
    }
};
export function createSubject() {
    return (dispatch, store) => {
        return fetcher('/magazine/',
            {
                method: 'POST',
                body: JSON.stringify(sanitizeCreateSubject(store().infoCreateSubject,store().infoVisitor)),
            })
    };
}
export function updateSubject(id) {
    return (dispatch, store) => {
        return fetcher('magazine/' + id + '/',
            {
                method: 'PUT',
                body: JSON.stringify(sanitizeCreateSubject(store().infoCreateSubject,store().infoVisitor)),
            })
    };
}
export function removeSubject(id) {
    return () => {
        return fetcher('magazine/' + id + '/',
            {
                method: 'DELETE',
            })
    };
}
