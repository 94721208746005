import React, {useEffect, useState} from 'react';
import {getCargoesOnMapDetail} from "../../redux/track/action";
import {useDispatch} from "react-redux";
import {MdAccessTime} from "react-icons/md";
import {FaArrowLeft, FaCalendarDay, FaMapMarkerAlt, FaPhoneSquareAlt, FaTruck} from "react-icons/fa";
import moment from "moment-jalaali";
import LoadingIcon from "../publicComponents/loadingIcon";
import {Spin} from "antd";

const PopupTrack = ({item,id}) => {
    const [cargo, setCargo] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(() => true)
        dispatch(getCargoesOnMapDetail(id, {})).then(r => {
            setLoading(() => false)
            if (r.statusCode === 200)
                setCargo(state => ({...state, ...r.result}))
        }).catch(e => {
            setLoading(() => false)
        })
    }, [])
    return (
        <div className="pt-4" style={{minWidth:'240px'}}>
            <div className="bgMainColor text-white p-2 text-right">
                اطلاعات بار
                <div className="pt-2">
                          <span className="title">
                                <span>کد بار : </span>
                                <span>{item?.tracking_code}</span>
                            </span>
                </div>
            </div>
            {cargo?.tracking_code && <>
                <div className='d-flex align-items-center justify-content-between p-2 mt-2 border-bottom'>
                    <div className='d-flex align-items-center justify-content-between'>
                        {cargo?.driver_profile?.media?.length ? <span className="profile-image ml-2" style={{backgroundImage: `url(${cargo?.driver_profile?.media[0]?.exact_url || ""})`}}/>:''}
                        <div className="pl-4 text-right">
                            <p className="m-0">{`${cargo?.driver_profile?.first_name ?? "-"} ${cargo?.driver_profile?.last_name ?? "-"}`}</p>
                            <p className="m-0">
                                <span className="ml-1 text-secondary">راننده</span>

                                {cargo?.eta_passed && <span className="ml-2 font-size-1 text-MainColor"><MdAccessTime/></span>}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
<span>
         {cargo?.driver_profile?.owner?.mobile_number ?? "-"}
</span>

                        <span className="text-success font-size-1 mr-1 pb-1"><FaPhoneSquareAlt/></span>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2 mt-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                        {(cargo?.owner?.media?.length) ? <span className="profile-image ml-2" style={{backgroundImage: `url(${cargo?.owner?.media[0]?.exact_url || ""})`}}/>:''}
                        <div className="pl-4 text-right">
                            <p className="m-0">{cargo?.owner?.name ?? "-"}</p>
                            <p className="m-0">
                                <span className="ml-1 text-secondary">صاحب بار</span>

                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
<span>
         {cargo?.owner?.mobile_number ?? "-"}
</span>

                        <span className="text-success font-size-1 mr-1 pb-1"><FaPhoneSquareAlt/></span>
                    </div>
                </div>
                <div>
                    <div>
                        <div
                            className="row text-right p-2 text-color ">
                            <div className="col-12 my-2">
                                <div>
                                    <span className="text-MainColor ml-2"><FaMapMarkerAlt/></span>
                                    <span>{(cargo?.origin?.city?.province?.name ?? '-') + ' ،' + (cargo?.origin?.city?.name ?? '-')}</span>
                                    <span className="text-MainColor px-1"><FaArrowLeft/></span>
                                    <span>{(cargo?.destination?.city?.province?.name ?? '-') + ' ،' + (cargo?.destination?.city?.name ?? '-')}</span>
                                </div>
                                <div className="mt-3">
                                    <span className="text-MainColor ml-2"><FaCalendarDay/></span>
                                    <span className="text-gray"> تاریخ بارگیری : </span>
                                    <span>{moment(cargo?.date, 'YYYY-MM-DD').format(' ddd jD jMMMM jYYYY')}</span>
                                </div>

                                <div className="mt-3">
                                    <span className="text-MainColor ml-2"><FaTruck/></span>
                                    <span className="text-gray">نوع ناوگان و بارگیر : </span>
                                    <span>{cargo?.car?.title}</span>
                                </div>
                            </div>

                            {/*<div className="col-12 my-2">*/}
                            {/*    <div className="">*/}
                            {/*        <span className="text-MainColor ml-2"><AiOutlineDropbox/></span>*/}
                            {/*        <span className="text-gray"> تناژ : </span>*/}
                            {/*        <span>{Boolean(Number(cargo.weight)) ? cargo.weight : ''}</span>*/}
                            {/*        <span className="mr-2">(تن)</span>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            {/*<div className="col-12 my-2">*/}
                            {/*    <p className="price p-2">*/}
                            {/*        {(cargo?.price_type == 0) ? 'توافقی' : ''}*/}
                            {/*        {(cargo?.price_type == 1) ? (numberWithCommas(cargo?.price) + ' تومان ' + 'به ازای هر سرویس ') : ''}*/}
                            {/*        {(cargo?.price_type == 2) ? (numberWithCommas(cargo?.price) + ' تومان ' + 'به ازای هر تن ') : ''}*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>
            </>}
            <div className="text-center">
                <Spin size="large" spinning={loading} indicator={<LoadingIcon/>}/>
            </div>

        </div>
    );
};

export default PopupTrack;
