import React, {Component} from "react";
import {HashRouter, Router, Switch, Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {createHashHistory} from "history";
import NotFound from "components/404";
import Dashboard from "components/dashboard";
import Score from "./components/score/score";
import Drivers from "components/users/driver/drivers";
import ServiceProvider from "components/users/serviceProvider/serviceProvider";
import DetailServiceProvider from "components/users/serviceProvider/detailServiceProvider";
import DriverDetail from "components/users/driver/detailDriver";
import EditDriver from "components/users/driver/editDriver";
import DetailCustomer from "components/users/customer/detailCustomer";
import Customer from "components/users/customer/customer";
import EditCustomer from "components/users/customer/editCustomer";
import Transport from "components/users/transport/transport";
import DetailTransport from "components/users/transport/detailTransporter";
import EditTransporter from "components/users/transport/editTransporter";
import Login from "components/login";
import Banner from "components/magazine/banner";
import NewBanner from "components/magazine/newBanner";
import Subjects from "components/magazine/subjects";
import NewSubject from "components/magazine/newSbject";
import Category from "components/magazine/category";
import NewCategory from "components/magazine/newCategory";
import Services from "components/service";
import NewServiceCategory from "components/service/newCategory";
import Request from "components/service/request";
import Cargo from "components/cargo";
import CargoDetail from "components/cargo/detail";
import Notification from "components/notification";
import Admin from "components/admin";
import NewTransport from "components/users/transport/newTransport";
import EditScore from "components/score/editscore";
import EditCargo from "components/cargo/editCargo";
import Coins from "components/score/coins";
import City from "components/area";
import Report from "components/report";
import ReportMap from "components/report/reportMap";
import ReportDownloadList from "components/report/downloadList";
import ReportPhoneNumber from "components/report/PhoneNumber";
import TrackMap from "components/track";
import TrackCargo from "components/trackCargo";
import TrackCargoDetail from "components/trackCargo/detail";
import Survey from "components/survey";
import SurveyDetail from "components/survey/detail";

class App extends Component {
    render() {
        const isLoggedIn = this.props.isLoggedIn;

        return (
            <HashRouter>
                <Router history={createHashHistory()}>
                    <Switch>
                        {
                            !isLoggedIn && <Route exact component={Login}/>
                        }

                        <Route path="/login" exact component={Login}/>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/service-provider" exact component={ServiceProvider}/>
                        <Route path="/service-provider-detail" exact component={DetailServiceProvider}/>
                        <Route path="/drivers" exact component={Drivers}/>
                        <Route path="/driver-detail" exact component={DriverDetail}/>
                        <Route path="/driver-edit" exact component={EditDriver}/>
                        <Route path="/customer" exact component={Customer}/>
                        <Route path="/customer-detail" exact component={DetailCustomer}/>
                        <Route path="/customer-edit" exact component={EditCustomer}/>
                        <Route path="/transport" exact component={Transport}/>
                        <Route path="/newtransport" exact component={NewTransport}/>
                        <Route path="/transporter-detail" exact component={DetailTransport}/>
                        <Route path="/transporter-edit" exact component={EditTransporter}/>
                        <Route path="/banner" exact component={Banner}/>
                        <Route path="/new-banner" exact component={NewBanner}/>
                        <Route path="/subjects" exact component={Subjects}/>
                        <Route path="/new-subject/:slug" exact component={NewSubject}/>
                        <Route path="/category" exact component={Category}/>
                        <Route path="/new-category/:slug" exact component={NewCategory}/>
                        <Route path="/service-category" exact component={Services}/>
                        <Route path="/new-service-category" exact component={NewServiceCategory}/>
                        <Route path="/service-request" exact component={Request}/>
                        <Route path="/cargo" exact component={Cargo}/>
                        <Route path="/cargo-detail/:slug" exact component={CargoDetail}/>
                        <Route path="/notification" exact component={Notification}/>
                        <Route path="/admin" exact component={Admin}/>
                        <Route path="/score" exact component={Score}/>
                        <Route path="/editscore/:slug" exact component={EditScore}/>
                        <Route path="/edit-cargo/:slug" exact component={EditCargo}/>
                        <Route path="/coins" exact component={Coins}/>
                        <Route path="/city" exact component={City}/>
                        <Route path="/report" exact component={Report}/>
                        <Route path="/report-map" exact component={ReportMap}/>
                        <Route path="/report-download-list" exact component={ReportDownloadList}/>
                        <Route path="/report-number" exact component={ReportPhoneNumber}/>
                        <Route path="/track-map" exact component={TrackMap}/>
                        <Route path="/track-cargo" exact component={TrackCargo}/>
                        <Route path="/track-cargo-detail/:slug" exact component={TrackCargoDetail}/>
                        <Route path="/survey" exact component={Survey}/>
                        <Route path="/survey/:user/:role/:owner/:slug" exact component={SurveyDetail}/>

                        <Route path="*" exact component={NotFound}/>

                    </Switch>
                </Router>
            </HashRouter>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        isLoggedIn: store.infoConfirmOtp?.isLoggedIn,
    };
};

export default connect(mapStateToProps)(App);
