import React, {Component} from "react";
import all from "images/icon/transport/all cargoes.svg";
import last from "images/icon/transport/New month cargoes.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaCheckCircle, FaTimesCircle, FaTrashAlt} from "react-icons/fa";
import { MdModeEdit} from "react-icons/md";
import FilterPrivate from "components/users/transport/filter";
import {
    getTransport,
    changeDetailTransporter,
    authDetail,
    deleteUser,
    deleteUserFake,
    numberUsers
} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Pagination, Spin} from "antd";
import {Link, NavLink as NavLinkRouter} from "react-router-dom";
import {ReactComponent as Add} from "../../../images/add.svg";

import {QueryMaker} from "components/users/Query";
import FilterUser from "components/users/filter";
import * as QueryString from "query-string";
import moment from "moment-jalaali";
// const Filter = [
//     {label: 'همه شرکت ها', value: ''},
//     {label: 'شرکت های امروز', value: '&date_min=' + moment().format('jYYYY-jMM-jDD')},
//     {label: 'شرکت های ماه جدید', value: '&date_min=' + moment().format('jYYYY-jMM-01')},
//     {label: 'در انتظار تایید', value: '&is_in_approve_queue=true'},
// ]
class Transport extends Component {
    state = {
        dropdownOpen: false,
        transport: [],
        transportCount: 0,
        page: 1,
        dashboard: {},
        search: '',
        params: {search: '', city: '', page: 1},
        city: ""
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    componentDidMount() {
        if( Object.keys(QueryString.parse(this.props.location.search))?.length ){
            this.setState({
                params: {search: '', city: '', page: Number((QueryString.parse(this.props.location.search)?.page) || 1)},
            },()=>{
                this.handleRequest(); 
                const { history } = this.props;
                history.push(`/transport`);
            })
        }else{
            this.handleRequest();
        }
        this.props.numberUsers("transportation").then(
            r => {
                this.setState({
                    dashboard: r?.result
                })
            }
        )
    }

    handleRequest = () => {
        let params = QueryMaker(this.state.params);
        this.props.getTransport(params).then((r) => {
            if (r?.result) {
                this.setState({
                    transport: r?.result,
                    transportCount: r?.count,
                });
            }
        });
    };
    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            }
        }, () => this.handleRequest());

    };
    getProvince = (c) => {
        this.setState({
            params: {
                ...this.state.params,
                city: c
            }
        });

    };

    render() {
        
        const {transport} = this.state;
        console.log("Service_state",this.state);

        return (
            <>
                <Main>
                    <Spin
                        size="large"
                        spinning={this.props.infoLoading}
                        indicator={<LoadingIcon/>}
                    >
                        <div className="row">
                            <Card name="تعداد کل شرکت ها" number={this.state.dashboard?.total_count}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="شرکت های جدید ماه جاری" number={this.state.dashboard?.current_month_count}>
                                <img src={last} alt=""/>
                            </Card>
                            <Card name="شرکت های جدید ماه قبل" number={this.state.dashboard?.last_month_count}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="شرکت های جدید امروز" number={this.state.dashboard?.today_count}>
                                <img src={last} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4 ">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">شرکت های حمل‌ونقل </p>
                                <div className="">
                                    <Link to="/newtransport">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn px-4 "
                                                style={{
                                                    background:
                                                        "linear-gradient( #FE9900 0%, #FF867B 100%)",
                                                    color: "white",
                                                }}
                                            >
                                                <Add/>
                                                <span className="position-absolute p-1"></span>
                                                ثبت شرکت حمل‌ونقل جدید
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            </div>


                            <div className="mt-1">
                                <div className="row">


                                    <FilterUser
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            filter: t,
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}

                                        getProvince={this.getProvince}
                                    />
                                    <FilterPrivate
                                        state={this.state}
                                        filterDropdown={(t) => this.setState({
                                            filter: t,
                                            params: {...this.state.params, [t.name]: (t.value)}
                                        })}
                                        handleRequest={() => {

                                            this.setState({
                                                params: {
                                                    ...this.state.params,
                                                    page: 1
                                                }
                                            }, () => this.handleRequest())
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className="table-main mt-4 table-responsive-sm
                table-responsive-md table-responsive-lg table-responsive-xl"
                            >
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th className="text-right">نام شرکت حمل‌ونقل</th>
                                        <th>محل شرکت حمل‌ونقل</th>
                                        <th>شماره همراه</th>
                                        <th>شماره تلفن ثابت</th>
                                        <th className="text-center"> کدملی</th>
                                        <th > تاریخ عضویت</th>
                                        <th > مجوز وانت بار</th>
                                        <th > مجوز  بین الملل</th>
                                        <th>احراز هویت</th>
                                        <th>ویرایش</th>
                                        <th>حذف</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transport.map((t, i) => (
                                        <tr>
                                            {console.log(t)}
                                            <td>{((Number(this.state.params?.page) > 1) ? ((Number(this.state.params?.page) - 1) * 10) : 0) + (i + 1)}</td>
                                            <td className="text-right">
                                                <NavLinkRouter
                                                    to={`/transporter-detail/?page=${this.state.params?.page}`}
                                                    // to="/transporter-detail"
                                                    onClick={() =>
                                                        this.props.changeDetailTransporter(t)
                                                    }
                                                >
                                                    {t?.company_name || 'بیان نشده'}
                                                </NavLinkRouter>
                                            </td>
                                            <td>{(t?.location?.city?.province?.name || '') + (t?.location?.city?.province?.name ? '،' : '') + (t?.location?.city?.name || '')}</td>
                                            <td>{t?.owner?.mobile_number}</td>
                                            <td>{t?.phone_number}</td>
                                            <td className="text-right">{t?.national_code || ''}</td>
                                            <td>{moment(t?.creation_date,'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') }</td>
                                            <td>
                                                {
                                                    t?.cargo_pickup_license ?
                                                        <span className='text-green' ><FaCheckCircle/></span>
                                                        :
                                                        <span className='text-gray' ><FaTimesCircle/></span>
                                                }
                                                </td>
                                            <td>
                                                {
                                                    t?.international_license ?
                                                        <span className='text-green' ><FaCheckCircle/></span>
                                                        :
                                                        <span className='text-gray' ><FaTimesCircle/></span>
                                                }
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={t?.is_approve}
                                                    onChange={(e) => {
                                                        this.props.authDetail({
                                                            is_approve: e.target.checked
                                                        }, t?.owner?.id, 'transportation')
                                                            .then(r => {
                                                                if (r?.statusCode == 200)
                                                                    this.handleRequest()
                                                            })
                                                    }}
                                                    className={` ${t.is_approve ? 'check-td-green' : ' check-td-gray'} border-0`}/>
                                                {t?.is_in_approve_queue && <span className="text-danger">*</span>}
                                            </td>
                                            <td>
                                                <NavLinkRouter
                                                    to="/transporter-edit"
                                                    onClick={() => {
                                                        this.props.changeDetailTransporter(t)
                                                    }
                                                    }
                                                    className="check-td-green"
                                                >
                                                    <MdModeEdit/>
                                                </NavLinkRouter>
                                            </td>
                                            <td>
                                                <button className="trash-td-pink"
                                                        onClick={() => {
                                                            if (t?.owner?.id) {
                                                                this.props.deleteUser(t?.owner?.id, 'transportation')
                                                                    .then(r => {
                                                                        this.handleRequest();
                                                                    })
                                                                    .catch(e => {
                                                                    })
                                                            } else {
                                                                this.props.deleteUserFake(t?.id, 'fake_transportation')
                                                                    .then(r => {
                                                                        this.handleRequest();
                                                                    })
                                                                    .catch(e => {
                                                                    })
                                                            }

                                                        }}>
                                                    <FaTrashAlt/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div dir="ltr" className="text-center">
                                    <Pagination defaultCurrent={1} current={this.state.params?.page} responsive={true}
                                                onChange={this.changePage}
                                                showSizeChanger={false}
                                                total={this.state.transportCount}/>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTransport: (...data) => dispatch(getTransport(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),
        deleteUser: (...data) => dispatch(deleteUser(...data)),
        deleteUserFake: (...data) => dispatch(deleteUserFake(...data)),
        changeDetailTransporter: (...data) =>
            dispatch(changeDetailTransporter(...data)),
        numberUsers: (...data) => dispatch(numberUsers(...data)),
    };
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Transport);
