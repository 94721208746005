import {createStore, combineReducers, applyMiddleware} from "redux";
import * as auth from './auth/reducer';
import * as common from 'redux/common/reducer';
import * as pages from 'redux/pages/reducer';
import * as magazine from 'redux/magazine/reducer';
import * as dashboard from 'redux/dashboard/reducer';
import * as service from 'redux/service/reducer';
import * as cargo from 'redux/cargo/reducer';
import * as notification from 'redux/notification/reducer';
import * as account from 'redux/account/reducer';
import * as area from 'redux/area/reducer';
import * as track from 'redux/track/reducer';
import * as trackCargo from 'redux/trackCargo/reducer';

import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middleware = [thunk];
const persistConfig = {
    key: 'root',
    storage,
};
const persistedReducer = persistReducer(persistConfig, combineReducers({
    ...auth,
    ...common,
    ...pages,
    ...magazine,
    ...dashboard,
    ...service,
    ...cargo,
    ...notification,
    ...account,
    ...area,
    ...track,
    ...trackCargo,
}));
let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
let persistor = persistStore(store);
export default store;
export {persistor};
