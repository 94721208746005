import React, {useState, useEffect} from 'react';
import Main from "components/publicComponents/main";
import {Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {connect} from "react-redux";
import {addCity} from "redux/area/action";
import ProvinceSelector from "components/area/ProvinceSelector.js"
import MapSelector from "components/area/MapSelector"



const Index = (props) => {
    const [title, setTitle] = useState('');
    const [point, getPoint] = useState({});
    const [province, setProvince] = useState([]);
    const getProvince = (data) => {
        setProvince(data)
    }
    const send = () => {
        console.log(title,point,province)
        props.addCity({
            "name":title,
            "province":province,
            "point":point
        })
    }

    useEffect(() => {

    }, []);
    return (
        <>
            <Main>
                <Spin size="large" spinning={props.infoLoading} indicator={<LoadingIcon/>}>
                    <div className="main-page-inside mt-4 text-right">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="main-title text-right">ایجاد شهر جدید</p>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">نام شهر</p>
                            <input type="text"
                                   onChange={(e)=>setTitle(e.target.value)}
                                   value={title}
                                   name="title"
                                   className="input-style-public-border pr-2"
                            />
                        </div>
                        {/*   <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">لینک اعلان</p>
                            <input type="url" className="input-style-public-border pr-2"/>
                        </div>*/}

                        <div className="row align-items-center">

                            <div className="col-12 mt-4">
                                <div className="d-flex ">
                                    <p className="text-banner">استان</p>
                                    <ProvinceSelector subject="انتخاب استان" getNewProvince={getProvince}/>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className=" ">
                                    <p className="text-banner">موقعیت</p>
                                    <MapSelector getPoint={getPoint}/>
                                </div>
                            </div>
                        </div>
                        <div className="text-left mt-4">
                            <button
                                className={`${(1) ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}
                                disabled={!(1)}
                                onClick={send}> تایید نهایی
                            </button>
                        </div>
                    </div>
                </Spin>
            </Main>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        addCity: (...data) => dispatch(addCity(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);