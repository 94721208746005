import React, {Component} from 'react';
import {
    FaSearch,
    FaMapMarkerAlt,
    FaArrowLeft,
    FaCalendarDay,
    FaTruck,
    FaCheckCircle,
    FaBox,
    FaUserAlt,
    FaEnvelopeOpenText,
    FaTrashAlt,

} from "react-icons/fa";
import { AiOutlineDropbox } from "react-icons/ai";
import {MdModeEdit} from "react-icons/md";
import moment from 'moment-jalaali';
import {NavLink as NavLinkRouter} from "react-router-dom";
import numberWithCommas from 'components/publicComponents/threeNumber';
import {deleteDetailCargo, getCargoes, editDetailCargo} from "redux/trackCargo/action";
import {connect} from "react-redux";
import * as QueryString from "query-string";
class Item extends Component {

    state = {
        page: 1,
        params: {search: '', city: '', page: 1},
        search: '',
        city: ""
    };


    render() {
        const {item,tag} = this.props;

        // console.log(item.weight);
        return (
            <>
                <div className="box-border mb-3">
                    <div className="text-right d-flex justify-content-between">
                            <span className="title px-3 py-1">
                                <span>کد بار : </span>
                                <span>{item?.tracking_code}</span>
                            </span>
                        {/*<div>*/}
                        {/*    <NavLinkRouter */}
                        {/*        to={`/edit-cargo/${item?.tracking_code}/`} className="text-MainColor ml-3"*/}
                        {/*    ><MdModeEdit/></NavLinkRouter>*/}
                        {/*    <button onClick={() => {*/}
                        {/*        this.props.deleteDetailCargo(item?.tracking_code).then(r => {*/}
                        {/*            this.props.getListCargoes()*/}
                        {/*        })*/}

                        {/*    }}*/}
                        {/*        className="text-MainColor"><FaTrashAlt/></button>*/}

                        {/*</div>*/}
                        { tag ? <span className="ant-tag ml-3">{tag }</span> :''}
                    </div>
                    {/* <NavLinkRouter to={"/cargo-detail/" + item?.tracking_code} */}
                    <NavLinkRouter  to={`/track-cargo-detail/${item?.tracking_code}/?page=${this.props.page}`}
                                   className="row text-right p-2 text-color ">
                        <div className="col-12 col-lg-6 col-xl-5 my-2">
                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaMapMarkerAlt/></span>
                                <span>{item?.origin?.city?.province?.name + ' ،' + item?.origin?.city?.name}</span>
                                <span className="text-MainColor px-1"><FaArrowLeft/></span>
                                <span>{item?.destination?.city?.province?.name + ' ،' + item?.destination?.city?.name}</span>
                            </div>
                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaCalendarDay/></span>
                                <span className="text-gray"> تاریخ بارگیری : </span>
                                <span>{moment(item?.date, 'jYYYY-jMM-jDD').format(' ddd jD jMMMM jYYYY')}</span>
                            </div>

                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaTruck/></span>
                                <span className="text-gray">نوع ناوگان و بارگیر : </span>
                                <span>{item?.car?.title}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5 my-2">
                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaBox/></span>
                                <span className="text-gray"> نوع کالا وبسته بندی : </span>
                                <span>{item?.type?.title}</span>
                            </div>
                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaUserAlt/></span>
                                <span className="text-gray"> اعلام بار کننده : </span>
                                <span>{item?.owner?.name}</span>
                                <span className="text-green ml-2"><FaCheckCircle/></span>
                            </div>
                            <div className="mt-3">
                                <span className="text-MainColor ml-2"><FaEnvelopeOpenText/></span>
                                <span className="text-gray">توضیحات : </span>
                                <span>{item?.description}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-5 my-2">
                            <div className="">
                                <span className="text-MainColor ml-2"><AiOutlineDropbox/></span>
                                <span className="text-gray"> تناژ : </span>
                                <span>{Boolean(Number(item.weight)) ? item.weight : ''}</span>
                                <span className="mr-2">(تن)</span>
                            </div>

                        </div>

                        <div className="col-12  col-xl-2 my-2">
                            <p className="price p-2">
                                {(item?.price_type == 0) ? 'توافقی' : ''}
                                {(item?.price_type == 1) ? (numberWithCommas(item?.price) + ' تومان ' + 'به ازای هر سرویس ') : ''}
                                {(item?.price_type == 2) ? (numberWithCommas(item?.price) + ' تومان ' + 'به ازای هر تن ') : ''}
                            </p>
                        </div>
                    </NavLinkRouter>

                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDetailCargo: (...data) => dispatch(deleteDetailCargo(...data)),
        getCargoes: (...data) => dispatch(getCargoes(...data)),
    };
};

const mapStateToProps = (store) => {
    return {
        infoGetProfile: store.infoGetProfile?.result,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Item);
