
export const GET_CATEGORY_PARENTS = 'GET_CATEGORY_PARENTS';
export const GET_BANNER = 'GET_BANNER';
export const GET_MAGAZINE= 'GET_MAGAZINE';
export const GET_MAGAZINE_DETAIL= 'GET_MAGAZINE_DETAIL';
export const CREATE_BANNER= 'CREATE_BANNER';
export const CREATE_CATEGORY= 'CREATE_CATEGORY';
export const CREATE_SUBJECTS= 'CREATE_SUBJECTS';
export const VISITORS= 'VISITORS';
export const initialMagazineDetail= {
    "id": null,
    "media": {
        "url":"",
        "type": "",
        "bucket": "",
        "file_key": "",
        "exact_url": ""
    },
    "creation_date": "",
    "caption": "",
    "description": "",
    "targets": [],
    "owner": null,
    "category": null
};
export const initialBanner= {
    "name": "",
    "type": "browser",
    "browser": "",
    "action": null,
    "show_status": true,
    "show_priority": 0,
    "media": {
        "url": "",
        "type": "",
        "bucket": "",
        "file_key": "",
        "exact_url":""
    }
};
export const initialCatergory= {
    "id": null,
    "title":"",
    "description":"",
    "priority":1,
    "media":{

        "url": "",
        "type": "",
        "bucket": "",
        "file_key": ""
    },
};
export const initialSubject= {
    "id": null,
    "caption":"",
    "description":"",
    "targets":[],
    "category":null,
    "media":{
        "url": "",
        "type": "",
        "bucket": "",
        "file_key": ""
    },
};
