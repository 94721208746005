import React, {useEffect, useState} from 'react';
import {fetcher} from "../../../redux/common/action";
import {UrlQuery} from "../../../utils/utils";
import {Pagination} from "antd";
import moment from "moment-jalaali";
const Payments = ({infoDetailCustomer}) => {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getData = () => {
        fetcher(UrlQuery('panel/coin-transactions/deposits/', {owner: infoDetailCustomer.owner.id, role: "driver",page}),
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    setList(response.result)
                    setTotal(response.count)
                }
            }).catch((err) => {

        })
    }

    useEffect(() => {
        if (infoDetailCustomer.owner.id)
            getData()

    }, [page])

    const changePage = (p) => {
        setPage(p)
    };

    return (
        <div>
            <div className="mt-4 ">
                <p className="text-right d-flex align-items-center">
                    <span className="main-title ">تراکنش ها</span>
                </p>

                <div className="table-main mt-4">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>شماره</th>
                            <th>مقدار اعتبار(سکه)</th>
                            <th>مبلغ تراکنش(تومان)</th>
                            <th>تاریخ</th>
                            <th>شناسه تراکنش</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(list?.length > 0) &&
                        list.map((t, i) =>
                            <tr>
                                <td>{i + 1}
                                </td>
                                <td>
                                    <span className="text-success">{t?.amount}+</span>

                                </td>

                                <td>
                                    <span className="">{t?.cafebazaar_payment.coin_quantity}</span>
                                </td>
                                <td>
                                    <span className="">{moment(t?.creation_date).format("YYYY/MM/DD")}</span>
                                </td>
                                <td>
                                <span className="text-info">{t?.cafebazaar_payment.order_id}</span>
                            </td>
                            </tr>)
                        }

                        </tbody>

                    </table>
                    <div dir="ltr" className="text-center">
                    <Pagination defaultCurrent={1} current={page} responsive={true}
                                onChange={changePage}
                                showSizeChanger={false}
                                total={total}/>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Payments;
