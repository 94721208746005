import React, {Component} from 'react';


class Card extends Component {
    render() {
        return (
            <>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-4">
                    <div className="card-intro p-4 ">

                        <div >
                            {this.props.children}
                        </div>
                        <div className="text-center">
                            <p className="text-gray pb-2">{this.props.name}</p>
                            <p className="text-color font-size-13 font-weight-bold">{this.props.number}</p>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default Card;
