import {fetcher} from "redux/common/action";
import {message} from "antd"


export const getProvince = (page = 1, data = '') => {
    return () => {
        return fetcher('panel/area/province/?page=' + page + '&search=' + data,
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            }).catch(e => {

                }
            )
    }
};

export const addCity = (data) => {
    return () => {
        return fetcher('panel/area/',
            {
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then((response) => {
                message.success('شهر جدید با موفقیت ساخته شد')
                return response;
            }).catch(e => {
                message.error('دوباره تلاش کنید')
                }
            )
    }
};

