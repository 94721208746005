import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import PamentScore from "./pamentScore";
import PaidScore from "./paidScore";

const listTabs = [
    {name: 'looking', label: 'اعتبار کسب شده', id: 1},
    {name: 'done', label: 'اعتبار خریداری شده', id: 2},
    {name: 'cancel', label: 'اعتبار خرج شده', id: 3},
]

const Score = ({infoDetailCustomer}) => {
    const [activeTab,setActiveTab]=useState("1");
    const [balance,setBalance]=useState(0);
    return (
        <div>
            <p className="text-right d-flex align-items-center justify-content-between">
                <span className="main-title ">تاریخچه اعتبار</span>
                <div className="box-gold py-1">
                    <span>اعتبار کل</span>
                    <span> : </span>
                    <span> {balance} </span>
                    <span>سکه</span>
                </div>
            </p>
                <Nav
                    className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                    {
                        listTabs.map((item, i) => <NavItem className="flex-grow-1">
                            <NavLink
                                className={classnames({active: activeTab === (i + 1).toString()})}
                                onClick={() => {
                                    if (activeTab !== (i + 1).toString()) {
                                        setActiveTab( (i + 1).toString())
                                    }
                                }}
                            >
                                {item?.label}
                            </NavLink>
                        </NavItem>)
                    }

                </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="mt-4 ">
                    {/*<p className="text-left mt-3">*/}
                    {/*    <span>  مجموع اعتبار کسب شده</span>*/}
                    {/*    <span> : </span>*/}
                    {/*    <span className="text-success">*/}
                    {/*       <span> 1200 </span>*/}
                    {/*    <span>سکه</span>*/}
                    {/*    </span>*/}
                    {/*</p>*/}
                    <div className="table-main mt-4">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>شماره</th>
                                <th>علت اعتبار</th>
                                <th>مقدار اعتبار(سکه)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(infoDetailCustomer?.scores?.length > 0) &&
                            infoDetailCustomer?.scores.map((t, i) =>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{t?.title}</td>
                                    <td><span className="text-success">{t?.score * t?.count}+</span></td>
                                </tr>)
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="mt-4 ">
                        {/*<p className="text-left mt-3">*/}
                        {/*    <span>   مجموع اعتبار خریداری شده شده</span>*/}
                        {/*    <span> : </span>*/}
                        {/*    <span className="text-success">*/}
                        {/*   <span> 1200 </span>*/}
                        {/*<span>سکه</span>*/}
                        {/*</span>*/}
                        {/*</p>*/}
                        <PamentScore infoDetailCustomer={infoDetailCustomer} setBalance={setBalance}/>
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div className="mt-4 ">
                        {/*<p className="text-left mt-3">*/}
                        {/*    <span>   مجموع اعتبار خرج شده</span>*/}
                        {/*    <span> : </span>*/}
                        {/*    <span className="text-success">*/}
                        {/*   <span> 1200 </span>*/}
                        {/*<span>سکه</span>*/}
                        {/*</span>*/}
                        {/*</p>*/}
                        <PaidScore infoDetailCustomer={infoDetailCustomer}/>
                    </div>
                </TabPane>
            </TabContent>

        </div>
    );
};

export default Score;
