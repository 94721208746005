import {fetcher} from "redux/common/action";
import {UrlQuery} from "../../utils/utils";
import {} from 'redux/dashboard/type';
import {message} from "antd";

export const getCargoesOnMap = (data) => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo_tracking/',data),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            }).catch(e=>{})
    }
};
export const getCargoesOnMapDetail = (id,data) => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery(`panel/cargo_tracking/${id}/`,data),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            }).catch(e=>{})
    }
};
