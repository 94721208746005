import {fetcher} from "redux/common/action";
import {UrlQuery} from "../../utils/utils";
import {} from 'redux/dashboard/type';
import {message} from "antd";

export const getCargoes = (data) => {
    return () => {
        // return fetcher('panel/cargo/?state=' + type + (page ? ('&page=' + page) : '') + '&role=' + role,
        return fetcher(UrlQuery('panel/cargo/',data),
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const getDetailCargo = (id = "") => {
    return () => {
        return fetcher('panel/cargo/' + id + '/',
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
    }
};
export const deleteDetailCargo = (id = "") => {
    return () => {
        return fetcher('panel/cargo/' + id + '/',
            {
                method: 'DELETE',
            })
            .then((response) => {
                return response;
            })
    }
};
export const editDetailCargo = (id = "", data) => {
    return () => {
        return fetcher('panel/cargo/' + id + '/',
            {
                method: 'PUT',
                body: JSON.stringify(data)
            })
            .then((response) => {
                return response;
            })
            .catch(r => {

            })
    }
};
