import {RESET} from "redux/common/type";
import {
    GET_CATEGORY_PARENTS,
    GET_BANNER,
    initialBanner,
    GET_MAGAZINE,
    GET_MAGAZINE_DETAIL,
    initialMagazineDetail,
    CREATE_BANNER,
    CREATE_CATEGORY,
    initialCatergory,
    CREATE_SUBJECTS,
    initialSubject,
    VISITORS
} from 'redux/magazine/type';

export const infoGetCategoryParents = (state = [], action) => {
    switch (action.type) {
        case RESET:
            return [];
        case GET_CATEGORY_PARENTS :
            return action.data?.result;
        default:
            return state;

    }
};


export const infoVisitor = (state = [], action) => {
    switch (action.type) {
        case RESET:
            return [];
        case VISITORS :
            return action.data;
        default:
            return state;

    }
};
export const infoCreateBanner = (state = initialBanner, action) => {
    switch (action.type) {
        case RESET:
            return initialBanner;
        case CREATE_BANNER :
            return {...initialBanner,...state,...action.data};
        default:
            return state;

    }
};
export const infoCreateCategory = (state = initialCatergory, action) => {
    switch (action.type) {
        case RESET:
            return initialCatergory;
        case CREATE_CATEGORY :
            return {...initialCatergory,...state,...action.data};
        default:
            return state;

    }
};

export const infoCreateSubject = (state = initialSubject, action) => {
    switch (action.type) {
        case RESET:
            return initialSubject;
        case CREATE_SUBJECTS :
            return {...initialSubject,...state,...action.data};
        default:
            return state;

    }
};
export const infoGetMagazine = (state = {result:[]}, action) => {
    switch (action.type) {
        case RESET:
            return {result:[]};
        case GET_MAGAZINE :
            return action.data?.result;
        default:
            return state;

    }
};
export const infoGetMagazineDetail = (state = initialMagazineDetail, action) => {
    switch (action.type) {
        case RESET:
            return initialMagazineDetail;
        case GET_MAGAZINE_DETAIL :
            return action.data?.result;
        default:
            return state;
    }
};
