import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from 'react-icons/io';
import {FaBookReader} from 'react-icons/fa';
import {changeDetailDriver} from "redux/dashboard/action";
import {connect} from "react-redux";
export const Educate = [
    {value: '0', label: 'بی سواد'},
    {value: '1', label: 'ابتدایی'},
    {value: '2', label: 'سیکل'},
    {value: '3', label: 'دبیرستان'},
    {value: '4', label: 'دیپلم'},
    {value: '5', label: 'کاردانی'},
    {value: '6', label: 'کارشناسی'},
    {value: '7', label: 'کارشناسی ارشد'},
];

class VehicleSelectorFilter extends Component {
    state = {
        toggle: false,
        selectedOption: {label: '', value: null}
    };
    toggle = () => {
        this.setState({toggle: !this.state.toggle})
    };
    handleOptionChange = (changeEvent) => {
        this.setState({
            selectedOption: JSON.parse(changeEvent.target.value)
        });
        this.props.changeDetailDriver({
            "education_degree": JSON.parse(changeEvent.target.value).value
        });
        this.toggle();
    }


    componentDidMount() {
        this.props.changeDetailDriver({
            "education_degree": "1"
        });
        this.setState({
            selectedOption: Educate.filter((i) => this.props.infoDetailDriver?.education_degree == i.value)[0]
        })
    }

    render() {

        return (
            <>
                <button onClick={this.toggle} className="box-detail  d-block w-100 mt-2 p-0">
                    <p className="d-flex align-items-center justify-content-between py-3 p-2"
                       style={{lineHeight: '2.2rem'}}>
                                <span>
                                <span className="text-MainColor"><FaBookReader/></span>
                                <span className="mr-2">{this.props.subject + ' : ' +
                                (this.state.selectedOption?.label ? this.state.selectedOption?.label :'')}</span>
                                </span>
                        <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                    </p>
                </button>

                <Modal centered={true} isOpen={this.state.toggle} toggle={this.toggle} className="modal-login">
                    <ModalBody className="p-0 border-radius-5 pb-4">
                        <div
                            className="py-3 headColor d-flex align-items-center justify-content-between mb-3 px-2">
                            <p className="text-white text-center  pr-2">
                                انتخاب مدرک تحصیلی
                            </p>
                            <button onClick={this.toggle} className="text-white font-size-15">
                                <IoIosClose/>
                            </button>
                        </div>

                        <div className="form-check-inline-style">
                            {
                                Educate.map((item, i) =>
                                    <div key={i}
                                         className="form-check-inline d-block text-right my-4 ml-3">
                                                                <span className="position-relative">
                                                                    <input type="radio"
                                                                           checked={this.state.selectedOption?.value == item.value}
                                                                           onChange={this.handleOptionChange}
                                                                           className="form-check-input ml-1 checkbox-style"
                                                                           value={JSON.stringify(item)}/>
                                                                   <span className="check"/>
                                                                     <label htmlFor="">{item.label}</label>
                                                                </span>

                                    </div>)
                            }
                        </div>

                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoDetailDriver: store.infoDetailDriver,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelectorFilter);
