import React, {Component} from "react";
import Main from "components/publicComponents/main";
import {IoIosArrowDown} from "react-icons/io";
import {MdModeEdit} from "react-icons/md";
import {Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Pagination, Spin} from "antd";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {getScore} from "redux/score/action";
import NotFoundFile from "components/publicComponents/notFoundFile";
import * as QueryString from "query-string";
class Score extends Component {
    state = {
        dropdownOpen: false,
        transport: [],
        transportCount: 0,
        scores: [],
        scoresCount: 0,
        page: 1,
        params: {search: '', city: '', page: 1},
        target: Targets[0]
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    componentDidMount() {

        if( Object.keys(QueryString.parse(this.props.location.search))?.length ){
            this.setState({
                params: {search: '', city: '', page: Number((QueryString.parse(this.props.location.search)?.page) || 1)},
            },()=>{
                this.getScore(this.state.page);
                const { history } = this.props;
                history.push(`/cargo`);
            })
        }else{
            this.getScore(this.state.page);
        }
        
    }

    getScore = (p) => {
        this.props.getScore(p, this.state.target?.value).then((r) => {
            if (r?.result) {
                this.setState({
                    scores: r?.result,
                    scoresCount: r?.count,
                });
            }
        });
    }

    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            }
        }, () => this.getScore(p));

    };

    render() {
        return (
            <>
                <Main>
                    <Spin
                        size="large"
                        spinning={this.props.infoLoading}
                        indicator={<LoadingIcon/>}
                    >
                        <div className="main-page-inside mt-4 ">
                            <div className=" d-flex align-items-center justify-content-between">
                                <p className="col-3 main-title text-right">امتیازات</p>
                                <div className="col-9 d-flex justify-content-end">
                                    <div className="d-flex align-items-center ml-4">
                                        <span className="ml-2 ">دسته بندی براساس</span>
                                        <Dropdown
                                            isOpen={this.state.dropdownOpen}
                                            toggle={this.toggle}
                                        >
                                            <DropdownToggle className="dropdown-btn bg border px-4 text-dark">
                                                <span>{this.state.target?.label}</span>
                                                <span className="pr-2">
                          <IoIosArrowDown/>
                        </span>
                                            </DropdownToggle>
                                            <DropdownMenu style={{right: 0}}>
                                                {
                                                    Targets.map(t =>
                                                        <button className="dropdown-btn-item"
                                                                onClick={_ => this.setState({target: t,dropdownOpen:false},()=>this.getScore())
                                                                }>{t?.label}</button>)
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <NavLinkRouter to="/coins">
                                        <div className="mr-5 ">
                                            <button
                                                type="button"
                                                className="btn px-4 mr-2"
                                                style={{
                                                    background:
                                                        "linear-gradient( #FE9900 0%, #FF867B 100%)",
                                                    color: "white",
                                                }}
                                            >
                                                <span className="position-absolute p-1"/>
                                                اعطای سکه هدیه
                                            </button>
                                        </div>
                                    </NavLinkRouter>
                                </div>
                            </div>
                            <div
                                className="table-main mt-4 table-responsive-sm
                table-responsive-md table-responsive-lg table-responsive-xl"
                            >
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th className="text-right">شاخه اصلی</th>
                                        <th>تعداد سکه</th>
                                        <th>سقف سکه ماهانه</th>
                                        <th>ویرایش</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.scores.map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td className="text-right">{item?.title}</td>
                                            <td>{item?.score}</td>
                                            <td>{item?.ceil}</td>
                                            <td>
                                                <NavLinkRouter to={`/editscore/${item?.id}`}>
                                                    <span className="check-td-green"><MdModeEdit/></span>
                                                </NavLinkRouter>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div dir="ltr" className="text-center">
                                <Pagination defaultCurrent={1} current={this.state.params?.page || 1} responsive={true}
                                            onChange={this.changePage}
                                            showSizeChanger={false}
                                            hideOnSinglePage={true}
                                            total={this.state?.scoresCount}/>
                                {
                                    this.state?.scoresCount === 0 &&
                                    <NotFoundFile/>
                                }
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScore: (...data) => dispatch(getScore(...data)),
    };
};
const mapStateToProps = (store) => {
    return {
        infoGetProfile: store.infoGetProfile?.result,
        infoLoading: store.infoLoading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Score);
const Targets = [
    {label: 'همه کاربری ها', value: ''},
    {label: 'رانندگان', value: 'driver'},
    {label: 'شرکت حمل‌ونقل  ', value: 'transportation'},
    {label: 'خدمت دهنده ها', value: 'serviceprovider'},
    {label: 'صاحب بار', value: 'customer'},
];
