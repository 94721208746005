import {
    SERVICE_REQUEST,
    SERVICE_CATEGORY,
    RESULT_SERVICE_CATEGORY,
} from 'redux/service/type';
import {fetcher} from "redux/common/action";
import {CREATE_SUBJECTS} from "../magazine/type";

export const resultServiceCategory = (data) => {
    return {
        type: RESULT_SERVICE_CATEGORY,
        data
    }
};
export const getRequest = () => {
    return (dispatch) => {
        return fetcher('panel/request?',
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            }).catch(r => {
            })
    }
};

export function addServiceCategory(data, method, id = '') {
    return (dispatch, store) => {
        return fetcher('/panel/service_category/' + id,
            {
                method: method,
                body: JSON.stringify(data),
            }).then(r => {
            return r;
        })
            .catch(r => {
            })
    };
}


export function getServiceCategory() {
    return () => {
        return fetcher('/panel/service_category',
            {
                method: 'GET',
            }).then(r => {
            return r;
        })
            .catch(r => {
            })
    };
}

// status_choices = [
//     ('looking_provider', 'looking_provider',),
//     ('expired', 'expired',),
//     ('accept', 'accept'),
//     ('cancel', 'cancel')
// ]
export function getServiceRequest(status = 'looking_provider') {
    return () => {
        return fetcher('/panel/request?status=' + status,
            {
                method: 'GET',
            }).then(r => {
            return r;
        })
            .catch(r => {
            })
    };
}

export const getTags = () => {
    return (dispatch) => {
        return fetcher('service/tags',
            {
                method: 'GET',
            })
            .then((response) => {
                return response;
            })
            .catch(r => {
            })
    }
};
