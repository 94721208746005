import {message, Spin} from "antd";
import React from "react";
import Main from "components/publicComponents/main";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {addTransport, setRequestTransport,resetRequestTransport} from "redux/account/action";
import DatePicker from 'react-datepicker2';
import moment from 'moment-jalaali';
import PlaceSelector from "components/users/PlaceSelector";

class NewTransport extends React.Component {
    state = {
        dropdownOpen: false,
        transport: [],
    };

    componentDidMount() {
        this.props.resetRequestTransport();
    }

    send = () => {
        this.props.addTransport()
    };
    changeInput = (e) => {
        this.props.setRequestTransport({
            [e.target.name]: e.target.value
        })
    };

    render() {
        return (
            <>
                <Main>
                    <div
                        id="newTransport" className="container box-detail py-5 mt-5">
                        <p className="col-3 mr-3 main-title text-right">
                            ثبت شرکت حمل‌ونقل  جدید
                        </p>
                        <div className="mt-3 px-3">
                            <div className="row text-right">
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">نام شرکت حمل‌ونقل </p>
                                    <input
                                        type="text"
                                        name="company_name"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">نام مدیر شرکت</p>
                                    <input
                                        type="text"
                                        name="founder_name"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">نام </p>
                                    <input
                                        type="text"
                                        name="first_name"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">نام خانوادگی </p>
                                    <input
                                        type="text"
                                        name="last_name"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">کدملی</p>
                                    <input
                                        type="number"
                                        name="national_code"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">شماره تلفن همراه
                                        <span className="text-pink "> * </span>
                                           </p>

                                    <input
                                        type="number"
                                        name="phone_number"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">حوزه کاری</p>
                                    <input
                                        type="text"
                                        name="area_expertise"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">آدرس دقیق محل زندگی</p>
                                    <input
                                        type="text"
                                        name="address_live"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <p className="mb-1">آدرس دقیق محل کار</p>
                                    <input
                                        type="text"
                                        name="address_work"
                                        className="input-style-public-border pr-2"
                                        onChange={this.changeInput}
                                    />
                                </div>
                                {/* <div className="col-12 col-md-6 mt-4">
                                    <label className="mb-2">تاریخ تاسیس :</label>
                                    <DatePicker
                                        className="input-style-public-border pr-2 mt-2"
                                        value={this.state.insurance_body_deadline}
                                        timePicker={false}
                                        isGregorian={false}
                                        onChange={(value) => {
                                          /*  this.setRequestTransport(value.format('jYYYY-jMM-jDD'), 'insurance_body_deadline')*/
                                }
                                <div className="col-12 col-md-6 mt-4">
                                    <label className="mb-2">شهر محل فعالیت:</label>
                                    <PlaceSelector title={'محل  فعالیت'}
                                                   prefix={"شهر:"}
                                                   property="setRequestTransport"
                                                   result='infoNewTransport'
                                                   type={"location"}/>
                                </div>
                                <div className="col-12 col-md-6 mt-4">
                                    <label className="mb-2">شهر محل زندگی:</label>
                                    <PlaceSelector title={'محل  فعالیت'}
                                                   prefix={"شهر:"}
                                                   property="setRequestTransport"
                                                   result='infoNewTransport'
                                                   type={"office_location"}/>
                                </div>
                                <div className="col-12 mt-4 text-left">
                                    <Link to="/transport">
                                        <button
                                            className="btn px-4"
                                            style={{
                                                color: "#FE9900",
                                                border: "1px solid #FF867B",
                                            }}
                                        >
                                            انصراف
                                        </button>
                                    </Link>
                                    <button
                                        type="button"
                                        onClick={this.send}
                                        className="btn mr-3"
                                        style={{
                                            background: "linear-gradient(#FE9900 , #FF867B)",
                                            color: "white",
                                        }}
                                    >
                                        ثبت شرکت حمل‌ونقل
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* </Spin> */}
                </Main>
            </>
        )
            ;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTransport: (...data) => dispatch(addTransport(...data)),
        setRequestTransport: (...data) => dispatch(setRequestTransport(...data)),
        resetRequestTransport: (...data) => dispatch(resetRequestTransport(...data)),
    };
};

const mapStateToProps = (store) => {
    return {
        infoGetProfile: store.infoGetProfile?.result,
        infoNewTransport: store.infoNewTransport,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewTransport);
