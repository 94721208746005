import React, {Component, useEffect, useState} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from 'react-icons/io';
import {FaBookReader} from 'react-icons/fa';
import {connect} from "react-redux";
import {getProvince} from "redux/area/action";
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";

export const Educate = [
    {value: '0', label: 'بی سواد'},
    {value: '1', label: 'ابتدایی'},
    {value: '2', label: 'سیکل'},
    {value: '3', label: 'دبیرستان'},
    {value: '4', label: 'دیپلم'},
    {value: '5', label: 'کاردانی'},
    {value: '6', label: 'کارشناسی'},
    {value: '7', label: 'کارشناسی ارشد'},
];
const ProvinceSelector = (props) => {
    const [toggle, setToggle] = useState(false);
    const [selectedOption, setSelectedOption] = useState(false);
    const [province, setProvince] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState('');
    const handleOptionChange = (changeEvent) => {
        setSelectedOption(JSON.parse(changeEvent.target.value))
        props.getNewProvince(JSON.parse(changeEvent.target.value)?.id)
        setToggle(false);
    }
    useEffect(() => {
        searchCity()
    }, []);
    const searchCity = (p = 1, d) => {
        props.getProvince(p, d)
            .then(r => {

                if (r?.result) {
                    setProvince((p == 1) ? r?.result : province.concat(r?.result))
                    setLoading(false);
                    setHasMore(r?.result?.length > 9)
                    setCount(r?.count)
                }
            })
    };
    const handleInfiniteOnLoad = () => {
        setLoading(true)
        console.log(province?.length,count)
        if (province?.length >= count) {
            setLoading(false)
            return ;
        } else {
            searchCity(Math.floor(province?.length / 10) + 1, data)
        }

    };

    return (
        <>
            <button onClick={() => setToggle(true)} className="box-detail  d-block w-100 mt-2 p-0">
                <p className="d-flex align-items-center justify-content-between py-3 p-2"
                   style={{lineHeight: '2.2rem'}}>
                                <span>
                                <span className="text-MainColor"><FaBookReader/></span>
                                <span className="mr-2">{props.subject + ' : ' +
                                (selectedOption?.name ? selectedOption?.name : '')}</span>
                                </span>
                    <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                </p>
            </button>

            <Modal centered={true} isOpen={toggle} toggle={() => setToggle(false)} className="modal-login">
                <ModalBody className="p-0 border-radius-5 pb-4">
                    <div
                        className="py-3 headColor d-flex align-items-center justify-content-between mb-3 px-2">
                        <p className="text-white text-center  pr-2">
                            انتخاب استان
                        </p>
                        <button onClick={() => setToggle(false)} className="text-white font-size-15">
                            <IoIosClose/>
                        </button>
                    </div>
                    <Spin size="large" spinning={props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="box-height-modal text-right mt-3 px-3 mb-2" style={{height: '40vh'}}>

                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                hasMore={!loading && hasMore}
                                useWindow={false}
                            >
                                <div className="form-check-inline-style">
                                {
                                    province.map((item, i) =>
                                        <div key={i}
                                             className="form-check-inline d-block text-right my-4 ml-3">
                                                                <span className="position-relative">
                                                                    <input type="radio"
                                                                           checked={selectedOption?.id == item?.id}
                                                                           onChange={handleOptionChange}
                                                                           className="form-check-input ml-1 checkbox-style"
                                                                           value={JSON.stringify(item)}/>
                                                                   <span className="check"/>
                                                                     <label htmlFor="">{item?.name}</label>
                                                                </span>

                                        </div>)
                                }
                                </div>
                            </InfiniteScroll>
                        </div>
                    </Spin>


                </ModalBody>
            </Modal>
        </>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        getProvince: (...data) => dispatch(getProvince(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoDetailDriver: store.infoDetailDriver,
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ProvinceSelector);
