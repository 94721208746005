import React, {Component} from 'react';
import {connect} from "react-redux";
import {Pagination} from 'antd';
import {fetcher} from "redux/common/action";
import {NavLink as NavLinkRouter} from "react-router-dom";
import moment from "moment-jalaali";

class CustomerSurvey extends Component {
    state = {
        list: [],
        listCount: 0,
        loading: true,
        params: {page: 1},
    }


    componentDidMount() {
        this.getList();
        console.log(this)
    }

    getList = () => {
        fetcher(`panel/survey-report/${this.props.user}/?page=` + this.state.params.page,
            {
                method: 'GET',
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    console.log(response)
                    this.setState({
                        list: response.result,
                        listCount: response.count
                    })
                }

            }).catch(e => {})
    };
    changePage = (p) => {
        this.setState({
            params: {
                ...this.state.params,
                page: p
            },
        }, () => this.getList());
        console.log(p);
    };

    render() {
        return (
            <>

                <div className="table-main mt-4">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>شماره</th>
                            <th>نام و نام خانوادگی</th>
                            <th> نقش کاربر</th>
                            <th>شماره همراه</th>
                            <th>
                                <div>میانگین امتیازات</div>
                                <sub>(کسب شده)</sub>
                            </th>
                            <th>
                                <div>میانگین امتیازات</div>
                                <sub>(داده شده)</sub>
                            </th>

                            <th>تاریخ آخرین نظرسنجی</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.list.map((item, i) => <tr>
                                <td>{i + 1}</td>
                                <td><NavLinkRouter to={`/survey/${this.props.user}/${this.props.role}/${item.owner?.id}/${item.id}/?page=${this.state.params?.page}`}>
                                    {1 ? 'بیان نشده' : ''}</NavLinkRouter></td>
                                <td>{`${item.first_name ?? ''} ${item.last_name ?? ''}`}</td>
                                <td>{item.owner?.mobile_number ?? ''}</td>
                                <td>{item.rating ?? 0}</td>
                                <td>{item.given_rate ?? 0}</td>
                                <td>{moment(item.creation_date).format("YYYY/MM/DD")}</td>

                            </tr>)
                        }


                        </tbody>

                    </table>
                    <div dir="ltr" className="text-center">
                        <Pagination defaultCurrent={1} current={this.state.params?.page || 1} responsive={true}
                                    onChange={this.changePage}
                                    showSizeChanger={false}
                                    total={this.state.listCount}/>
                    </div>
                </div>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerSurvey);
