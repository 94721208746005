import React, {Component} from 'react';
import all from "images/icon/driver/all drivers.svg";
import last from "images/icon/driver/last  month drivers.svg";
import now from "images/icon/driver/new month drivers.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {authDetail, changeDetailDriver, getCargoesDriver, getVehiclesList} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import Record from "components/users/record";
import moment from 'moment-jalaali';
import {Educate} from 'components/users/EducareSelectorFilter';
import * as QueryString from "query-string";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from "classnames";
import {FaStar} from "react-icons/fa";
import Box from "components/survey/box";
import Payments from "components/users/driver/payments";
import Score from "components/users/driver/pamentScore";
import Shop from "components/users/driver/shop";

const listTabs = [
    {name: 'looking', label: 'اطلاعات کاربری', id: 1},
    {name: 'done', label: 'مشخصات ناوگان', id: 2},
    {name: 'done', label: 'بارها', id: 3},
    {name: 'cancel', label: 'تراکنش ها', id: 4},
    {name: 'cancel', label: 'تاریخچه اعتبار', id: 5},
    {name: 'cancel', label: 'فروشگاه', id: 6},
    // {name: 'cancel', label: 'نظرسنجی', id: 4},
]

class DetailDriver extends Component {
    state = {
        pageNo: QueryString.parse(this.props.location.search),
        customer: [],
        customerCount: 0,
        profile: '',
        national: '',
        smartcode: '',
        modal: false,
        input: null,
        data: '',
        selectedOptionApprove: false,
        vehiclesList: [],
        cargoesNow: [],
        cargoesReserve: [],
        cargoesCarrying: [],
        cargoesCancel: [],
        cargoesPast: [],
        activeTab: '1',
    }

    componentDidMount() {
        this.props.getVehiclesList().then(r => {
            if (r?.result) {
                this.setState({
                    vehiclesList: r?.result
                })
            }
        });
        this.getCargoes();
        if (this.props.infoDetailDriver?.media) {
            let profile = this.props.infoDetailDriver?.media.filter(t => t?.type === "driverprofile");
            let national = this.props.infoDetailDriver?.media.filter(t => t?.type === "national");
            let smartcode = this.props.infoDetailDriver?.media.filter(t => t?.type === "smartcode");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url,
                    selectedOptionApprove: this.props.infoDetailDriver?.is_approve
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }
            if (smartcode.length !== 0) {
                this.setState({
                    smartcode: smartcode[0]?.exact_url
                })
            }
        }
    }

    getCargoes = () => {
        this.props.getCargoesDriver(1, '0', this.props.infoDetailDriver?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesNow: r?.result
                })
            }
        })
        this.props.getCargoesDriver(1, '2,3', this.props.infoDetailDriver?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesReserve: r?.result
                })
            }
        })
        this.props.getCargoesDriver(1, '4,5', this.props.infoDetailDriver?.owner?.id).then(r => {
            if (r?.result) {
                this.setState({
                    cargoesCarrying: r?.result
                })
            }
        })
        this.props.getCargoesDriver(1, '1,6', this.props.infoDetailDriver?.owner?.id,'0').then(r => {
            if (r?.result) {
                this.setState({
                    cargoesCancel: r?.result
                })
            }
        })
        this.props.getCargoesDriver(1, '1,6', this.props.infoDetailDriver?.owner?.id,'1,2').then(r => {
            if (r?.result) {
                this.setState({
                    cargoesPast: r?.result
                })
            }
        })
    }

    handleReturn = () => {
        window.location.href = `#/drivers/?page=${this.state.pageNo.page}`
    }


    // preventBack = () => {
    //     window.history.forward()
    //     setTimeout(() => {
    //         preventBack()
    //     }, 0);
    //     window.onunload=function(){null};
    // }

    render() {
        const {infoDetailDriver} = this.props;

        return (
            <>
                <Main>
                    <button onClick={this.handleReturn} className="btn-return-page">بازگشت</button>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row mt-3">
                            <Card name="تعداد کل سفرها"
                                  number="5435">
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های موفق"
                                  number="5435">
                                <img src={now} alt=""/>
                            </Card>
                            <Card name="تعداد سفر‌های ناموفق"
                                  number="5435">
                                <img src={last} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <Nav
                                className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                {
                                    listTabs.map((item, i) => <NavItem className="flex-grow-1">
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === (i + 1).toString()})}
                                            onClick={() => {
                                                if (this.state.activeTab !== (i + 1).toString()) {
                                                    this.setState({
                                                        activeTab: (i + 1).toString()
                                                    })
                                                }
                                            }}
                                        >
                                            {item?.label}
                                        </NavLink>
                                    </NavItem>)
                                }

                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <p className="main-title text-right d-flex align-items-center mt-4">
                                    <span className="profile-image ml-2" style={{
                                        backgroundImage: `url(${this.state.profile})`
                                    }}/>
                                        <span>{infoDetailDriver?.first_name || ''} {infoDetailDriver?.last_name || ''}</span>
                                    </p>
                                    <div className="row text-right">
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>شماره همراه : </span>
                                            <span>{infoDetailDriver?.owner?.mobile_number}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>محل زندگی : </span>
                                            <span>{(infoDetailDriver?.location?.city?.province?.name || '') + ' ، ' + (infoDetailDriver?.location?.city?.name || '')}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>تاریخ تولد : </span>
                                            <span>{infoDetailDriver?.birth_date ? moment(infoDetailDriver?.birth_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>تحصیلات : </span>
                                            <span>{Educate.filter((t) => t.value === infoDetailDriver?.education_degree)[0]?.label}</span>
                                        </div>
                                    </div>
                                    <p className="main-title text-right d-flex align-items-center mt-5">
                                        <span className="pl-2"> احراز هویت</span>
                                    </p>


                                    <div className="row text-right">
                                        <div className="col-12 col-md-6   mt-4">
                                            <p className="text-right d-flex align-items-center"
                                               title="با کلیک بر این دکمه میتوانید احراز هویت کاربر را تغییر دهید">
                                                <span className="pl-2"> احراز هویت</span>


                                                <label className="switch"
                                                       title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                                    <input type="checkbox"
                                                           defaultChecked={infoDetailDriver?.is_approve}
                                                           onChange={(e) => {
                                                               this.props.changeDetailDriver({is_approve: e.target.checked})
                                                               this.props.authDetail({
                                                                   is_approve: e.target.checked
                                                               }, infoDetailDriver?.owner?.id, 'driver')
                                                                   .then(r => {
                                                                       if ((r?.statusCode == 200) && r?.result?.is_approve)
                                                                           this.props.changeDetailDriver({is_approve: r?.result?.is_approve})
                                                                   })
                                                           }}
                                                    />
                                                    <span className="slider"/>
                                                </label>
                                                <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailDriver?.is_approve ?
                                                            <span className="text-success">(کاربر تایید شد)</span>
                                                            :
                                                            <span className="text-pink">(کاربر تایید نشده)</span>
                                                    }
                                                </span>


                                            </p>
                                        </div>
                                        <div className="col-12 col-md-6   mt-4">
                                            <p className=" text-right d-flex align-items-center"
                                               title="با کلیک بر این دکمه میتوانید احراز هویت کاربر را تغییر دهید">
                                                <span className="pl-2">  فعال/مسدود</span>
                                                <label className="switch"
                                                       title="با کلیک بر این دکمه میتوانید اخراز هویت کاربر را تغییر دهید">
                                                    <input type="checkbox"
                                                           defaultChecked={infoDetailDriver?.is_active}
                                                           onChange={(e) => {
                                                               this.props.changeDetailDriver({is_active: e.target.checked})
                                                               this.props.authDetail({
                                                                   is_active: e.target.checked
                                                               }, infoDetailDriver?.owner?.id, 'driver')
                                                                   .then(r => {
                                                                       if ((r?.statusCode == 200) && r?.result?.is_active)
                                                                           this.props.changeDetailDriver({is_active: r?.result?.is_active})
                                                                   })
                                                           }}
                                                    />
                                                    <span className="slider"/>
                                                </label>
                                                <span className="mr-3 font-weight-light font-size-08">
                                                    {

                                                        this.props.infoDetailDriver?.is_active ?
                                                            <span className="text-success">(کاربر فعال شده)</span>
                                                            :
                                                            <span className="text-pink">(کاربر مسدود شد)</span>
                                                    }
                                                </span>


                                            </p>
                                        </div>
                                    </div>
                                    <div className="row text-right">
                                        <div className="col-12 col-md-6 mt-4"><span>کد ملی : </span>
                                            <span>{infoDetailDriver?.national_code}</span>
                                        </div>
                                        <div className="col-12 col-md-6   mt-4">
                                            <div className="d-flex align-items-center">
                                                <span>عکس کارت ملی : </span>
                                                <a href={this.state.national} target="_blank" dir="ltr"
                                                   className="text-wrap-width">{this.state.national}</a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6   mt-4"><span>شماره هوشمند راننده : </span>
                                            <span>{infoDetailDriver?.smart_driver_code}</span>
                                        </div>
                                        <div className="col-12 col-md-6   mt-4">
                                            <div className="d-flex align-items-center ">
                                                <span>عکس کارت هوشمند راننده : </span>
                                                <a href={this.state.smartcode} target="_blank" dir="ltr"
                                                   className="text-wrap-width">{this.state.smartcode}</a>
                                            </div>
                                        </div>

                                    </div>
                                    <p className="main-title text-right d-flex align-items-center mt-5">
                                        امتیازات</p>
                                    <div className="row text-right">
                                        {(infoDetailDriver?.scores?.length > 0) &&
                                            infoDetailDriver?.scores.map((t, i) =>
                                                <div className="col-12 col-md-6  mt-4"><span>{t?.title} : </span>
                                                    <span>{t?.score * t?.count}</span>
                                                </div>)
                                        }


                                    </div>
                                    <p className="main-title text-right d-flex align-items-center mt-5">
                                        توضیحات</p>

                                    <textarea name="" id="" cols="30" rows="4"
                                              className="input-style-public-border p-3 mt-3"
                                              defaultValue={infoDetailDriver?.admin_description ? infoDetailDriver?.admin_description : ''}
                                              onChange={e => this.props.changeDetailDriver({admin_description: e.target.value})}/>
                                    <button className="btn-success"
                                            disabled={!infoDetailDriver?.admin_description}
                                            onClick={() => {
                                                this.props.authDetail({
                                                    admin_description: infoDetailDriver?.admin_description
                                                }, infoDetailDriver?.owner?.id, 'driver')
                                            }}>افزودن توضیح
                                    </button>
                                </TabPane>
                                <TabPane tabId="2">
                                    {/*<p className="main-title text-right d-flex align-items-center">*/}
                                    {/*    مشخصات ناوگان</p>*/}
                                    <div className="row text-right">
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>نوع وسیله نقلیه : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.vehicle?.car?.title + ' ، ' + infoDetailDriver?.vehicles_info[0]?.vehicle?.option?.title}</span>


                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4">
                                            <span>شماره هوشمند ناوگان : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.smart_car_code}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4">
                                            <span>شماره پلاک ناوگان : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.plate_car}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4">
                                            <span>نام برند : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.brand}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>نوع سیستم ناوگان : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.type}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>سال ساخت ناوگان : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.create_year}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>سررسید بیمه بدنه : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.insurance_body_deadline ? moment(infoDetailDriver?.vehicles_info[0]?.insurance_body_deadline, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4  mt-4"><span>سررسید بیمه ثالث : </span>
                                            <span>{infoDetailDriver?.vehicles_info[0]?.insurance_third_party_deadline ? moment(infoDetailDriver?.vehicles_info[0]?.insurance_third_party_deadline, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                        </div>

                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Record
                                        getListCargoes={this.getCargoes}
                                        mainClass={true}
                                        list={[
                                            {
                                                name: 'سفرهای جاری',
                                                cargo: this.state.cargoesNow,
                                                tag: null
                                            },
                                            {
                                                name: 'رزرو',
                                                cargo: this.state.cargoesReserve,
                                                tag: 'تایید نشده'
                                            },
                                            {
                                                name: 'درحال حمل',
                                                cargo: this.state.cargoesCarrying,
                                                tag: 'تایید شده'
                                            },
                                            {
                                                name: 'حمل شده',
                                                cargo: this.state.cargoesPast,
                                                tag: null
                                            },
                                            {
                                                name: 'لغو شده',
                                                cargo: this.state.cargoesCancel,
                                                tag: null
                                            },
                                        ]}/>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Payments infoDetailCustomer={infoDetailDriver}/>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Score infoDetailCustomer={infoDetailDriver}/>
                                </TabPane>
                                <TabPane tabId="6">
                                    <Shop infoDetailCustomer={infoDetailDriver}/>
                                </TabPane>
                                {/*<TabPane tabId="4">*/}
                                {/*    <Box user="drivers" id={this.props.infoDetailDriver?.owner?.id}/>*/}
                                {/*</TabPane>*/}
                            </TabContent>


                        </div>


                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        getCargoesDriver: (...data) => dispatch(getCargoesDriver(...data)),
        authDetail: (...data) => dispatch(authDetail(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailDriver: store.infoDetailDriver,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailDriver);
