import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {FaPlusCircle, FaTrashAlt} from "react-icons/fa";
import {MdModeEdit} from "react-icons/md";
import {resultServiceCategory, getServiceCategory,addServiceCategory} from "redux/service/action";
import {connect} from "react-redux";
import moment from 'moment-jalaali';
import {message, Spin} from "antd";
import LoadingIcon from "components/publicComponents/loadingIcon";

class Index extends Component {
    state = {
        listCategory: []
    };

    componentDidMount() {
      this.getServiceCategory();
    }
    getServiceCategory=()=>{
        this.props.getServiceCategory().then(r => {
            if (r?.result) {
                this.setState({
                    listCategory: r?.result
                })
                /*   this.setState({
                       listCategory:r?.result
                   })*/
            }

        })
    }
    render() {
        return (
            <>

                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">دسته بندی خدمات</p>
                                <NavLinkRouter to="/new-service-category" className="submit-btn w-auto px-4 py-2">
                                    <span className="pl-2"><FaPlusCircle/></span>
                                    ایجاد دسته جدید
                                </NavLinkRouter>

                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th>نام دسته بندی</th>
                                        <th>توضیحات</th>
                                        {/*<th>تاریخ</th>*/}
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.listCategory.map((b, i) => <tr>
                                            <td>{i + 1}</td>

                                            <td>{b?.title}</td>
                                            <td>{b?.description}</td>
                                            {/*<td>{moment(b?.creation_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD')}</td>*/}
                                            <td><NavLinkRouter to="/new-service-category"
                                                               onClick={() => this.props.resultServiceCategory(b)}
                                                               className="check-td-green"><MdModeEdit/></NavLinkRouter>
                                            </td>
                                            <td>
                                                <button className="trash-td-pink"
                                                        onClick={() => this.props.addServiceCategory({
                                                            "title": this.state.name,
                                                            "description": this.state.description
                                                        }, "DELETE", b?.id).then(r =>  this.getServiceCategory()).catch(r => {
                                                            message.error('با خطا مواجه شدید')
                                                        })}>
                                                    <FaTrashAlt/></button>
                                            </td>
                                            <td>
                                                {/*{
                                                (this.state.banners?.length === (i + 1)) ?
                                                    <button className='submit-btn  px-1 py-1'>غیر فعال کردن</button>
                                                    :
                                                    <button className='submit-btn-border px-1 py-1'> فعال
                                                        کردن</button>

                                            }*/}
                                            </td>
                                        </tr>)
                                    }


                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceCategory: (...data) => dispatch(getServiceCategory(...data)),
        resultServiceCategory: (...data) => dispatch(resultServiceCategory(...data)),
        addServiceCategory: (...data) => dispatch(addServiceCategory(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
