import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    getCargoes
} from "redux/report/action";
import {FaCheckCircle} from "react-icons/fa";
import {Pagination} from "antd";
import NotFoundFile from "../publicComponents/notFoundFile";

class List extends Component {
    state = {
        list:[],
        listCount:0,
        page:1
    };

    componentDidMount() {
        this.getListCargoes()
    }
    getListCargoes = (p=1) => {
        this.setState({page:p})
        let cargoParams={}
        cargoParams={
            report_type:"mobile_number",
            ...this.props.params,
            page:p
        }
        this.props.getCargoes(cargoParams).then(r => {
            if (r?.result) {
                this.setState({
                    list: r?.result,
                    listCount: r?.count,
                })
            }

        })
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateComponent !== this.props.updateComponent) {
            this.getListCargoes()
        }
    }

    render() {

        const {vehiclesList=[]} = this.props;
        const {list,listCount} = this.state;
        return (
            <>



                    <div className="table-main">
                        <table className="table mt-4 ">
                            <thead>
                            <tr>
                                <th>شماره</th>
                                <th>نام</th>
                                <th>نام خانوادگی</th>
                                <th> شماره موبایل</th>
                                <th>تعداد  اعلام بار</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                list.map((item , index) => <tr>
                                    <td>{index+1}</td>
                                    <td>{item?.owner__customer__first_name}</td>
                                    <td>{item?.owner__customer__last_name}</td>
                                    <td>{item?.owner__mobile_number}</td>
                                    <td>{item?.cargo_count}</td>
                                </tr>)
                            }


                            </tbody>
                        </table>
                        <div dir="ltr" className="text-center">
                            <Pagination defaultCurrent={1} current={this.state?.page || 1} responsive={true}
                                        onChange={(e) => this.getListCargoes(e)}
                                        showSizeChanger={false}
                                        hideOnSinglePage={true}
                                        total={listCount}/>
                            {
                                listCount === 0 &&
                                <NotFoundFile/>
                            }
                        </div>
                    </div>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCargoes: (...data) => dispatch(getCargoes(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(List);
