import React, {Component} from 'react';

import Main from "components/publicComponents/main";
import Visitor from "components/magazine/visitor";
import {connect} from "react-redux";
import { Redirect} from "react-router-dom";
import {uploadFile, resultCreateBanner, createBanner} from "redux/magazine/action";
import {initialBanner} from "redux/magazine/type";
import {message} from "antd";

class NewBanner extends Component {
    state = {
        dropdownOpen: false,
        ready: false,
        next: false,
        showFile: ''
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        this.props.resultCreateBanner(initialBanner)
    }

    sendPic = (e) => {

        this.setState({
            file: e.target.files[0],
            showFile: URL.createObjectURL(e.target.files[0])
        });
        if (e.target.files[0]) {
            this.props.uploadFile(e.target.id, e.target.files[0], e.target.files[0].type).then(r => {
                if (r?.result) {
                    this.props.resultCreateBanner({
                        media: r?.result,
                    })
                    this.setState({
                        ready: r?.statusCode === 201
                    })
                }
            })
        }
    };
    sendBanner = () => {
        let error = null;
        !this.props.infoCreateBanner.name && (error = 'نام بنر');
        // !this.props.infoCreateBanner.browser && (error = 'لینک بنر');

        if(!error){
        this.props.createBanner().then((r) => {

            this.setState({
                next: true
            })

        }).catch(r => {
            message.error('با خطا مواجه شدید')
        })
        }else {
            message.error(error+ 'را وارد کنید');
        }
    };

    render() {
        if (this.state.next) {
            return <Redirect to="/banner"/>
        }
        return (
            <>
                <Main>

                    <div className="main-page-inside mt-4 text-right">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="main-title text-right">ایجاد بنر جدید</p>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">لینک بنر</p>
                            <input type="text" className="input-style-public-border pr-2"
                                   onChange={(e) => this.props.resultCreateBanner({browser: e.target.value})}
                            />
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <p className="text-banner">توضیحات</p>
                            <input type="text" className="input-style-public-border pr-2"/>
                        </div>

                        <div className="row mt-4 align-items-center">
                            <div className="col-12 col-md-6">
                                <div className="d-flex align-items-center ">
                                    <p className="text-banner">نام بنر</p>
                                    <input type="text" className="input-style-public-border pr-2"
                                           onChange={(e) => this.props.resultCreateBanner({name: e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                                <div className="d-flex align-items-center ">
                                    <span className="text-banner">آپلود عکس </span>
                                    <div className="border-radius-10 ">
                                        <div className="d-flex align-items-center justify-content-center ">
                                    <span className="position-relative">
                                    <input type="file" className="pr-2 inputUpload py-1" title="یک فایل انتخاب کنید"
                                           onChange={this.sendPic}
                                           accept="image/png, image/jpeg,image/jpg"
                                           id="banner"
                                    />
                                        <span
                                            className="layerUpload d-flex align-items-center text-center justify-content-center">انتخاب فایل</span>
                                    </span>

                                        </div>
                                    </div>
                                    <img className="img-banner mr-2" src={this.state.showFile} alt=""/>
                                </div>
                            </div>

                        </div>
                        <Visitor/>
                        <div className="text-left">
                            <button
                                className={`${this.state.ready ? 'submit-btn' : 'submit-btn-silver'} w-auto px-3 py-2 d-inline-block`}
                                disabled={!this.state.ready}
                                onClick={this.sendBanner}> تایید نهایی
                            </button>
                        </div>
                    </div>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (...data) => dispatch(uploadFile(...data)),
        resultCreateBanner: (...data) => dispatch(resultCreateBanner(...data)),
        createBanner: (...data) => dispatch(createBanner(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoMedia: store.infoMedia,
        infoCreateBanner: store.infoCreateBanner,

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewBanner);
