import React, {Component} from "react";
import {NavLink as NavLinkRouter, Redirect} from 'react-router-dom';
import Main from "components/publicComponents/main";
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import {Tabs, Tab} from "react-bootstrap";
import {Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";
import Visitor from "components/magazine/visitor";
import {giveScore,giftScore} from "redux/score/action";
import {connect} from "react-redux";
import {message} from 'antd'
import classnames from "classnames";
import Item from "../users/record";


class Coins extends React.Component {
    state = {
        dropdownOpen: false,
        floor_score: 0,
        activeTab: '1',
        score_type: null,
        item:{}
    };

    /*toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };*/
    send = (data) => {
        if (this.state.floor_score) {
            this.props.giveScore(data)
                .then(r => {
                    if (r?.statusCode == 200) {
                        message.success('اعطای سکه با موفقیت انجام شد ')
                    }
                })
        } else {
            message.error('مقدار صحیح سکه را وارد کنید ')
        }
    };
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })

        }
    };
    changeItem = (e) => {
        this.setState({
            item: {
                ...this.state.item,
                [e.target.name]: e.target.value
            }
        })
    };
    giftScore=()=>{
        this.props.giftScore({...this.state.item,roles:this.props.infoVisitor,"targets":this.props.infoVisitor})
    };

    render() {
        const {item} =this.state;
        return (
            <div>
                <Main>
                    <div className="main-page-inside mt-4 ">
                        <p className="col-3 main-title text-right">اعطای سکه هدیه</p>
                        <div className="mt-4">
                            <div>
                                <Nav
                                    className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                                    {
                                        [{name: 'کاربران کم کار', cargo: this.state.cargoesNow},
                                            {name: 'کاربران همگانی', cargo: this.state.cargoesPast}].map((item, i) =>
                                            <NavItem className="flex-grow-1">
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab == (i + 1)})}
                                                    onClick={() => {
                                                        this.toggle((i + 1).toString());
                                                    }}
                                                >
                                                    {item?.name}
                                                </NavLink>
                                            </NavItem>)
                                    }
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="mt-5 px-3 text-right">
                                            <p className="mb-1"> اعطای سکه به کاربران تا سقف </p>
                                            <input
                                                className="input-style-public-border pr-2"
                                                onChange={(e) => this.setState({floor_score: e.target.value})}
                                                type="text"/>
                                        </div>
                                        <Visitor text="اعطا برای : "/>
                                        <div className="mt-4">
                                            <NavLinkRouter to="/score"
                                                           className="submit-btn-border d-inline-block w-auto px-5 ml-3 py-2">
                                                انصراف
                                            </NavLinkRouter>
                                            <button
                                                onClick={() => this.send({
                                                    "role": this.props.infoVisitor,
                                                    "floor_score": this.state.floor_score
                                                })}
                                                className="submit-btn d-inline-block w-auto px-5 py-2">
                                                پرداخت سکه
                                            </button>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="row mt-5 text-right">
                                            <div className="col-12 col-lg-4 mt-3">
                                                <p className="mb-1">عنوان سکه</p>
                                                <input type="text"
                                                       className="input-style-public-border pr-2"
                                                       onChange={this.changeItem}
                                                       name="title"/>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-3">

                                                <p className="mb-1">تعداد سکه</p>
                                                <input type="text"
                                                       name="score"
                                                       onChange={this.changeItem}
                                                       className="input-style-public-border pr-2"/>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-3">
                                                <p className="mb-1">سقف ماهیانه</p>
                                                <input type="text"
                                                       name="ceil"
                                                       onChange={this.changeItem}
                                                       className="input-style-public-border pr-2"/>
                                            </div>
                                            <div className="col-12 col-lg-6  mt-3">
                                                <p className="mb-1">توضیحات</p>
                                                <textarea className="input-style-public-border pr-2"
                                                          rows="3"
                                                          name="description"
                                                          onChange={this.changeItem}/>
                                            </div>
                                            <div className="col-12 col-lg-6  mt-3">
                                                <p className="mb-1">نوع</p>
                                                <input type="number"
                                                       name="type"
                                                       onChange={this.changeItem}
                                                       placeholder="  عددی را به عنوان نوع جدید وارد کنید"
                                                       className="input-style-public-border pr-2"/>
                                            </div>
                                        </div>

                                        <Visitor text="اضافه برای : "/>
                                        <div className="mt-4">
                                            <NavLinkRouter to="/score"
                                                           className="submit-btn-border d-inline-block w-auto px-5 ml-3 py-2">
                                                انصراف
                                            </NavLinkRouter>
                                            <button
                                                onClick={this.giftScore}
                                                className="submit-btn d-inline-block w-auto px-5 py-2">
                                                پرداخت سکه
                                            </button>
                                        </div>
                                    </TabPane>

                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Main>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        giveScore: (...data) => dispatch(giveScore(...data)),
        giftScore: (...data) => dispatch(giftScore(...data)),
    };
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoVisitor: store.infoVisitor
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Coins);
