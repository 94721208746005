import {MENU,SERVICE,CARGO} from 'redux/pages/type';
export const pageSave = (data,type) => {
    switch (type) {
        case 'menu':
            return ({
                type: MENU,
                data
            });
        case 'service':
            return ({
                type: SERVICE,
                data
            });
        case 'cargo':
            return ({
                type: CARGO,
                data
            });
    }

};
