import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {MdCheckCircle} from "react-icons/md";
import {changeDetailTransporter, getVehiclesList, editDetail, getTransport,editDetailFake} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import PlaceSelector from "components/users/PlaceSelector";
import EducateSelectorFilter from "components/users/EducareSelectorFilter";
import DatePicker from 'react-datepicker2';
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";
import moment from 'moment-jalaali';
import CarSelector from "components/users/CarSelector";
import NameSelector from "components/users/NameSelector";

class EditTransporter extends Component {
    state = {
        date: moment(),
        back: false
    }

    componentDidMount() {
        this.setState({
            date: moment(this.props.infoDetailTransporter?.established_date).isValid() ? moment(this.props.infoDetailTransporter?.established_date) : moment()
        })
    }

    render() {
        if (this.state.back) {
            return <Redirect to="/transport"/>
        }
        const {infoDetailTransporter} = this.props;
        return (
            <>
                <Main>

                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row text-right">
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره تلفن همراه :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.mobile_number}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({mobile_number: e.target.value})
                                       }}
                                       disabled={!this.props.infoDetailTransporter?.id}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره تلفن ثابت :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.phone_number}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({phone_number: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام شرکت حمل‌ونقل :</label>
                                <NameSelector title={'نام شرکت حمل‌ونقل'} prefix={"شرکت حمل‌ونقل :"} type={""}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">مدیر عامل :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.founder_name}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({founder_name: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شهر محل زندگی :</label>
                                <PlaceSelector title={'محل زندگی'} prefix={"شهر:"}
                                               property='changeDetailTransporter'
                                               result='infoDetailTransporter'
                                               type={"location"}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">حوزه کاری :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.area_expertise}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({area_expertise: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">سال تاسیس :</label>
                                <DatePicker
                                    className="input-style-public-border pr-2 mt-2"

                                    value={this.state.date}
                                    timePicker={false}
                                    isGregorian={false}
                                    onChange={(value) => {
                                        if (value)
                                            this.props.changeDetailTransporter({
                                                "established_date": value.format('jYYYY-jMM-jDD')
                                            })
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">آدرس دقیق :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.location?.address}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({
                                               location: {
                                                   ...infoDetailTransporter?.location,
                                                   address: e.target.value
                                               }
                                           })
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">کد دعوت :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.inviter_code}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({inviter_code: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">کد ملی :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailTransporter?.national_code}
                                       onChange={(e) => {
                                           this.props.changeDetailTransporter({national_code: e.target.value})
                                       }}
                                />
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-4 text-center my-4">
                                <button className="submit-btn-green "
                                        disabled={this.props.infoLoading}
                                        onClick={() => {
                                            if (this.props.infoDetailTransporter?.owner?.id) {


                                                this.props.editDetail('infoDetailTransporter', this.props.infoDetailTransporter?.owner?.id, 'transportation')
                                                    .then(r => {
                                                        /*this.props.cancel();*/
                                                        if (r?.result) {
                                                            this.props.getTransport().then(r => {
                                                                if (r?.result) {
                                                                    let item = r?.result.filter(t => t?.owner?.id == this.props.id);
                                                                    if (item?.length !== 0) {
                                                                        this.props.changeDetailTransporter(item[0]);
                                                                    }
                                                                    this.setState({back: true})
                                                                }
                                                            })
                                                        }
                                                    })
                                            } else {
                                                this.props.editDetailFake('infoDetailTransporter', this.props.infoDetailTransporter?.id, 'fake_transportation')
                                                    .then(r => {
                                                        /*this.props.cancel();*/
                                                        if (r?.result) {
                                                            this.props.getTransport().then(r => {
                                                                if (r?.result) {
                                                                    let item = r?.result.filter(t => t?.owner?.id == this.props.id);
                                                                    if (item?.length !== 0) {
                                                                        this.props.changeDetailTransporter(item[0]);
                                                                    }
                                                                    this.setState({back: true})
                                                                }
                                                            })
                                                        }
                                                    })
                                            }
                                        }}>
                                    {this.props.infoLoading ? "درحال اتصال" : 'ذخیره تغییرات'}
                                </button>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTransport: (...data) => dispatch(getTransport(...data)),
        changeDetailTransporter: (...data) => dispatch(changeDetailTransporter(...data)),
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        editDetail: (...data) => dispatch(editDetail(...data)),
        editDetailFake: (...data) => dispatch(editDetailFake(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailTransporter: store.infoDetailTransporter,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTransporter);
