import React, {useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import ModalShop from "./modalShop";
import {fetcher} from "../../../redux/common/action";
import {UrlQuery} from "../../../utils/utils";

const listTabs = [
    {name: 'looking', label: 'خدمات', id: 1},
    // {name: 'done', label: 'محصولات', id: 2},
]

const Shop = ({infoDetailCustomer}) => {
    const [activeTab, setActiveTab] = useState("1");
    const [list, setList] = useState([]);

    const getData=()=>{
        fetcher(UrlQuery('panel/service-pockets/',{owner:infoDetailCustomer.owner.id,role:"customer"}),
            {
                method: 'GET',
            })
            .then((response) => {
                if(response.statusCode === 200){
                    setList(response.result)
                }
            }).catch((err) => {

        })
    }

    useEffect(() => {
        if(infoDetailCustomer.owner.id)
            getData()

    }, [])

    return (
        <div>
            <div className="mt-4 ">
                <p className="text-right d-flex align-items-center">
                    <span className="main-title ">فروشگاه</span>
                </p>
                <Nav
                    className="pr-0 mb-3 tabs d-flex align-items-center justify-content-between py-1">
                    {
                        listTabs.map((item, i) => <NavItem className="flex-grow-1">
                            <NavLink
                                className={classnames({active: activeTab === (i + 1).toString()})}
                                onClick={() => {
                                    if (activeTab !== (i + 1).toString()) {
                                        setActiveTab((i + 1).toString())
                                    }
                                }}
                            >
                                {item?.label}
                            </NavLink>
                        </NavItem>)
                    }

                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="table-main mt-4">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>شماره</th>
                                    <th>علت اعتبار</th>
                                    <th>مقدار اعتبار(سکه)</th>
                                    <th>سقف بدهی</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(list?.length > 0) &&
                                list.map((t, i) =>
                                    <tr>
                                        <td>{i + 1}
                                        </td>
                                        <td>{t?.service_title}
                                        </td>
                                        <td>
                                            <span className="text-success">{t?.inventory}+</span>

                                        </td>
                                        <td>
                                            <ModalShop item={t} getData={getData}/>
                                        </td>
                                    </tr>)
                                }

                                </tbody>
                            </table>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                    </TabPane>
                </TabContent>

            </div>
        </div>
    );
};

export default Shop;
