import React, {Component} from 'react';
import {changeDetailDriver, getVehiclesList} from "redux/dashboard/action";
import {connect} from "react-redux";
import {Modal, ModalBody} from 'reactstrap';
import {IoIosArrowDropleft, IoIosClose} from 'react-icons/io';
import {FaTruckMoving} from 'react-icons/fa';

class CarSelector extends Component {
    state = {
        toggle: false,
        toggle2: false,
        options: [],
        cars: [],
        selectCar: {},
        selectOption: {},
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    };
    toggle2 = () => {
        this.setState({
            toggle2: !this.state.toggle2
        })
    };

    componentDidMount() {
        this.props.getVehiclesList().then(r => r?.result && this.setState({cars: r?.result})).catch(e => {
        })
    }

    render() {
        return (
            <>

                <button onClick={this.toggle} className="box-detail  d-block w-100  p-0">
                    <p className="d-flex align-items-center justify-content-between py-1 px-2"
                       style={{lineHeight: '2.2rem'}}>
                                <span>
                                <span className="text-MainColor"><FaTruckMoving/></span>
                                <span
                                    className="mr-2">{(this.state.selectCar?.car?.title || 'انتخاب وسیله نقلیه') +(this.state.selectOption?.title ? ' ، ' : '')
                                + (this.state.selectOption?.title || '')}
                                </span>
                                </span>
                        <span className="text-MainColor font-size-2"><IoIosArrowDropleft/></span>
                    </p>
                </button>

                <Modal centered={true} isOpen={this.state.toggle} toggle={this.toggle} className="modal-login"
                       size="lg">
                    <ModalBody className="p-0 border-radius-5 pb-4">
                        <div
                            className="py-3 headColor d-flex align-items-center justify-content-between mb-3 px-2">
                            <p className="text-white text-center  pr-2">
                                انتخاب نوع ناوگان
                            </p>
                            <button onClick={this.toggle} className="text-white font-size-15">
                                <IoIosClose/>
                            </button>
                        </div>
                        <div className="p-3">
                            <div className="form-check-inline-style">
                                <div className="row justify-content-center">
                                    {this.state.cars.map((v, i) => <div className="col-6 mt-2">
                                        <button className="button-car w-100 py-4" key={i}
                                                onClick={() => {
                                                    this.props.filterDropdown({name: "car", value: v?.car?.id})
                                                    this.setState({
                                                        selectCar: v,
                                                        options: v.option
                                                    });
                                                    this.toggle2();
                                                }}
                                                value={v?.car?.id}>
                                            {v?.car?.title}
                                        </button>
                                    </div>)}
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                </Modal>
                <Modal centered={true} isOpen={this.state.toggle2} className="modal-login" size="lg" toggle={()=>this.setState({toggle2:false})}>
                    <ModalBody className="p-0 border-radius-5 pb-4">
                        <div
                            className="py-3 headColor d-flex align-items-center justify-content-between mb-3 px-2">
                            <p className="text-white text-center  pr-2">
                                انتخاب نوع بارگیر
                            </p>
                            {/*  <button onClick={this.toggle2} className="text-white font-size-15">
                                <IoIosClose/>
                            </button>*/}
                        </div>
                        <div className="p-3">
                            <div className="form-check-inline-style">
                                <div className="row justify-content-center">
                                    <div
                                        className="form-check-inline d-block w-100 text-right my-2 ml-3">
                                                                <span className="position-relative">
                                                                    <input type="radio"
                                                                           checked={!this.state.selectOption?.id}
                                                                           onChange={() => {
                                                                               this.props.filterDropdown({
                                                                                   name: "option",
                                                                                   value: ""
                                                                               })
                                                                               this.setState({selectOption: {}})

                                                                               this.toggle();
                                                                               this.toggle2()
                                                                           }}
                                                                           className="form-check-input ml-1 checkbox-style"/>
                                                                   <span className="check"/>
                                                                     <label htmlFor=""> همه بارگیرها</label>
                                                                </span>

                                    </div>
                                    {this.state.options.map((v, i) => <div key={i}
                                                                           className="form-check-inline d-block w-100 text-right my-2 ml-3">
                                                                <span className="position-relative">
                                                                    <input type="radio"
                                                                           checked={this.state.selectOption?.id == v?.id}
                                                                           onChange={() => {
                                                                               this.props.filterDropdown({
                                                                                   name: "option",
                                                                                   value: v?.id
                                                                               })
                                                                               this.setState({selectOption: v})

                                                                               this.toggle();
                                                                               this.toggle2()
                                                                           }}
                                                                           className="form-check-input ml-1 checkbox-style"
                                                                           value={v?.option?.id}/>
                                                                   <span className="check"/>
                                                                     <label htmlFor=""> {v?.title}</label>
                                                                </span>

                                    </div>)}

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),
        changeDetailDriver: (...data) => dispatch(changeDetailDriver(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailDriver: store.infoDetailDriver,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CarSelector);
