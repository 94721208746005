import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    getListAdmin
} from "redux/auth/action";
import {FaCheckCircle} from "react-icons/fa";

class List extends Component {
    state = {
        list: []
    }

    componentDidMount() {
        this.props.getListAdmin().then(r => {
            if (r?.result) {
                this.setState({
                    list: r?.result
                })
            }
        })
    }

    render() {
        return (
            <>

                <div className="main-page-inside mt-4 text-right">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="main-title text-right">لیست گزارشات </p>
                    </div>
                    <div className="table-main">
                        <table className="table mt-4 ">
                            <thead>
                            <tr>
                                <th>شماره تلفن همراه</th>
                                <th>ادمین</th>
                                <th>مدیر</th>
                                <th>فعال</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.list.map(item => <tr>
                                    <td>{item?.mobile_number}</td>
                                    <td>{item?.is_admin ?
                                        <span className="text-green"><FaCheckCircle/></span> :
                                        <span className="text-gray"><FaCheckCircle/></span>}</td>
                                    <td>{item?.is_staff ?
                                        <span className="text-green"><FaCheckCircle/></span> :
                                        <span className="text-gray"><FaCheckCircle/></span>}</td>
                                    <td>{item?.is_active ?
                                        <span className="text-green"><FaCheckCircle/></span> :
                                        <span className="text-gray"><FaCheckCircle/></span>}</td>
                                </tr>)
                            }


                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListAdmin: (...data) => dispatch(getListAdmin(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(List);
