// export const BASE_URL = "http://192.168.0.182:8000/api";
// export const BASE_URL = "http://192.168.0.108:8001/api";
// export const BASE_URL = "http://94.139.164.105:5859/api";
// export const BASE_URL = "http://193.141.64.122:80/api";
// export const BASE_URL = "http://192.168.0.58:5859/api";
// export const BASE_URL = "http://192.168.0.182:8000/api";
// export const BASE_URL = "http://driver.baryar.amnmoj.ir/api";
// export const BASE_URL = "http://192.168.0.182:8000/api";
// export const BASE_URL = "https://test.api.baryar.ir/api";
export const BASE_URL = "https://app.baryar.ir/api";
