import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import {getDetailCargo} from "redux/trackCargo/action";
import moment from "moment-jalaali";
import {MdLocationOn} from "react-icons/md";
import {FaPhone, FaTruck,FaCheckCircle,FaCalendarDay,FaStopwatch,FaMoneyBillAlt} from "react-icons/fa";
import * as QueryString from "query-string";

class Detail extends Component {
    state = {
        pageNo : QueryString.parse(this.props.location.search),
        item: {}
    }

    componentDidMount() {
        this.props.getDetailCargo(this.props.match.params.slug).then(r => {
            if (r?.result) {
                this.setState({
                    item: r?.result
                })
            }
        })
    }

    handleReturn = () => {
        window.location.href  =  `#/track-cargo/?page=${this.state.pageNo.page}`
    }

    render() {
        const {item} = this.state;

        console.log("Item =>>>>",item);
        return (
            <>
                <Main>
                <lin onClick={this.handleReturn}  className="btn-return-page">بازگشت</lin>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>

                        <div className="main-page-inside mt-5 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">مکان و تاریخ</p>
                            </div>
                            <div className="row text-right ">
                                <div className="col-12 col-md-6 mt-4">
                                    <span className="ml-2 font-size-15 text-MainColor"><MdLocationOn/></span>
                                    <span className="text-gray">از مبدا : </span>
                                    <span>{item.origin?.city.province.name + '،' + item.origin?.city.name}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">

                                    <span className="ml-2 font-size-15 text-MainColor"><MdLocationOn/></span>
                                    <span className="text-gray">به مقصد : </span>
                                    <span>{item?.destination?.city?.province?.name + " ، " + item?.destination?.city?.name}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">
                                    <span className="ml-2 text-MainColor"><FaStopwatch/></span>
                                    <span className="text-gray">زمان بارگیری : </span>
                                    <span>{item?.start_time?.slice(0, 5) + " تا " + item?.end_time?.slice(0, 5)}</span>
                                </div>
                                <div className="col-12 col-md-6 col-xl-4  mt-4">
                                    <span className="ml-2 text-MainColor"><FaCalendarDay/></span>
                                    <span className="text-gray">تاریخ ساخت : </span>
                                    <span>{moment(item?.creation_date, 'jYYYY-jMM-jDD').isValid() ?
                                        moment(item?.creation_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD') : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">اطلاعات بار</p>
                            </div>
                            <div className="row text-right ">

                                <div className="col-12 col-md-6 mt-4"><span className="text-gray">کدبار : </span>
                                    <span>{item?.tracking_code}</span>
                                </div>

                                <div className="col-12 col-md-6 mt-4">
                                    <span className="ml-2 text-MainColor"><FaMoneyBillAlt/></span>
                                    <span className="text-gray">قیمت  : </span>
                                    <span> {(item?.price_type === 2) ? 'به ازای هر تن' : ((item?.price_type === 1) ? 'به ازای هر سرویس' : 'توافقی')}</span>

                                    {
                                        item?.price_type ?
                                            <span><span className="font-weight-bold px-2">{item?.price}</span>
                                <span> تومان </span></span>
                                            :
                                            ''
                                    }
                                </div>
                                <div className="col-12 col-md-6  mt-4"><span
                                    className="text-gray">نوع کالا : </span>
                                    <span>{item?.type ? item.type : ''}</span>
                                </div>
                                <div className="col-12 col-md-6   mt-4"><span
                                    className="text-gray">دسته بندی کالا : </span>
                                    <span>{item?.packing ? item?.packing : ''}</span>
                                </div>
                                <div className="col-12   mt-4"><span className="text-gray">توضیحات بار : </span>
                                    <span>{item?.description ? item?.description : ''}</span>
                                </div>

                            </div>
                        </div>
                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">اطلاعات ناوگان</p>
                            </div>
                            <div className="row text-right ">
                                <div className="col-12 col-md-6  mt-4">
                                    <span className="ml-2 text-MainColor"><FaTruck/></span>
                                    <span className="text-gray">نام ناوگان : </span>
                                    <span>{item?.car?.title}</span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">
                                    <span className="ml-2 text-MainColor"><FaTruck/></span>
                                    <span className="text-gray">نوع بارگیر : </span>
                                    {
                                        console.log(item?.options)
                                    }
                                    {item?.options?.length > 0 ?
                                        <span>{item?.options.map(i => (i?.title) + ' ، ')}</span> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="main-page-inside mt-4 text-right">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">سفارش دهنده بار</p>
                            </div>
                            <div className="row text-right ">
                                <div className="col-12 col-md-6  mt-4"><span
                                    className="text-gray">نام سازنده بار : </span>
                                    <span>{item?.extra_information?.owner_information}</span>
                                    <span
                                        className={`font-size-08 ${(item?.owner?.is_authorized) ? 'text-green' : 'text-gray'} `}> {<FaCheckCircle/>} </span>
                                </div>
                                <div className="col-12 col-md-6  mt-4">

                                    <span className="ml-2 text-MainColor"><FaPhone/></span>
                                    <span className="text-gray">شماره تلفن همراه : </span>
                                    <span>{item?.extra_information?.phone_number}</span>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailCargo: (...data) => dispatch(getDetailCargo(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
