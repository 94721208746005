import React, {useEffect, useState} from 'react';
import CarFilter from "components/users/driver/carFilter";
import moment from "moment-jalaali";
import DatePicker from 'react-datepicker2';
// import {file_fetcher} from "../../../redux/common/action";
import {FaSearch} from "react-icons/fa";
import ProvinceSelector from "../users/ProvinceSelector";
import CarSelector from "components/report/CarSelector";
import {changeDetailDriver, editDetail, getDriver, getVehiclesList} from "../../redux/dashboard/action";
import {connect} from "react-redux";

const Filter = (props) => {
    const [date, seDate] = useState(null);
    const [vehiclesList, setVehiclesList] = useState([]);
    // const [body, setBody] = useState('');
    const deleteFilters = () => {
        window.location.reload();
    };
    useEffect(() => {
        props.getVehiclesList().then(r => {
            if (r?.result) {
                setVehiclesList(r?.result)
            }
        });
    }, [])
    return (
        <>

            <div className="d-flex">
                <div className="col px-0">
                    <div className="row">
                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <ProvinceSelector getNewProvince={props.getProvince} titleListProvince="شهر مبدا"/>
                        </div>

                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <ProvinceSelector getNewProvince={props.getProvinceDestination}
                                              titleListProvince="شهر مقصد"/>
                        </div>
                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                            <CarSelector name="نوع وسیله نقلیه"
                                         getNewCar={props.getNewCar}
                                         getOption
                                         vehiclesList={vehiclesList}/>
                        </div>
                        <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 text-right mt-3">
                            <div className="d-flex align-items-center">
                                <label className="ml-3">تاریخ بارگیری </label>
                                <div className="flex-grow-1">
                                    <DatePicker
                                        className="input-style-public-border pr-2"
                                        value={date}
                                        timePicker={false}
                                        isGregorian={false}
                                        // placeholder="تاریخ بارگیری"
                                        onChange={(value) => {
                                            seDate(value)
                                            props.filterDropdown({value: value.format('jYYYY-jMM-jDD'), name: 'date'})
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-12 col-md-12 mt-2 col-lg-6 col-xl-4 mt-3">
                <div className="d-flex">
                    <button onClick={props.handleRequest} className="submit-btn py-1">اعمال فیلتر</button>
                    <button onClick={deleteFilters} className="submit-btn-border py-1 mr-1">حذف فیلتر</button>
                    {/* <button onClick={handleReceivedFileExcel} className="submit-btn py-1 mr-1"> دریافت فایل اکسل</button> */}
                </div>

            </div>
        </>
    );
};

// export default Filter;
const mapDispatchToProps = (dispatch) => {
    return {
        getVehiclesList: (...data) => dispatch(getVehiclesList(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
