export const DETAIL_USER='DETAIL_USER';
export const CHANGE_DETAIL_DRIVER='CHANGE_DETAIL_DRIVER';
export const CHANGE_DETAIL_CUSTOMER='CHANGE_DETAIL_CUSTOMER';
export const CHANGE_DETAIL_TRANSPORTER='CHANGE_DETAIL_TRANSPORTER';
export const CHANGE_DETAIL_SERVICE_PROVIDER='CHANGE_DETAIL_SERVICE_PROVIDER';
export const DELETE_OLD_AREA_SEARCH = 'DELETE_OLD_AREA_SEARCH';
export const OLD_AREA_SEARCH='OLD_AREA_SEARCH';
export const initialChangeDriver =
    {
        "location": {
            "city_id": null,
            "point": {
                "longitude": "",
                "latitude": ""
            },
            "address": ""
        },
        "admin_description":'',
        "is_archived": false,
        "first_name": "",
        "last_name": "",
        "national_code": "",
        "is_active": false,
        "is_approve": false,
        "birth_date": null,
        "smart_driver_code": "",
        "scores": [],
        "vehicles_info": [{
            "vehicle": {
                "car_id":null,
                "option_id": null
            },
            "smart_car_code": "",
            "plate_car": "",
            "create_year": "",
            "brand": "",
            "type": "",
            "insurance_deadline": ""
        }],

    };
export const initialChangeCustomer =
    {
        "admin_description":'',
        "is_archived": false,
        "creation_date": "",
        "modified_date": "",
        "first_name": "",
        "last_name": "",
        "firebase_token": "",
        "referral_code": "",
        "inviter_code": "",
        "is_joined_topic": false,
        "is_approve": false,
        "owner": null,
        "company_name": "",
        "phone_number": "",
        "national_code": "",
        "area_expertise": "",
        "gender": "",
        "education_degree": "",
        "birthday": "",
        "location": {
            "city_id": null,
            "name": "",
            "point": {},
            "address": "",
            "custom_location":null
        },
        "load_per_week": 1
    };
export const initialChangeTransporter =
    {
        "admin_description":'',
        "company_name": "",
        "phone_number": "",
        "founder_name": "",
        "location":{
            "city_id": null,
            "point": {},
            "address": ""
        },
        /*"branches_office": [
            {
                "branch_location": {
                    "city_id": null,
                    "point": {},
                    "address": ""
                },
                "phone_number": ""

            },
            {
                "branch_location": {
                    "city_id": null,
                    "point": {},
                    "address": ""
                },
                "phone_number": ""

            }
        ],*/
        "area_expertise": "",
        "parent": ""
    };
