import {GET_OTP, CONFIRM_OTP} from 'redux/auth/type';
import {fetcher, reset} from 'redux/common/action';
/*import {getProfile} from 'redux/profile/action';*/
import {removeToken} from "utils/utils";
import {message} from "antd";
import store from 'redux/store'
export const resultGetOtp = (data, number) => {
    return {
        type: GET_OTP,
        data: {
            data,
            number
        }
    }
};
export const resultConfirmOtp = (data) => {
    return {
        type: CONFIRM_OTP,
        data
    }
};

export const logOut = () => {
    removeToken();
    return (dispatch) => {
        dispatch(resultConfirmOtp({
            data: {
                result: false
            }
        }));
        dispatch(reset());
        window.location = '/#/'

    }
};

export function getOtp(data) {
    return (dispatch) => {
        return fetcher('/account/auth/send_otp/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            }, {noAuth:false})
            .then((response) => {
                dispatch(resultGetOtp(response, data.mobile_number));
            /*    message.success(response?.result || 'code')*/
            }).catch((e) => {
            /*    message.error(e.message)*/
            })
    };
}

export function confirmOtp(data) {
    return (dispatch) => {
        return fetcher('/account/auth/verify_otp/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            })
            .then((response) => {
                if(response?.result?.message){
                    message.error(response?.result?.message);
                    throw new Error(response?.status.toString())
                    return response;
                }else {
                    dispatch(resultConfirmOtp(response));
                }

            })
            .catch((e) => {
                if (e.message === '406')
                    message.error('کد صحیح را وارد نمایید');
                else
                    message.error(e.message)
            })
    }
}

export function refreshOtp(data) {
    return (dispatch) => {
        return fetcher('/account/auth/token/refresh',
            {
                method: 'POST',
                body: JSON.stringify(data),
            }, {noAuth:false})
            .then((response) => {
                dispatch(resultConfirmOtp(response));
            })
            .catch(e => {
                store.dispatch(resultConfirmOtp({
                    data: {
                        result: false
                    }
                }))
                window.location = '/#/'
            })

    };
}
export function register(data) {
    return (dispatch) => {
        return fetcher('/panel/auth/register/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            })
            .then((response) => {
                message.success("ادمین جدید با موفقیت اضافه شد")
            }).catch(e=>{
                message.error(e.message);
            })
    };
}
export function getListAdmin() {
    return () => {
        return fetcher('panel/user/admin',
            {
                method: 'GET',
            })
            .then((response) => {
              return response;
            }).catch(e=>{
            })
    };
}
export function login(data) {
    return (dispatch) => {
        return fetcher('panel/auth/login/',
            {
                method: 'POST',
                body: JSON.stringify(data),
            }, {noAuth:false})
            .then((response) => {
                if(response?.result?.message){
                    message.error(response?.result?.message);
                }else {
                    dispatch(resultConfirmOtp(response));
                }
            }).catch(e=>{
                message.error(e.message);
            })
    };
}
