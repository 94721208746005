import {fetcher} from "redux/common/action";
import {message} from "antd";
export const sendTopicPush = (data) => {
    return (dispatch) => {
        return fetcher('panel/push',
            {
                method: 'POST',
                body: JSON.stringify(data),
            }).then(r=>{
            message.success('با موفقیت ارسال شد')
                return r;
        }).catch(r=>{
            message.error('با خطا مواجه شدید')
        })
    }
};
