import React, {Component} from 'react';
import {resultٰVisitors} from "redux/magazine/action";
import {connect} from "react-redux";

const Show = [
    {title: 'راننده', id: 'driver'},
    {title: 'صاحب بار', id: 'customer'},
    {title: 'شرکت حمل‌ونقل ', id: 'transportation'},
    {title: 'سرویس دهنده', id: 'serviceprovider'}
];

class Visitor extends Component {

    componentDidMount() {
        this.props.resultٰVisitors(Show.map(t => t.id));
    }

    chooseShow = (e, i) => {
        let list = this.props.infoVisitor;
        if (e.target.checked) {
            list.push(i);
            this.props.resultٰVisitors(list);
            this.forceUpdate()
        } else {
            this.props.resultٰVisitors(list.filter(t => t !== i));
        }

    };

    render() {
        return (
            <>
                <div className="d-flex align-items-center mt-4">
                    <span className="text-banner ml-3">{this.props.text ? this.props.text :'نمایش برای :'} </span>
                    {
                        Show.map((i, ind) => <div key={ind} className="mr-2 mr-md-4">
                            <span className="d-inline-block text-right">
                                       <label className="labelContainer m-0">
                                    <input type="checkbox"
                                           value="asd"
                                           onChange={e => this.chooseShow(e, i.id)}
                                           checked={this.props.infoVisitor?.includes(i.id)}/>
                                    <span className="checkmark"/>
                                    <span className="font-size-09 text-secondary ">{i.title}</span>
                                </label>
                                </span></div>)
                    }

                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resultٰVisitors: (...data) => dispatch(resultٰVisitors(...data)),
    }
};
const mapStateToProps = (store) => {
    return {
        infoVisitor: store.infoVisitor
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Visitor);
