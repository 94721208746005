import {GET_OTP, CONFIRM_OTP} from 'redux/auth/type';
import {setToken} from 'utils/utils';
import {RESET} from "redux/common/type";
export const infoGetOtp = (state = {number:'',data:{result:''}}, action) => {
    switch (action.type) {
        case RESET:
            return {number:'',data:{result:''}};
        case GET_OTP:
            console.log('action.data',action.data)
            return action.data;
        default:
            return state;
    }
};
export const infoConfirmOtp = (state = {isLoggedIn: false}, action) => {
    switch (action.type) {
        case RESET:
            return {isLoggedIn: false};
        case CONFIRM_OTP:
            if (action.data?.result?.access) {
                setToken(action.data.result?.access,action.data.result?.refresh);
                return {...state, isLoggedIn: true,
                    data:action.data};
            } else {
                return {...state, isLoggedIn: false,
                    data:action.data};
            }
        default:
            return state;
    }
};
