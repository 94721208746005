import React, {Component} from 'react';
import all from "images/icon/magazine/Content.svg";
import news from "images/icon/magazine/News.svg";
import notif from "images/icon/magazine/Notifications.svg";
import learn from "images/icon/magazine/Learning.svg";

import {IoIosArrowDown} from "react-icons/io";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaPlusCircle, FaTrashAlt} from "react-icons/fa";
import {MdModeEdit} from "react-icons/md";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {getCategory, getSubject, removeSubject, resultCreateSubjects} from "redux/magazine/action";
import {connect} from "react-redux";
import moment from 'moment-jalaali';
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";

class Subjects extends Component {
    state = {
        dropdownOpen: false,
        Subject: [],
        SubjectCount: 0,
        loading: true,
        categories: []
    };
    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    };

    componentDidMount() {
        this.getSubject();
        this.props.getCategory().then(r => {
            if (Array.isArray(r?.result)) {
                this.setState({
                    categories: r?.result,
                    categoryCount: r?.count,
                })
            }
        })
    }

    getSubject = () => {
        this.props.getSubject().then(r => {
            if (r?.result) {
                this.setState({
                    Subject: r?.result,
                    SubjectCount: r?.count,
                })
            }
        })
    };

    render() {
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل مطالب"
                                  number={this.state.SubjectCount}>
                                <img src={all} alt=""/>
                            </Card>
                            <Card name="تعداد کل خبرها"
                                  number="88">
                                <img src={news} alt=""/>
                            </Card>
                            <Card name="تعداد کل اطلاعیه ها"
                                  number="88">
                                <img src={notif} alt=""/>
                            </Card>
                            <Card name="تعداد کل آموزش ها"
                                  number="88">
                                <img src={learn} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">مطالب</p>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center ml-4">
                                        <span className="ml-2">دسته بندی براساس</span>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                            <DropdownToggle className="dropdown-btn bg border px-4">
                                                <span>همه مطالب</span>
                                                <span className="pr-2"><IoIosArrowDown/></span>
                                            </DropdownToggle>
                                            <DropdownMenu style={{right: 0}}>
                                                <button className="dropdown-btn-item">همه مطالب ماه جدید</button>
                                                <button className="dropdown-btn-item">همه مطالب امروز</button>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <NavLinkRouter to="/new-subject/new" className="submit-btn w-auto px-4 py-2">
                                        <span className="pl-2"><FaPlusCircle/></span>
                                        ایجاد مطلب جدید
                                    </NavLinkRouter>
                                </div>


                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th>عکس مطلب</th>
                                        <th>تیتر مطلب</th>
                                        <th>نوع دسته بندی</th>
                                        <th>تاریخ مطلب</th>
                                        <th>وضعیت مطلب</th>
                                        <th>ویرایش</th>
                                        <th>حذف</th>
                                        <th></th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.Subject.map((s, i) => <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <img className="img-banner" src={s?.media?.exact_url} alt=""/>
                                        </td>
                                        <td className="text-long">{s?.caption}</td>
                                        <td>{this.state.categories.filter((t) => t.id == s?.category)[0]?.title}</td>
                                        <td>{moment(s?.creation_date, 'jYYYY-jMM-jDD').format('jYYYY/jMM/jDD')}</td>
                                        <td>آرشیو</td>
                                        <td><NavLinkRouter to={'new-subject/' + s.id}
                                                           onClick={() => {
                                                               this.props.resultCreateSubjects({...s, parent: s?.id})
                                                           }}
                                                           className="check-td-green"><MdModeEdit/></NavLinkRouter></td>
                                        <td>
                                            <button className="trash-td-pink"
                                                    onClick={() => {
                                                        this.props.removeSubject(s.id).then(r => this.getSubject())
                                                    }}><FaTrashAlt/></button>
                                        </td>
                                        <td>
                                            {
                                                1 ?
                                                    <button className='submit-btn  px-1 py-1'> غیر فعال کردن</button>
                                                    :
                                                    <button
                                                        className="submit-btn-border border-radius-5  px-1 py-1"> فعال
                                                        کردن</button>
                                            }

                                        </td>
                                    </tr>)}
                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getSubject: (...data) => dispatch(getSubject(...data)),
        getCategory: (...data) => dispatch(getCategory(...data)),
        removeSubject: (...data) => dispatch(removeSubject(...data)),
        resultCreateSubjects: (...data) => dispatch(resultCreateSubjects(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Subjects);
