import React, {Component} from 'react';
import banner from "images/icon/magazine/Banners.svg";
import Card from "components/dashboard/card";
import Main from "components/publicComponents/main";
import {FaPlusCircle, FaTrashAlt} from "react-icons/fa";
import {NavLink as NavLinkRouter} from "react-router-dom";
import {getBanner, removeBanner} from 'redux/magazine/action';
import {connect} from "react-redux";
import {Spin} from 'antd';
import LoadingIcon from "components/publicComponents/loadingIcon";

class Banner extends Component {
    state = {
        dropdownOpen: false,
        banners: [],
        bannerCount: 0,
        loading: true
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        this.getBanner();
    }

    getBanner = () => {
        this.props.getBanner().then(r => {
            if (r?.result) {
                this.setState({
                    banners: r?.result,
                    bannerCount: r?.count,
                })
            }
        })
    }

    render() {
        return (
            <>

                    <Main>
                        <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>
                        <div className="row">
                            <Card name="تعداد کل بنرها"
                                  number={this.state.bannerCount}>
                                <img src={banner} alt=""/>
                            </Card>
                            <Card name="تعداد بنرهای فعال"
                                  number="1">
                                <img src={banner} alt=""/>
                            </Card>
                        </div>
                        <div className="main-page-inside mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="main-title text-right">سابقه بنر ها</p>
                                <NavLinkRouter to="/new-banner" className="submit-btn w-auto px-4 py-2">
                                    <span className="pl-2"><FaPlusCircle/></span>
                                    ایجاد بنر جدید
                                </NavLinkRouter>

                            </div>
                            <div className="table-main mt-4">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>شماره</th>
                                        <th>عکس بنر</th>
                                        <th>نام بنر</th>
                                        <th>لینک بنر</th>
                                        <th>تاریخ بنر</th>
                                        <th></th>
                                        <th></th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.banners.map((b, i) => <tr>
                                            <td>{i+1}</td>
                                            <td>
                                                <img className="img-banner" src={b?.media?.exact_url}/>
                                            </td>

                                            <td>{b.name}</td>
                                            <td><a className="link-banner" target="_blank"
                                                   href={b.browser}>{b.browser}</a>
                                            </td>
                                            <td>1398/52/25</td>
                                            <td>
                                                <button className="trash-td-pink"
                                                        onClick={() => this.props.removeBanner(b.id).then(r => this.getBanner())}>
                                                    <FaTrashAlt/></button>
                                            </td>
                                            <td>
                                                {
                                                    (this.state.banners?.length === (i + 1)) ?
                                                        <button className='submit-btn  px-1 py-1'>غیر فعال کردن</button>
                                                        :
                                                        <button className='submit-btn-border px-1 py-1'> فعال
                                                            کردن</button>

                                                }
                                            </td>
                                        </tr>)
                                    }


                                    </tbody>

                                </table>
                            </div>

                        </div>
                        </Spin>
                    </Main>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBanner: (...data) => dispatch(getBanner(...data)),
        removeBanner: (...data) => dispatch(removeBanner(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading:store.infoLoading
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
