import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import Card from "components/dashboard/card";
import users from 'images/icon/users.svg';
import drivers from 'images/icon/drivers.svg';
import cargoes from 'images/icon/cargoes.svg';
import owners from 'images/icon/owners.svg';
import suports from 'images/icon/supporters.svg';
import bars from 'images/icon/bars.svg';
import succcessfullBar from 'images/icon/succcessfull bars.svg';
import unsucccessfullBar from 'images/icon/Unsuccessful Bars.svg';
import Currents from 'images/icon/Currents bars.svg';
import {getDashboard} from "redux/dashboard/action";
import {connect} from "react-redux";

class Index extends Component {
    state={
        dashboard:{}
    }
    componentDidMount() {
        this.props.getDashboard().then(r=>{
            if(r?.result){
                this.setState({
                    dashboard:r?.result
                })
            }

        })
    }

    render() {
        const {dashboard} =this.state;
        return (
            <>
                <Main>
                    <div className="row">
                        <Card name="تعداد کاربران"
                              number={dashboard?.user_count}>
                            <img src={users} alt=""/>
                        </Card>
                        <Card name="تعداد رانندگان"
                              number={dashboard?.driver_count}>
                            <img src={drivers} alt=""/>
                        </Card>
                        <Card name="تعداد شرکت حمل‌ونقل  ها"
                              number={dashboard?.transportation_count}>
                        <img src={cargoes} alt=""/>
                    </Card>
                        <Card name="تعداد صاحبان بار"
                              number={dashboard?.customer_count}>
                        <img src={owners} alt=""/>
                    </Card>

                    </div>
                    <div className="row">
                        <Card name="تعداد سرویس دهندگان"
                              number={dashboard?.service_provider_count}>
                            <img src={suports} alt=""/>
                        </Card>
                    </div>
                    <div className="row">
                        <Card name="تعداد کل بارها"
                              number={dashboard?.cargo_count}>
                            <img src={bars} alt=""/>
                        </Card>
                        <Card name="تعداد بارهای موفق"
                              number={dashboard?.success_cargo_count}>
                            <img src={succcessfullBar} alt=""/>
                        </Card>
                        <Card name="تعداد بارهای ناموفق"
                              number={dashboard?.failed_cargo_count}>
                            <img src={unsucccessfullBar} alt=""/>
                        </Card>
                        <Card name="تعداد بارهای جاری"
                              number={dashboard?.current_cargo_count}>
                            <img src={Currents} alt=""/>
                        </Card>
                    </div>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboard: (...data) => dispatch(getDashboard(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
