import React, {Component} from 'react';
import Main from "components/publicComponents/main";
import {MdCheckCircle} from "react-icons/md";
import {changeDetailCustomer, editDetail, getCustomer} from "redux/dashboard/action";
import {connect} from "react-redux";
import LoadingIcon from "components/publicComponents/loadingIcon";
import {Spin} from "antd";
import {NavLink as NavLinkRouter, Redirect} from "react-router-dom";
import PlaceSelector from "components/users/PlaceSelector";
import EducateSelectorFilter from "components/users/EducareSelectorFilter";
import DatePicker from 'react-datepicker2';
import moment from 'moment-jalaali';
import CarSelector from "components/users/CarSelector";

class EditCustomer extends Component {
    state = {
        profile: '',
        national: '',
        smartcode: '',
        modal: false,
        input: null,
        data: '',
        selectedOptionApprove: false,
        vehiclesList: [],
        back: false
    }

    componentDidMount() {
        this.setState({
            date: moment(this.props.infoDetailCustomer?.birth_date, 'jYYYY-jMM-jDD').isValid() ? moment(this.props.infoDetailCustomer?.birth_date, 'jYYYY-jMM-jDD')
                : moment(),
        })
        if (this.props.infoDetailCustomer?.media) {
            let profile = this.props.infoDetailCustomer?.media.filter(t => t?.type === "customerprofile");
            let national = this.props.infoDetailCustomer?.media.filter(t => t?.type === "national");
            let smartcode = this.props.infoDetailCustomer?.media.filter(t => t?.type === "smartcode");
            if (profile.length !== 0) {
                this.setState({
                    profile: profile[0]?.exact_url,
                    selectedOptionApprove: this.props.infoDetailCustomer?.is_approve
                })
            }
            if (national.length !== 0) {
                this.setState({
                    national: national[0]?.exact_url
                })
            }
            if (smartcode.length !== 0) {
                this.setState({
                    smartcode: smartcode[0]?.exact_url
                })
            }

        }
    }

    render() {
        if (this.state.back) {
            return <Redirect to="/customer"/>
        }
        const {infoDetailCustomer} = this.props;
        return (
            <>
                <Main>
                    <Spin size="large" spinning={this.props.infoLoading} indicator={<LoadingIcon/>}>

                        <div className="row text-right">
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شماره تلفن همراه :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailCustomer?.owner?.mobile_number}
                                       disabled={true}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4 text-right">
                                <label className="mb-2">تاریخ تولد :</label>
                                <DatePicker
                                    className="input-style-public-border pr-2 mt-2"

                                    value={this.state.date}
                                    timePicker={false}
                                    isGregorian={false}
                                    onChange={(value) => {
                                        if (value)
                                            this.props.changeDetailCustomer({
                                                "birth_date": value.format('jYYYY-jMM-jDD')
                                            })
                                    }}
                                />

                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailCustomer?.first_name}
                                       onChange={(e) => {
                                           this.props.changeDetailCustomer({first_name: e.target.value})
                                       }}
                                />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">نام خانوادگی :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailCustomer?.last_name}
                                       onChange={(e) => {
                                           this.props.changeDetailCustomer({last_name: e.target.value})
                                       }}
                                />
                            </div>

                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">شهر محل زندگی :</label>
                                <PlaceSelector title={'محل زندگی'} prefix={"شهر:"}
                                               property='changeDetailCustomer'
                                               result='infoDetailCustomer'
                                               type={"location"}/>
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <label className="mb-2">کدملی :</label>
                                <input type="text" className="input-style-public-border pr-2"
                                       defaultValue={infoDetailCustomer?.national_code}
                                       onChange={(e) => {
                                           this.props.changeDetailCustomer({national_code: e.target.value})
                                       }}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-4 text-center my-4">
                                <button className="submit-btn-green "
                                        disabled={this.props.infoLoading}
                                        onClick={() => {
                                            this.props.editDetail('infoDetailCustomer', this.props.infoDetailCustomer?.owner?.id, 'customer')
                                                .then(r => {
                                                    /*this.props.cancel();*/
                                                    this.props.getCustomer().then(r => {
                                                        if (r?.result) {
                                                            let item = r?.result.filter(t => t?.owner?.id == this.props.id);
                                                            if (item?.length !== 0) {
                                                                this.props.changeDetailCustomer(item[0]);
                                                            }
                                                            this.setState({back: true})
                                                        }
                                                    })
                                                })
                                        }}>
                                    {this.props.infoLoading ? "درحال اتصال" : 'ذخیره تغییرات'}
                                </button>
                            </div>
                        </div>
                    </Spin>
                </Main>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (...data) => dispatch(getCustomer(...data)),
        changeDetailCustomer: (...data) => dispatch(changeDetailCustomer(...data)),
        editDetail: (...data) => dispatch(editDetail(...data)),

    }
};
const mapStateToProps = (store) => {
    return {
        infoLoading: store.infoLoading,
        infoDetailCustomer: store.infoDetailCustomer,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
